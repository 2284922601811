import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkEmpty, getAuthToken, getUserDetails, handleTokenError } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { SyntheticEvent } from "react";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

export interface CompanyProfileData {
  companyName: string;
  companyEmail: string;
}
export interface PersonalData {
  prefix:string,
  first_name:string,
  last_name:string,
  phone_number:string|null,
}


type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
type Color = "success" | "info" | "warning" | "error" | undefined;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  initialValuesCompanyProfile: {
    companyName: string;
    companyEmail: string;
  };
  profileAvatar: string;
  loading: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  profileImage: string;
  activeButton: string|null;
  errorw9Message:string;
  errorsoleSourceMessage:string;
  w9File:string|undefined;
  soleFile:string|undefined;
  account_number:string,
  account_type:string,
  routing_number:string,
   initialValuePersonalData:{
    prefix:string;
    first_name:string;
    last_name:string;
    phone_number:string|null;
    email:string;
  };
  confirmPassword:string;
  passwordErrors: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getVendorDetailsApiId: string="";
  updateVendorDetailsApiId: string="";
  getPersonalDetailsApiId: string="";
  updatePersonalDetailsApiId:string="";
  updatePasswordApiId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      initialValuesCompanyProfile: {
        companyName: "Kaushal vendor",
        companyEmail: "Kaushal vendor",
      },
      profileAvatar: "",
      loading: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      profileImage: "",
      activeButton: "",
      errorw9Message:"",
      errorsoleSourceMessage:"",
      w9File:"",
      soleFile:"",
      initialValuePersonalData:{
        prefix:"",
        first_name:"",
        last_name:"",
        phone_number:"",
        email:""
      },
      confirmPassword:"",
      passwordErrors: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      account_number:"",
      account_type:"",
      routing_number:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getVendorData();
    const buttonClicked = localStorage.getItem('buttonClicked') || 'Company Profile';
    this.setState({ activeButton: buttonClicked });
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson);
      if (responseJson) {
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
        this.setState({
          loading: false,
        });
      }
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getVendorDetailsApiId) {
          this.setState({
            initialValuesCompanyProfile: {  
              companyName:
                responseJson.data.attributes.vendor_company.data.attributes
                  .company_name,
              companyEmail:
                responseJson.data.attributes.vendor_company.data.attributes
                  .company_address,
            },
            profileAvatar: responseJson.data.attributes.vendor_company.data.attributes
            .vendor_logo.url,
            w9File:
              responseJson.data.attributes.vendor_company.data.attributes
                .w9_file?.url,
            soleFile:
              responseJson.data.attributes.vendor_company.data.attributes
                .sole_source_letters?.url,
            initialValuePersonalData: {
              prefix: responseJson.data.attributes.prefix,
              first_name: responseJson.data.attributes.first_name,
              last_name: responseJson.data.attributes.last_name,
              phone_number: responseJson.data.attributes.phone_number,
              email: responseJson.data.attributes.email,
            },
              account_number: checkEmpty(responseJson.data.attributes.vendor_company.data.attributes
              .account_number),
              routing_number: checkEmpty(responseJson.data.attributes.vendor_company.data.attributes
              .routing_number),
              account_type:checkEmpty(responseJson.data.attributes.vendor_company.data.attributes
              .account_type)
            
        });
      }

      if (apiRequestCallId === this.updatePersonalDetailsApiId) {
        if (responseJson.data.attributes) {
          this.setState({
            showSnackbar: true,
            severity: "success",
            message: "Profile updated successfully",
          });
          this.getVendorData();
          let userInfo = getUserDetails();
          userInfo.first_name = responseJson?.data?.attributes.first_name;
          userInfo.last_name = responseJson?.data?.attributes.last_name;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          window.dispatchEvent(new Event("storage"));
        }

      }
        if (apiRequestCallId === this.updateVendorDetailsApiId) {
          if (responseJson.data.attributes) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: "Profile updated successfully",
            });
            this.getVendorData();
            let userInfo = getUserDetails();
            userInfo.vendor_company.data.attributes = responseJson?.data?.attributes;
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            window.dispatchEvent(new Event("storage"));
          }
         
        }
        if (apiRequestCallId === this.updatePasswordApiId) {
          if (responseJson.message) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: responseJson.message,
            });
          }
        }
      } else {
        const errors = responseJson?.errors;
        if(responseJson?.errors === "Incorrect current password") {
          this.setState((prevState) => ({
            passwordErrors: {
              ...prevState.passwordErrors,
              currentPassword: responseJson.errors
            }
          }));
        }
        else{
          this.setState({
            showSnackbar: true,
            severity: "error",
            message:errors?
              `${Object.keys(errors)[0]} : ${Object.values(errors)[0]}`:
              "Something Went wrong",
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };
  getVendorData = () => {
    let token = getAuthToken();
    const header = {
      token: token,
    };

    const requestCompanyData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorDetailsApiId = requestCompanyData.messageId;
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendordataEndPoint
    );
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestCompanyData.id, requestCompanyData);
    return true;
  };
  updateCompanyProfile(data: FormData) {
    let token = getAuthToken();
    const header = {
      token: token,
    };
    const userData = getUserDetails();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateVendorDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/vendors/${userData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updatePersonalData(data:PersonalData) 
  {
    let token = getAuthToken();
    const userData = getUserDetails();
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePersonalDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${userData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updatePassword(data:FormData) 
  {
    let token = getAuthToken();
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePasswordApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/change_password"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSubmitCompanyProfile = (companyProfileData: CompanyProfileData) => {
    const formData = new FormData();
    formData.append("company_name", companyProfileData.companyName);
    formData.append("company_address", companyProfileData.companyEmail);
    this.updateCompanyProfile(formData);
  };
  onImageChangeVendor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] ?? null;
    if (file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.setState({ profileAvatar: e.target.result});
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("vendor_logo", file);
      this.updateCompanyProfile(formData);
    }
  };
  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };
  handleSubmitPersonalData = (personalData: PersonalData) =>{
    this.updatePersonalData(personalData);
  }
  handleSubmitPassword = (event:SyntheticEvent) =>{
    event.preventDefault();
   if(this.validateForm())
   {
    const formData = new FormData();
    formData.append("new_password", this.state.newPassword);
    formData.append("password_confirmation", this.state.confirmPassword);
    formData.append("current_password", this.state.currentPassword);
    this.updatePassword(formData);
   }
  }
  handleButtonClick = (buttonName: string) => {
    this.setState({ activeButton: buttonName });
    localStorage.setItem("buttonClicked",buttonName);
  };
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (
      field.field_type === "integer" &&
      (event.key === "." || event.key === ",")
    ) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (
        item.is_required &&
        !(item.value as string).trim() &&
        item.is_enable
      ) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    }
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, isW9File: boolean) => {
    const file = event.target.files?.[0];
    if (file) {
      let errorMessage = '';
      if (file.type !== 'application/pdf') {
        errorMessage = 'Please upload a PDF file.';
      } else if (file.size > 10 * 1024 * 1024) {
        errorMessage = 'File size should be less than 10MB.';
      }
  
      if (isW9File) {
        this.setState({ errorw9Message: errorMessage, errorsoleSourceMessage: '' });
      } else{
        this.setState({ errorsoleSourceMessage: errorMessage, errorw9Message: '' });
      }
  
      if (!errorMessage) {
        const formData = new FormData();
        formData.append(isW9File ? "w9_file" : "sole_source_letters", file);
        this.updateCompanyProfile(formData);
        this.getVendorData();
      }
    }
  };
  handleInputChange=(event:React.ChangeEvent<HTMLInputElement>)=>
  {  
    this.setState((prevState) => ({
      passwordErrors: {
        ...prevState.passwordErrors,
        currentPassword:""
      }
    }));
    this.setState({[event.target.name]:event.target.value} as unknown as Pick<S, keyof S>);
  }
  
  validateForm = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    let isValid = true;
    const errors = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };

    if (!currentPassword) {
      errors.currentPassword = 'Current Password is required';
      isValid = false;
    }

    const newPasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/;

    if (!newPassword) {
      errors.newPassword = 'New Password is required';
      isValid = false;
    } else if (!newPasswordRegex.test(newPassword)) {
      errors.newPassword = 'Must contain at least one uppercase, one lowercase, one number, and be at least 8 characters long';
      isValid = false;
    }
    if (confirmPassword !== newPassword) {
      errors.confirmPassword = 'Password must match';
      isValid = false;
    }

    this.setState({passwordErrors: errors});
    return isValid;
  };

  // Customizable Area End
}
