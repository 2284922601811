import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { play, rightAeroWhite } from "../assets";
import ReactPlayer from "react-player";
import {
  getNavPathForVideoCard,
  getUserDetails,
  isTeacher,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";

export default function VideoCard({ open, handleClose,navigation }: any) {
  const videoRef = useRef(null);
  const [show, setShow] = useState(true);
  const handlePlayavideo = () => {
    setShow(!show);
  };

  const onVideoClick = () => {
    setShow(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <MuiDialogContent className="video-div">
        <div onClick={() => onVideoClick()}>
          <ReactPlayer
            className="video-player"
            url={getUserDetails()?.videoUrl || ""}
            controls={false}
            ref={videoRef}
            playing={!show}
            width="auto"
            height={345}
          />
        </div>
        {show && (
          <div className="playbutton">
            <Button className="play-btn" onClick={handlePlayavideo}>
              <img src={play} alt="dd" /> &nbsp; Play video
            </Button>
          </div>
        )}
      </MuiDialogContent>
      <MuiDialogActions>
        <Grid container className="pad-34-20">
          <Grid item sm={12} className="p-b-15">
            <span className="video-title-txt">Welcome to Classbox!</span>
          </Grid>
          <Grid item sm={12} className="p-b-15">
            <span className="video-desc-txt">
              Watch this 'Getting Started' video to learn how manage your team
              members at{" "}
              <span className="school-name-video">
                {getUserDetails()?.school?.data?.attributes?.name || ""}
              </span>{" "}
              on Classbox.
            </span>
          </Grid>
          <Grid item container sm={12} className="video-modal-actions">
            <Button onClick={handleClose} color="primary" className="left-btn">
              Close
            </Button>
            <Button onClick={() => navigation.navigate(getNavPathForVideoCard())} color="primary" className="right-btn">
              {isTeacher() ? `Browse Classboxes` : `Manage School`}&nbsp;
              <img src={rightAeroWhite} alt="images" />
            </Button>
          </Grid>
        </Grid>
      </MuiDialogActions>
    </Dialog>
  );
}
