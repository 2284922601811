import React from "react";
import { Button } from "@material-ui/core";
import { confirmation, fileBlueIcon } from "../assets";
import "../../assets/successcard.scss";
import RequestCard from "./RequestCard";
import { rightArrowIcon } from "../../../catalogue/src/assets";
import moment from "moment";
import { getCommaSeperator, getCredits } from "../../../../components/src/NativeWebRouteWrapper/Utils";

export default function SuccessCard({
  state,
  navigation,
  clearSuccessDetails,
}: any) {
  return (
    <>
      {!state.pendingOrSuccess && !!state.successCardDetails ? (
        <div className="container-comfirmed1">
          <img src={confirmation} alt="img" className="img1"></img>
          <div className="top-div1">
            <span className="title1">Thank you for your order!</span>
            <br></br>
            <br></br>
            <span className="description1">
              The order confirmation has been sent to your admin's email
              address.
            </span>
          </div>
          <div className="bottom-div1">
            <div className="classbox-container">
              <div className="classbox-item">
                <span className="classbox-item-txt">
                  {getCommaSeperator(state.successCardDetails.attributes.total_cost)}{" "}
                  <span className="classbox-subtext">{getCredits(state.successCardDetails.attributes.total_cost)}</span>
                </span>
                <span className="classbox-item-txt1">Total amount</span>
              </div>
              <div className="classbox-item">
                <span className="classbox-item-txt">{moment(state.selectedRange[0]).format("MM/DD/YYYY")}</span>
                <span className="classbox-item-txt1">Estimated ship date</span>
              </div>
            </div>
            <a target="_blank" href={state.successCardDetails.attributes.order_receipt.url} className="download-style-underline">
              <Button className="download-btn">
                <img src={fileBlueIcon} alt="i" />
                &nbsp; Download receipt
              </Button>
            </a>
            <Button
              className="right-btn1"
              onClick={() => {
                clearSuccessDetails();
                navigation.navigate("Dashboard");
              }}
            >
              Dashboard &nbsp;
              <img src={rightArrowIcon} alt="i" />
            </Button>
          </div>
        </div>
      ) : (
        <RequestCard navigation={navigation} />
      )}
    </>
  );
}
