Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TeamBuilder";
exports.labelBodyText = "TeamBuilder Body";
exports.regx = /.*@/;
exports.btnExampleTitle = "CLICK ME";
exports.getMySchoolDetailApiEndPoint = "/bx_block_activity_feed/credits";
exports.inviteTeacherApiEndPoint = "/account_block/accounts";
exports.getPaymentsHistoryApiEndPoint = "/bx_block_activity_feed/credit_activities?account_id=";
exports.updateCreditsApiEndPoint = "/bx_block_activity_feed/credit_activities?";
exports.exampleApiPUTMethodType = "PUT";
exports.updateCoLeadsApiEndPoint = "/account_block/accounts/update_co_lead?"
exports.deleteStaffApiEndPoint="/account_block/accounts/remove_teacher"
exports.exampleApiDeleteMethodType="PUT";
exports.deleteLeadApiEndPoint="/account_block/accounts/update_co_lead?role=Teacher&id="
exports.getUserDetailsApiEndPoint = "account_block/accounts/";
// Customizable Area End