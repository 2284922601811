import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  InputLabel,
  TextField,
  Button,
  DialogActions,
} from "@material-ui/core";
import { info } from "../assets";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { errorIcon } from "../../../EmailNotifications2/src/assets";

export const getPasswordSymbol = (errors: any, touched: any) => {
  return !!errors.email && !!touched.email ? (
    <img src={errorIcon} alt="icon" />
  ) : (
    <></>
  );
};

const InviteTeacherModal = ({ handleDialog, state, onAddTeacher }: any) => {
  let regexObj = new RegExp(
    "^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(" +
      state.leadMail +
      ")$"
  );
  const Schema = Yup.object().shape({
    firstName: Yup.string().required("Field is Required."),
    lastName: Yup.string().required("Field is Required."),
    email: Yup.string()
      .email("Email must be in valid format!")
      .matches(regexObj, "Address extensions don't match.")
      .required("Email Address is required."),
  });

  return (
    <Dialog
      className="dialog-container"
      onClose={handleDialog}
      aria-labelledby="customized-dialog-title"
      open={state.dialogOpen}
    >
      <DialogTitle id="customized-dialog-title">
        <Typography
          className="main-text title-medium modal-title-bold"
          gutterBottom
        >
          Invite people
        </Typography>
        <Typography className="sub-text title-medium modal-desc-light">
          Invite new people to your Classbox
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            display: "flex",
            backgroundColor: "#f5f5f5",
            borderRadius: 5,
            margin: "auto",
            justifyContent: "flex-start",
            paddingLeft: "15px",
          }}
        >
          <Typography
            className="warning-text"
            style={{
              padding: "5px 0px",
              paddingLeft: "15px",
            }}
          >
            <img src={info} style={{ padding: "1.5% 0" }} />
            &nbsp; You can only invite teachers who have the same email
            extension as yours
          </Typography>
        </Box>
        <Box>
          <form noValidate autoComplete="off">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
              }}
              onSubmit={(values) => {
                onAddTeacher(values);
              }}
              validationSchema={Schema}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "5%",
                      }}
                    >
                      <Box sx={{ width: "48%" }}>
                        <InputLabel
                          htmlFor="my-input"
                          className="pop-up-input-text"
                        >
                          Name
                        </InputLabel>
                        <TextField
                          className="input-label"
                          style={{ marginTop: "3%" }}
                          onChange={handleChange("firstName")}
                          data-test-id={"txtInputfirstName"}
                          value={values.firstName}
                          name="firstName"
                          placeholder="First Name"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          helperText={<ErrorMessage name="firstName" />}
                          error={!!errors.firstName && !!touched.firstName}
                          required
                        />
                      </Box>
                      <Box sx={{ width: "48%" }}>
                        <InputLabel
                          htmlFor="my-input"
                          className="pop-up-input-text"
                        >
                          Last Name
                        </InputLabel>
                        <TextField
                          className="input-label"
                          style={{ marginTop: "3%" }}
                          onChange={handleChange("lastName")}
                          data-test-id={"txtInputlastName"}
                          value={values.lastName}
                          name="lastName"
                          placeholder="Last Name"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          helperText={<ErrorMessage name="lastName" />}
                          error={!!errors.lastName && !!touched.lastName}
                          required
                        />
                      </Box>
                    </Box>

                    <InputLabel
                      htmlFor="my-input"
                      style={{ marginTop: "2%" }}
                      className="pop-up-input-text"
                    >
                      Email
                    </InputLabel>
                    <TextField
                      className="input-label"
                      style={{ marginTop: "1%" }}
                      onChange={handleChange("email")}
                      data-test-id={"txtInputProductId"}
                      value={values.email}
                      name="email"
                      placeholder="Enter Email Id"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="email" />}
                      error={!!errors.email && !!touched.email}
                      required
                      InputProps={{
                        endAdornment: getPasswordSymbol(errors, touched),
                      }}
                    />
                    <DialogActions
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "0px",
                        gap: "8px",
                        marginTop: "15px",
                      }}
                    >
                      <Button
                        autoFocus
                        onClick={handleDialog}
                        color="primary"
                        className="btn-popup"
                        style={{
                          fontStyle: "normal",
                          fontSize: "16px",
                          color: "#00649E",
                          fontWeight: 500,
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => handleSubmit()}
                        // color="primary"
                        className="btn-popup-one"
                        style={{
                          color: "white",
                          backgroundColor: "#007DC6",
                          fontStyle: "normal",
                          fontSize: "16px",
                          borderRadius: "10px",
                          padding: "8px 20px",
                        }}
                      >
                        Add User
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InviteTeacherModal;
