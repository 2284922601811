import React from "react";
import { Grid } from "@material-ui/core";
import "../Header.scss";

const CommonPageHeader = ({ data }: any) => {
  return (
    <Grid container spacing={5} className="common-header">
      <Grid sm={12} item className="title">
        {data.icon || ""}&nbsp;{data.title || "Title"}
      </Grid>
    </Grid>
  );
};

export default CommonPageHeader;
