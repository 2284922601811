// Customizable Area Start
import React from "react";
import RequestTrainingController, { Props } from "./RequestTrainingController";
import "../assets/requestTraining.scss";
import RequestCard from "../../ContentManagement/src/component/RequestCard";

export default class RequestTraining extends RequestTrainingController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <RequestCard navigation={this.props.navigation} />
            </div>
        );
    }
}
// Customizable Area End
