// Customizable Area Start
import React from "react";
export const configJSON = require("./config");
import CallSchedulerController, { Props } from "./CallSchedulerController";
import CallSchedulerComponent from "./components/CallSchedulerComponent";

export default class CallScheduler extends CallSchedulerController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <CallSchedulerComponent />
        );
    }
}
// Customizable Area End
