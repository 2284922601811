import React from "react";
import { Grid } from "@material-ui/core";
import { listBack, SelectedClassboxBack } from "../assets";

const ClassBoxesCards = ({ state, setClassBoxDetails,itemId }: any) => {
  return (
    <Grid container item sm={12} className="claaaboxes-cards">
      {state.classBoxes?.map((item: any) => (
        <Grid
          item
          className="card pointer"
          sm={12}
          key={item.id}
          onClick={() => setClassBoxDetails(item)}
        >
          <img src={itemId !== item.id ?  listBack : SelectedClassboxBack} alt="icon" className="backimage" />
          <div className="content">
            <span className="title">{item.attributes.title || ""}</span>
            <img src={item.attributes.hero_emoji.url} alt="icon" className="icon" />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default ClassBoxesCards;
