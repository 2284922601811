import React from "react";
import {
  Box,
  Button,
  InputLabel,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { deleteIcon, info, LEFT, RIGHT } from "./assets";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import "../assets/index.scss";

const Themes = createTheme({
  palette: {
    primary: {
      main: "#2fa7f1",
      light: "#DCD2EF",
    },
    secondary: {
      main: "#2fa7f1",
      light: "#DCD2EF",
    },
  },
  typography: {
    fontFamily: "Helvetica Neue",
    button: {
      textTransform: "none",
      letterSpacing: 1,
    },
    h3: {
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
  },
});
const style = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof style> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(style)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// Customizable Area End

import InviteTeacherController, {
  Props,
  configJSON,
} from "./InviteTechersController";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { errorIcon } from "../../EmailNotifications2/src/assets";
import { getInvitesTitle } from "../../../components/src/NativeWebRouteWrapper/Utils";

export default class InviteTeachers extends InviteTeacherController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPasswordSymbol = (errors: any, touched: any) => {
    return !!errors.email && !!touched.email ? (
      <img src={errorIcon} alt="icon" />
    ) : (
      <></>
    );
  };
  // Customizable Area End

  render() {
    let regexObj = new RegExp(
      "^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(" +
        this.state.leadMail +
        ")$"
    );

    const Schema = Yup.object().shape({
      firstName: Yup.string().required("Field is Required."),
      lastName: Yup.string().required("Field is Required."),
      email: Yup.string()
        .email("Email must be in valid format!")
        .matches(regexObj, "Address extensions don't match.")
        .required("Email Address is required."),
    });

    return (
      // Customizable Area Start
      <ThemeProvider theme={Themes}>
        <Grid container className="backgroundImageConatiner">
          <Grid item sm={6}>
            <div className="leftContent">
              <Box
                style={{
                  width: "52%",
                  margin: "auto",
                }}
              >
                <span
                  className="title-bold header-text"
                  style={{ marginBottom: "4%", color: "#a7cce1" }}
                >
                  2/3
                </span>
                <br />
                <span className="left-text">Invite teachers</span>
                <br></br>
                <br></br>
                <br></br>
                <span className="subText title-medium">
                  Click the invite button to add your colleagues to Classbox.
                </span>
                <Box sx={webStyle.flexBox}>
                  <Button
                    className="btn-font"
                    onClick={() => this.props.navigation.goBack()}
                    style={{
                      margin: "4% 2%",
                      backgroundColor: "white",
                      padding: "3% 5%",
                      borderRadius: "10px",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: "#98A2B3",
                    }}
                  >
                    <img src={LEFT} /> &nbsp; Back
                  </Button>
                  <Button
                    className="btn-font"
                    onClick={this.sendInvitesToTechers}
                    style={{
                      margin: "4% 2%",
                      backgroundColor: "white",
                      padding: "3% 5%",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: "#00649E",
                      borderRadius: "10px",
                    }}
                  >
                    {
                    getInvitesTitle(this.state.teacherDetails.length)
                    }{" "}
                    &nbsp; <img src={RIGHT} />
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>

          <Grid container item sm={6} spacing={3} className="rightContainer">
            {this.state.teacherDetails.length != 0 ? (
              <Grid className="gridContainerStyle">
                {this.state.teacherDetails?.map((e: any, i: any) => {
                  return (
                    <Grid
                      item
                      container
                      wrap="wrap"
                      className="teacherDetailsConatiner"
                      key={i}
                    >
                      <Grid item sm={6} className="techerDetails ">
                        <span className="title title-bold">
                          {e.attributes.first_name.length +
                            e.attributes.last_name.length >
                          16
                            ? (
                                e.attributes.first_name +
                                " " +
                                e.attributes.last_name
                              ).slice(0, 16) + "..."
                            : e.attributes.first_name +
                              " " +
                              e.attributes.last_name}
                        </span>
                        <br />
                        <span className="email title-medium">
                          {e.attributes.email}
                        </span>
                      </Grid>
                      <Grid item sm={6} className="teacherDeleteDetails ">
                        <Button className="role title-medium">Teacher</Button>

                        <img
                          src={deleteIcon}
                          onClick={() => this.handleDelete(e.id)}
                          alt="Image"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
            <Grid item sm={12} className="inviteButton">
              <Button
                className="title-medium"
                onClick={this.handleDialog}
                startIcon={
                  <AddCircleOutlineIcon
                    // fontSize="inherit"
                    style={{ color: "#007DC6", fontSize: "40px" }}
                  />
                }
              >
                <Typography className="invite-text">Invite</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          className="dialog-container"
          onClose={this.handleDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.dialogOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleDialog}>
            <Typography
              className="main-text title-medium modal-title-bold"
              gutterBottom
            >
              Invite people
            </Typography>
            <Typography className="sub-text title-medium modal-desc-light">
              Invite new people to your Classbox
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              style={{
                display: "flex",
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                margin: "auto",
                justifyContent: "flex-start",
                marginTop: "-16px",
                paddingLeft: "15px",
              }}
            >
              <Typography
                className="warning-text"
                style={{
                  padding: "5px 0px",
                  paddingLeft: "15px",
                }}
              >
                <img src={info} style={{ padding: "1.5% 0" }} />
                &nbsp; You can only invite teachers who have the same email
                extension as yours
              </Typography>
            </Box>
            <Box>
              <form noValidate autoComplete="off">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                  }}
                  onSubmit={(values) => {
                    this.onSubmitAddTeacher(values);
                  }}
                  validationSchema={Schema}
                >
                  {({
                    handleChange,
                    values,
                    handleSubmit,
                    errors,
                    touched,
                    setFieldValue,
                    isValid,
                    dirty,
                  }) => {
                    return (
                      //@ts-ignore
                      <Form>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "5%",
                          }}
                        >
                          <Box sx={{ width: "48%" }}>
                            <InputLabel
                              htmlFor="my-input"
                              className="pop-up-input-text"
                            >
                              Name
                            </InputLabel>
                            <TextField
                              className="input-label"
                              style={{ marginTop: "3%" }}
                              onChange={handleChange("firstName")}
                              data-test-id={"txtInputfirstName"}
                              value={values.firstName}
                              name="firstName"
                              placeholder="First Name"
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              helperText={<ErrorMessage name="firstName" />}
                              error={!!errors.firstName && !!touched.firstName}
                              required
                            />
                          </Box>
                          <Box sx={{ width: "48%" }}>
                            <InputLabel
                              htmlFor="my-input"
                              className="pop-up-input-text"
                            >
                              Last Name
                            </InputLabel>
                            <TextField
                              className="input-label"
                              style={{ marginTop: "3%" }}
                              onChange={handleChange("lastName")}
                              data-test-id={"txtInputlastName"}
                              value={values.lastName}
                              name="lastName"
                              placeholder="Last Name"
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              helperText={<ErrorMessage name="lastName" />}
                              error={!!errors.lastName && !!touched.lastName}
                              required
                            />
                          </Box>
                        </Box>

                        <InputLabel
                          htmlFor="my-input"
                          style={{ marginTop: "2%" }}
                          className="pop-up-input-text"
                        >
                          Email
                        </InputLabel>
                        <TextField
                          className="input-label"
                          style={{ marginTop: "1%" }}
                          onChange={handleChange("email")}
                          data-test-id={"txtInputProductId"}
                          value={values.email}
                          name="email"
                          placeholder="Enter Email Id"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          helperText={<ErrorMessage name="email" />}
                          error={!!errors.email && !!touched.email}
                          required
                          InputProps={{
                            endAdornment: this.getPasswordSymbol(errors, touched),
                          }}
                        />
                        <DialogActions
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: "0px",
                            gap: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <Button
                            autoFocus
                            onClick={this.handleDialog}
                            color="primary"
                            className="btn-popup"
                            style={{
                              fontStyle: "normal",
                              fontSize: "16px",
                              color: "#00649E",
                              fontWeight: 500,
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            autoFocus
                            onClick={() => handleSubmit()}
                            // color="primary"
                            className="btn-popup-one"
                            style={{
                              color: "white",
                              backgroundColor: "#007DC6",
                              fontStyle: "normal",
                              fontSize: "16px",
                              borderRadius: "10px",
                              padding: "8px 20px",
                            }}
                          >
                            Add User
                          </Button>
                        </DialogActions>
                      </Form>
                    );
                  }}
                </Formik>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  checkBox: {
    margin: "2%",
    width: "50%",
    backgroundColor: "white",
    // height: "20%",
    borderRadius: 10,
    padding: "2%",
    borderColor: "#009ced",
    borderStyle: "solid",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-around",
    marginTop: "20%",
    marginLeft: "-7px",
  },
};
// Customizable Area End
