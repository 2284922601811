// Customizable Area Start
import React from "react";
export const configJSON = require("./config");
import CompleteProjectPageSubController, {
} from "./CompleteProjectPageSubController";
import "../assets/complete-taining.scss";
import { logo, rightWhite } from "./assets";
import { confirmation } from "../../ContentManagement/src/assets";

export default class CompleteProjectPageSub extends CompleteProjectPageSubController {


  render() {
    return (
      <div
       className="complete-taining-container"
       >
        <div 
        className="header"
        style={{justifyContent:"center"}}
        >
          <img 
          src={logo}
           alt="logo" 
           />
        </div>
        <div 
        className="card-container"
        >
          <div 
          className="card  card-success-of-classbox"
          >
            <img
             src={confirmation} 
             alt="img"
              className="img1-of-trainning"
              ></img>
          
            
              <div 
              className="header"
              >
                <h1 
                className="title"
                >Project Complete! 🎉
                </h1>
                <span 
                className="desc desc-text-center">
                Congrats on finishing{" "}
                  <span 
                  className="bold-title-0059rt">
                    {this.state.projectTitle}
                  </span>{" "}
                </span>
              </div>
        
            <div
             className="content"
             >
              <a
                className="dashboard-btn"
                href={this.state.studentViewURL}
                style={{display:"flex",justifyContent:"center",alignItems:"center",textDecoration: "none", cursor: "pointer" }}
              >
                Project Homepage&nbsp;&nbsp;&nbsp;
                <img src={rightWhite} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// Customizable Area End
