import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Checkbox,
  Paper,
  TextField,
  FormControlLabel,
  FormGroup,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required."),
  // .required("This field is required."),
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character."
    )
    .required("Password is Required."),
  // .required("This field is required"),
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#027ec8",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
// Customizable Area End

import NewPasswordController, {
  Props,
  configJSON,
} from "./NewPasswordController";

export default class NewPasswordWeb extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          style={{
            width: "33%",
            display: "flex",
            margin: "auto",
          }}
        >
          <Paper style={{ padding: "4%", width: "90%" }}>
            <Typography variant="h5">Reset Password</Typography>
            {this.state.errorText ? (
              <Box style={{ backgroundColor: "#fff5f5", padding: "1% 3%" }}>
                <Typography style={{ color: "#da7a74" }}>
                  Your password doesn't match the criteria
                </Typography>

                <ul style={{ marginTop: 0, width: "100%" }}>
                  <li style={{ color: "#da7a74" }}>
                    At least one capital letter
                  </li>
                  <li style={{ color: "#da7a74" }}>
                    At least one lower case letter
                  </li>
                  <li style={{ color: "#da7a74" }}>At least one number</li>
                  <li style={{ color: "#da7a74" }}>
                    Minimum character length is 8 character
                  </li>
                </ul>
              </Box>
            ) : null}

            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={(values) => {
                console.log(this.props.navigation);

                this.props.navigation.navigate("ActivityFeed");
                // return true;
              }}
              validationSchema={Schema}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form>
                    {/* Second Way */}

                    <InputLabel style={webStyle.top1}>Password</InputLabel>
                    <TextField
                      onChange={handleChange("password")}
                      data-test-id={"txtInputProductId"}
                      value={values.password}
                      name="password"
                      placeholder="Enter Password"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="password" />}
                      required
                      type="password"
                    />

                    <InputLabel style={webStyle.top1}>
                      Confirm Password
                    </InputLabel>
                    <TextField
                      onChange={handleChange("confirmPassword")}
                      data-test-id={"txtInputProductId"}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="confirmPassword" />}
                      required
                      type="password"
                    />
                    <Button
                      // variant="contained"
                      // type="submit"
                      // color="primary"
                      style={{ marginTop: "4%", float: "right" }}
                      color="primary"
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          successDialog: !this.state.successDialog,
                        })
                      }
                      data-test-id={"btnAddExample"}
                    >
                      Save changes
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>

          <Dialog
            onClose={this.handleDiaog}
            aria-labelledby="customized-dialog-title"
            open={this.state.successDialog}
          >
            <Box style={{ height: "20%", backgroundColor: "#f0fdf4" }}>
              <Typography
                style={{
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  alignSelf: "center",
                }}
              />
            </Box>
            <DialogContent>
              <Typography variant="h5" gutterBottom>
                Password changed successfully!
              </Typography>
              <Typography style={{ fontSize: "0.875rem" }} gutterBottom>
                Please log in again
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                data-test-id={"openMail"}
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                autoFocus
                onClick={this.handleDiaog}
                color="primary"
              >
                Open mail
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  top1: {
    marginTop: "4%",
  },
};
// Customizable Area End
