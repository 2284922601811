import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";

const NoCreditsModal = ({ state, handleNocreditModal, navigation }: any) => {
  return (
    <Dialog
      className="dialog-container-1"
      onClose={handleNocreditModal}
      aria-labelledby="customized-dialog-title"
      open={state.noCreditModalOpen}
    >
      <DialogTitle id="customized-dialog-title">
        <div className="header-main header-main-decline">
          <Typography
            className="main-text title-medium modal-title-bold"
            gutterBottom
          >
            Low credit balance
          </Typography>
          <IconButton onClick={handleNocreditModal}>
            <CancelIcon />
          </IconButton>
        </div>
        <Typography className="desc" style={{ width: "431px" }}>
          Don't have enough credits on your account, please purchase your
          credits.
        </Typography>
      </DialogTitle>

      <DialogActions
        className="dialog-actions-container"
        style={{ paddingBottom: "20px", paddingRight: "35px" }}
      >
        <Button
          autoFocus
          onClick={handleNocreditModal}
          className="cancle-btn"
          style={{ color: "#475467" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          autoFocus
          onClick={() => navigation.navigate("AddCredits")}
          className="add-btn-1"
        >
          Purchase Credits
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoCreditsModal;
