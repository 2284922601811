// Customizable Area Start
import React from "react";
import { Grid, Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import SupportController, { Props } from "./SupportController";
import "../assets/support.scss";
import { backIcon, callIcon, chatIcon, background } from "./assets";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { scrollToTop } from "../../../components/src/NativeWebRouteWrapper/Utils";

export default class Support extends SupportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container item className="support-container">
        {!this.state.data && (
          <Grid container item spacing={5} className="common-header" id="getBackxyz1297">
            <Grid sm={12} item className="title">
              <span>🙋 Support</span>&nbsp;
            </Grid>
          </Grid>
        )}

        {!!this.state.data && (
          <Grid className="back-container">
            <div className="img-div">
              <img
                src={backIcon}
                alt="back"
                className="pointer"
                id="back-btn-id"
                onClick={() => this.setState({ data: null })}
              />
            </div>
          </Grid>
        )}
        {!!this.state.data && (
          <Grid className="min-h-340" sm={12}>
            <div dangerouslySetInnerHTML={{ __html: this.state.data }} />
          </Grid>
        )}
        <div className={!this.state.data ? "suport-content" : "suport-content1"}>
          {!this.state.data && (
            <>
              <Grid item sm={12} xs={12} xl={12} className="header1">
                <div className="content-1">
                  <img src={background} className="img" />
                  <div className="content">
                    <span className="title">How can we</span>
                    <span className="title pad pad1">help you today?</span>
                    <span className="desc title-light">
                      Browse support articles below to get help, and if you
                      can't find what you are looking for, choose an option to
                      contact an instructional coach.
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                container
                sm={12}
                spacing={3}
                xs={12}
                xl={12}
                className="pt-20 blogs-content-actual"
              >
                {this.state.blogs.map((blog: any, index: number) => {
                  return (
                    <Grid item sm={4} xs={4} className="blogs" key={index}>
                      <span className="title top-title">{blog.title}</span>
                      {blog.blogs?.map((data: any) => {
                        return (
                          <span
                            className="menu pointer"
                            onClick={() => {
                              scrollToTop()
                              this.setData(data.content)
                            }}
                            key={data.id}
                          >
                            {data.title}
                          </span>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
          <div className="footer">
            <div className="card">
              <span className="title">
                {!!this.state.data
                  ? "Need something else?"
                  : "Can’t find what you’re looking for?"}
              </span>
              <span className="desc pb-10">We’ll help!</span>
              <div className="btn-container">
                <Button
                  className="btn"
                  href={window.location.origin + "/ScheduleCall"}
                  target="_blank"
                >
                  <img src={callIcon} /> &nbsp; Book a Call
                </Button>
                <Button
                  className="btn"
                  onClick={() =>
                    //@ts-ignore
                    window.drift.api.openChat()
                  }
                >
                  <img src={chatIcon} /> &nbsp; Online Chat
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}
// Customizable Area End
