// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  FormHelperText,
  Checkbox,
  Paper,
  TextField,
  InputLabel,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { loginSideImage, Logo } from "./assets";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import SignUpController, { Props } from "./SignUpController";
import "../assets/index.scss";
import { errorIcon } from "../../EmailNotifications2/src/assets";

const Schema = Yup.object().shape({
  firstName: Yup.string().required("Field is Required."),
  lastName: Yup.string().required("Field is Required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is Required."),
  password: Yup.string()
    .required("Password is Required.")
    .min(8, "Your password Must Contain 8 Characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z]).+$/,
      "Must contain one uppercase letter and one lowercase."
    )
    .matches(/^(?=.*\d).+$/, "Must Contain One Number."),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
  ageCheckbox: Yup.boolean()
    .required("Please check Age Conditions.")
    .oneOf([true], "Please check Age Conditions."),
  termsCheckbox: Yup.boolean()
    .required("Please check Terms and Conditions")
    .oneOf([true], "Please check Terms and Conditions."),
});

export default class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  getPasswordSymbol = (errors: any, touched: any) => {
    return !!errors.password && !!touched.password ? (
      <img src={errorIcon} alt="icon" />
    ) : (
      <></>
    );
  };
  getconfirmPasswordSymbol = (errors: any, touched: any) => {
    return !!errors.confirmPassword && !!touched.confirmPassword ? (
      <img src={errorIcon} alt="icon" />
    ) : (
      <></>
    );
  };

  render() {
    return (
      // Required for all blocks
      <Grid container className="container-signup">
        <Grid item sm={12} md={6} xs={12} className="left">
          <Box style={{marginTop: "107px"}}>
            <Formik
              initialValues={this.state.initialValues}
              onSubmit={(values) => {
                this.handleSignUp(values);
              }}
              validationSchema={Schema}
              enableReinitialize
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form className="form-container">
                    <Paper
                      style={{
                        width: "54%",
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      className="form-container-sub"
                    >
                      <Grid
                        item
                        sm={12}
                        className={this.getClassName(
                          this.state,
                          errors,
                          touched
                        )}
                      >
                        <span className="header-text title-bold">
                          Sign Up to Classbox
                        </span>
                      </Grid>
                      {this.showError(this.state, errors, touched) && (
                        <div className="error-box">
                          <span className="title">
                            Your password doesn't match the criteria
                          </span>
                          <ul className="error-list">
                            <li className="error">
                              At least one capital letter
                            </li>
                            <li className="error">
                              At least one lower case letter
                            </li>
                            <li className="error">At least one number</li>
                            <li className="error">
                              Minimum character length is 8 character
                            </li>
                          </ul>
                        </div>
                      )}
                      <Grid container spacing={2}>
                        <Grid item sm={6}>
                          <InputLabel className="input-title title-light">
                            First Name
                          </InputLabel>
                          <TextField
                            className="title-medium"
                            onChange={handleChange("firstName")}
                            data-test-id={"txtInputfirstName"}
                            value={values.firstName}
                            name="firstName"
                            placeholder="First Name"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            helperText={<ErrorMessage name="firstName" />}
                            error={!!errors.firstName && !!touched.firstName}
                            required
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <InputLabel className="input-title title-medium">
                            Last Name
                          </InputLabel>
                          <TextField
                            className="title-medium"
                            onChange={handleChange("lastName")}
                            data-test-id={"txtInputlastName"}
                            value={values.lastName}
                            name="lastName"
                            placeholder="Last Name"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            helperText={<ErrorMessage name="lastName" />}
                            error={!!errors.lastName && !!touched.lastName}
                            required
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <InputLabel className="input-title title-medium">
                            User Email
                          </InputLabel>
                          <TextField
                            className="title-medium"
                            onChange={handleChange("email")}
                            data-test-id={"txtInputProductId"}
                            value={values.email}
                            name="email"
                            placeholder="Enter Email Id"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            disabled
                            helperText={<ErrorMessage name="email" />}
                            error={!!errors.email && !!touched.email}
                            required
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <InputLabel className="input-title title-medium">
                            Password
                          </InputLabel>
                          <TextField
                            className="title-medium"
                            onChange={handleChange("password")}
                            data-test-id={"txtInpuppassword"}
                            value={values.password}
                            name="password"
                            placeholder="Password"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            InputProps={{
                              endAdornment: this.getPasswordSymbol(errors, touched),
                            }}
                            error={!!errors.password && !!touched.password}
                            required
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <InputLabel className="input-title title-medium">
                            Confirm Password
                          </InputLabel>
                          <TextField
                            className="title-medium"
                            onChange={handleChange("confirmPassword")}
                            data-test-id={"txtInputconfirmPassword"}
                            value={values.confirmPassword}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            helperText={<ErrorMessage name="confirmPassword" />}
                            error={
                              !!errors.confirmPassword &&
                              !!touched.confirmPassword
                            }
                            required
                            InputProps={{
                              endAdornment: this.getconfirmPasswordSymbol(
                                errors,
                                touched
                              ),
                            }}
                          />
                        </Grid>
                        <Grid sm={12} className="checkbox-div">
                          <FormControlLabel
                            className="input-title"
                            name="termsCheckbox"
                            control={<Checkbox color="primary" />}
                            label={
                              <span className="input-title title-medium">
                                I agree to the{" "}
                                <span className="terms-policy">
                                  {" "}
                                  <a
                                    href="https://www.termsfeed.com/live/d9b7a7a2-a0aa-4d4d-ac04-fabd534497b0"
                                    target="_blank"
                                  >
                                    {" "}
                                    Terms of Use
                                  </a>
                                  <span className="and">&nbsp;&&nbsp;</span>
                                  <a
                                    href="https://www.termsfeed.com/live/c47d4916-e1c0-42e9-8064-28697e0c120d"
                                    target="_blank"
                                  >
                                    Privacy Policy
                                  </a>
                                </span>
                              </span>
                            }
                            value={values.termsCheckbox}
                            onChange={handleChange("termsCheckbox")}
                          />
                          {!!errors.termsCheckbox &&
                            !!touched.termsCheckbox && (
                              <FormHelperText error={true}>
                                {errors.termsCheckbox}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid sm={12}>
                          <FormControlLabel
                            name="ageCheckbox"
                            control={<Checkbox color="primary" />}
                            label={
                              <span className="input-title title-medium">
                                I am over 13 years of age
                              </span>
                            }
                            value={values.ageCheckbox}
                            onChange={handleChange("ageCheckbox")}
                          />
                          {!!errors.ageCheckbox && !!touched.ageCheckbox && (
                            <FormHelperText error={true}>
                              {errors.ageCheckbox}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid sm={12}>
                          <Button
                            className="sign-up-btn"
                            color="primary"
                            type="submit"
                            variant="contained"
                            data-test-id={"btnAddExample"}
                          >
                            Sign Up
                          </Button>
                          <Grid sm={12} md={12} className="google-sign-up-btn">
                            <GoogleOAuthProvider clientId="270609563407-91r6a1g3nri7tq0igqnd7g3p36j4ksec.apps.googleusercontent.com">
                              <GoogleLogin
                                onSuccess={this.handleGoogleSignUpApi}
                                onError={this.handleGoogleSignUpApi}
                                text="signup_with"
                              />
                            </GoogleOAuthProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <div className="logo-login">
            <img src={Logo} />
          </div>
        </Grid>

        <Grid sm={12} md={6} xs={12} className="right">
          <div className="image-container">
            <img src={loginSideImage} className="img" />
            <div className="image-text">
              <p className="titel-text">Join Classbox</p>
              <p className="desc">
                Join your colleagues at{" "}
                <span className="school">{this.state.schoolData || ""}</span> on
                Classbox to bring more hands-on learning to your classroom.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area End
