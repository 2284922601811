import React from "react";
import { Grid, Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
const TrainingDetailsMain = ({
  state,
  getButtonTitle,
  showPrevious,
  updateStepStatus,
  getStepsDataWithTraining,
  getPreviousStepId,
}: any) => {
  return (
    <Grid item sm={10} className="content">
      <Grid sm={12} className="course-content">
        <div
          dangerouslySetInnerHTML={{
            __html:
              state?.trainingDetails?.current_step_data?.data?.attributes
                ?.content,
          }}
          className="course-content-container"
        />
      </Grid>
      {state?.trainingDetails?.training_data?.projects && (
        <Grid item sm={12} className={showPrevious() ? "btn" : "btn1"}>
          {showPrevious() && (
            <Button
              className="change-btn1"
              startIcon={<KeyboardBackspaceIcon style={{ color: "#007DC6" }} />}
              onClick={() => getPreviousStepId()}
            >
              Previous step
            </Button>
          )}
          <Button
            variant="contained"
            className="change-btn2"
            endIcon={<DoneIcon style={{ color: "white" }} />}
            onClick={() => updateStepStatus()}
          >
            {getButtonTitle()}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TrainingDetailsMain;
