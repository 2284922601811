// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getTeacherNameReverse,
  getUserDetails,
  handleTokenError,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  openModal: string;
  grade: string;
  type: string;
  sessionPerWeek: string;
  tutionTime: string;
  grades: string[];
  types: string[];
  sessions: string[];
  tutionTimes: string[];
  anchorEl: null;
  // Customizable Area Esnd
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class InviteTeacherController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitApiCallId: any;
  addAdditionalDetailApiCallId: any;
  updateUserStatus: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      open: false,
      openModal: "",
      grade: "",
      type: "",
      sessionPerWeek: "",
      tutionTime: "",
      grades: [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
      ],
      types: ["STEM Teacher", "General Studies Teacher", "CTE Teacher"],
      sessions: ["1", "2", "3", "4", "5"],
      tutionTimes: ["45", "60", "90", "120"],
      anchorEl: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.addAdditionalDetailApiCallId
      ) {
        let data = JSON.parse(localStorage.getItem("userInfo") || "");
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
            ...responseJson.data.attributes,
            teacher_type: getTeacherNameReverse(
              responseJson.data.attributes.teacher_type
            ),
          })
        );
        this.props.navigation.navigate("StandardSelection", { isLead: "false" });
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.updateUserStatus
      ) {
        localStorage.setItem("isFirstTeacher", "true");
        localStorage.setItem("showHeader", "true");
        this.props.navigation.navigate("Dashboard", { isFromSignup: true });
      }
    }
    // Customizable Area End
  }

  handleCloseModal = () => {
    this.setState({ openModal: "" });
    return true;
  };
  handleOpenModal = (e: any, value: string) => {
    this.setState({ openModal: value, anchorEl: e.currentTarget });
    return true;
  };
  setSelection = (item: any) => {
    let obj: any = {};
    obj[item.name] = item.value;
    this.setState(obj);
    return true;
  };

  handleSubmit = () => {
    const token = getAuthToken();
    const user = getUserDetails();
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    let data = {
      grade: this.state.grade,
      teacher_type: getTeacherNameReverse(this.state.type),
      times_per_week: this.state.sessionPerWeek,
      time_per_session_in_minutes: this.state.tutionTime,
      activated: true,
    };

    const httpBody = {
      ...data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdditionalDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postTeacherDetails}?id=${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateOnboarding = () => {
    const token = getAuthToken();
    const user = getUserDetails();
    const header = {
      token: token,
    };

    const httpBody = {
      activated: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserStatus = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activateUser}${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethode
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}

// Customizable Area End

// Customizable Area End
