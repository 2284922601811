import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Button } from "@material-ui/core"
import AlignmetOne from './AlignmetOne';
import AlignmentTwo from './AlignmentTwo';
import { getCommaSeperator } from "../../../../components/src/NativeWebRouteWrapper/Utils";

function CardComponent({
  savingToOrder,
  handleAlignment,
  alignment,
  state,
  increaseQuantity,
  increasePurchaseQuantity,
  classBoxDetails, setSelectedRange,
  selectedRange,
  months,
  setMonth
}: any) {
  return (
    <>
      <div className="card-main-div">
        <div className="toggle-div">
          <span className="sub-text">{classBoxDetails?.attributes?.title}</span>
          {classBoxDetails.attributes.checkout !== null && classBoxDetails.attributes.checkout === true && <ToggleButtonGroup
            value={"checkout"}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            className="toggle-back"
          >
            <ToggleButton
              value="0"
              aria-label="left aligned"
              className={alignment === "0" ? "toggle-btn-color" : "toggle-btn"}
            >
              <span style={{ padding: "5px" }}>Checkout</span>
            </ToggleButton>
            <ToggleButton
              value="1"
              aria-label="centered"
              className={alignment === "1" ? "toggle-btn-color" : "toggle-btn"}
            >
              <span style={{ padding: "5px" }}> Purchase</span>
            </ToggleButton>
          </ToggleButtonGroup> 
          }
          {classBoxDetails.attributes.checkout !== null && classBoxDetails.attributes.checkout === false && <span style={{
            color: "white",
            backgroundColor: "#485468",
            borderRadius: "50px",
            padding: "10px 30px",
            fontSize: "14px",
            fontWeight: 500
          }} className="purchase-btn">Purchase Only</span> }


        </div>
        <AlignmentTwo alignment={alignment} state={state} increasePurchaseQuantity={increasePurchaseQuantity} classBoxDetails={classBoxDetails} />
        <AlignmetOne state={state} increaseQuantity={increaseQuantity} selectedRange={selectedRange} setSelectedRange={setSelectedRange} months={months} setMonth={setMonth} alignment={alignment} />
        <div className="bottom-div">
          <Button className="save-btn" onClick={savingToOrder}>
            Save To Order
          </Button>
          <div>
            <span className="save-text">Classbox Credits Cost</span>
            <br />
            {alignment === "0" ? (
              <span className="save-text-credit">
                {getCommaSeperator(+state?.checkOutCost * state.months)}
              </span>
            ) : (
              <span className="save-text-credit">
                {getCommaSeperator(+state?.purchaseCost)}
              </span>
            )}
          </div>

        </div>
      </div >
    </>
  );
}

export default CardComponent;
