import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { iconOne, iconTwo, iconThree, logoImg } from "./assets";
import "../assets/index.scss";
import { getUserDetails } from "../../../components/src/NativeWebRouteWrapper/Utils";
const Theme = createTheme({
  palette: {
    primary: {
      main: "#024c7a",
      light: "#DCD2EF",
    },
    secondary: {
      main: "#007DC6",
      light: "#DCD2EF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
      letterSpacing: 1,
    },
    h3: {
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
  },
});

// Customizable Area End

import LeadRoleScreenController, {
  Props,
  configJSON,
} from "./LeadRoleScreenController";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";

export default class LeadRoleScreen extends LeadRoleScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ radioButtonSelected: event.target.value });
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-box">
        <img src={logoImg} alt="logo" className="logo" />
        <ThemeProvider theme={Theme}>
          <Box
            bgcolor={"#d1efff"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
              flex: 1,
            }}
          >
            <Box
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                style={{
                  width: "70%",
                }}
              >
                <span
                  className="title-bold header-text"
                  style={{ color: "#a7cce1" }}
                >
                  1/3
                </span>
                <br />
                <span className="left-text ">Welcome</span>
                <br />
                <span className="left-text ">to Classbox!</span>
                <br />
                <span className="left-sub-text ">
                  You've been invited to become the Classbox Lead
                  <span className="left-sub-text ">
                    {getUserDetails()
                      ?.school?.data?.attributes?.name.toLowerCase()
                      .includes("school")
                      ? ` for ${
                          getUserDetails()?.school?.data?.attributes?.name || ""
                        }`
                      : ` for ${
                          getUserDetails()?.school?.data?.attributes?.name || ""
                        } School.`}
                  </span>
                </span>
              </Box>
            </Box>
            <Box
              style={{
                width: "50%",
                verticalAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "auto",
              }}
            >
              <Box>
                <span
                  style={{
                    margin: "2%",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "24px",
                    color: "#004B77",
                  }}
                  className="title-medium"
                >
                  I am a ...
                </span>
                <Box
                  id="stem-btn"
                  style={
                    this.state.radioButtonSelected == "stem_teacher"
                      ? webStyle.checkBox1
                      : webStyle.checkBox
                  }
                  onClick={() => this.handleCheckBoxChange("stem_teacher")}
                >
                  <Box sx={webStyle.flexBox}>
                    <img src={iconOne}></img>
                    <Radio
                      checked={
                        this.state.radioButtonSelected === "stem_teacher"
                      }
                      onChange={this.handleChange}
                      value="stem_teacher"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      color={"primary"}
                    />
                  </Box>
                  <Typography className="right-text title-medium">
                    STEM Teacher
                  </Typography>
                </Box>
                <Box
                  id="cte-btn"
                  style={
                    this.state.radioButtonSelected == "cte_teacher"
                      ? webStyle.checkBox1
                      : webStyle.checkBox
                  }
                  onClick={() => this.handleCheckBoxChange("cte_teacher")}
                >
                  <Box sx={webStyle.flexBox}>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6.66659V3.33325M35 24.8958C31.3353 29.0478 25.9735 31.6666 20 31.6666C14.0265 31.6666 8.66472 29.0478 5 24.8958M17.908 14.4361L5 36.6666M22.092 14.4361L35 36.6666M20 6.66659C17.6988 6.66659 15.8333 8.53207 15.8333 10.8333C15.8333 13.1344 17.6988 14.9999 20 14.9999C22.3012 14.9999 24.1667 13.1344 24.1667 10.8333C24.1667 8.53207 22.3012 6.66659 20 6.66659Z"
                        stroke="#0099F3"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <Radio
                      checked={this.state.radioButtonSelected === "cte_teacher"}
                      onChange={this.handleChange}
                      value="cte_teacher"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      color={"primary"}
                    />
                  </Box>
                  <Typography className="right-text title-medium">
                    CTE Teacher
                  </Typography>
                </Box>
                <Box
                  style={
                    this.state.radioButtonSelected == "general_studies_teacher"
                      ? webStyle.checkBox1
                      : webStyle.checkBox
                  }
                  onClick={() =>
                    this.handleCheckBoxChange("general_studies_teacher")
                  }
                  id="genera-study-btn"
                >
                  <Box sx={webStyle.flexBox}>
                    <img src={iconTwo}></img>
                    <Radio
                      checked={
                        this.state.radioButtonSelected ===
                        "general_studies_teacher"
                      }
                      onChange={this.handleChange}
                      value="general_studies_teacher"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      color={"primary"}
                    />
                  </Box>
                  <Typography className="right-text title-medium">
                    General Studies Teacher
                  </Typography>
                </Box>
                <Box
                  id="Admin-btn"
                  style={
                    this.state.radioButtonSelected == "administrator"
                      ? webStyle.checkBox1
                      : webStyle.checkBox
                  }
                  onClick={() => this.handleCheckBoxChange("administrator")}
                >
                  <Box sx={webStyle.flexBox}>
                    <img src={iconThree}></img>

                    <Radio
                      checked={
                        this.state.radioButtonSelected === "administrator"
                      }
                      onChange={this.handleChange}
                      value="administrator"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                      color={"primary"}
                    />
                  </Box>
                  <Typography className="right-text title-medium">
                    Administrator
                  </Typography>
                </Box>
                <Button
                  className="title-bold class-btn "
                  onClick={() => this.handleRoleSelect()}
                  endIcon={<ArrowForwardIcon color="primary" />}
                >
                  Select
                </Button>
              </Box>
            </Box>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={this.state.showSnackbar}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MuiAlert
              onClose={this.handleCloseSnackbar}
              severity={this.state.severity}
            >
              {this.state.message}
            </MuiAlert>
          </Snackbar>
          <LoaderComponent open={this.state.loading} />
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  checkBox: {
    margin: "2%",
    width: "55%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: "2%",
    borderStyle: "solid",
    borderColor: "white",
  },
  checkBox1: {
    margin: "2%",
    width: "55%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: "2%",
    borderStyle: "solid",
    borderColor: "#007DC6",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
// Customizable Area End
