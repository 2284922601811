Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.getUserDetailsAPiEndPoint = "account_block/accounts/";
exports.updateUserDetailsAPiEndPoint = "account_block/accounts/";

exports.getUserDetailsAPiMethod = "GET";
exports.updateUserDetailsAPiMethod = "PATCH";
exports.getUserDetailsApiContentType = "application/json";
exports.updateTeacherDetailsAPiEndPoint =
  "account_block/accounts/teacher_details?id=";
exports.updateUserDetailsApiContentType = "application/json";
exports.changePasswordApi = "account_block/accounts/change_password?id=";
exports.changePasswordMethod = "PUT";
exports.changePasswordApiContentType = "application/json";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.updateImageField = "account_block/accounts/";
exports.deleteMethod = "DELETE"
exports.exampleAPiMethod = "POST";
exports.getStandardsDataApiEndpoint = "bx_block_profile/standard_sets"; 
exports.setStandardsDataApiEndpoint = "bx_block_profile/account_standards";
exports.unSetStandardsDataApiEndpoint = "bx_block_profile/account_standards/";
// Customizable Area End

