// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  handleTokenError,
  handleUncompleteSteps,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface OverviewData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    status: string;
    short_description: string;
    credit_award_available: boolean;
    credit_award: number;
    grade: string[];
    subject_tags: (string | string[])[];
    duration: number;
    project_step_number: number;
    present_url: string;
    related_requirements: {
      category: string;
      requirements: {
        data: Requirement[];
      };
    }[];
    admin_data: {
      data: {
        id: string;
        type: string;
        attributes: {
          first_name: string;
          last_name: string | null;
          email: string;
          role: string;
          betabox_image: string | null;
          project_creator_card_image: string | null;
        };
      };
    };
    hero_image: {
      url: string;
    };
    project_emoji: {
      url: string;
    };
    student_view: string | undefined;
    pdf_file: {
      url: string;
    };
    related_classboxes: {
      id: number;
      title: string;
      hero_image_url: {
        url: string;
      };
      emoji_url: {
        url: string;
      };
      owned_classbox: boolean;
    };
    related_training: {
      id: number;
      title: string;
      hero_image_url: string;
    }[];
  };
}

interface StepsData {
  id: string;
  type: string;
  attributes: {
    step_name: string;
    duration_in_hours: number;
    project_id: number;
    step_number_in_sequence: number;
    teaching_tips: string;
    content: string;
  };
}

interface Requirement {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    tag: string;
    category: string;
    icon: string;
  };
}

interface JsonResponse {
  overview: {
    data: OverviewData;
  };
  steps: {
    data: StepsData[];
  };
  project: {
    project_emoji: string;
    project_id: number | string;
    project_status_calculation: number;
    project_chapters: {
      project_chapter_id: string | number; 
      title: string;
      status: string;
    }[];
    overview: {
      data: OverviewData;
    };
    account_project : string
  };
  current_project_step_data:{
    status:string;
    data:{
      attributes:{
        step_name:string
      }
    }
    project_chapter_id: string | number;
  }
}

export interface S {
  // Customizable Area Start
  severity: Color;
  message: string;
  loading: boolean;
  showSnackbar: boolean;
  modalStaetTech : boolean;
  modalStateLms : boolean;
  tabId: number;
  projectDetails: JsonResponse;
  overview: boolean;
  previewMode: string;
  htmlContent: string;
  standardTab: string;
  selectedPrevieStepId:string;
  copied: boolean;
  clipboardContent: string;
  getID:string;
  projectStepData:any
  allProjectDetails:any
  paramTitle:string
  currentProjectId:any
  // Customizable Area End
}

interface SS {
  id: number;
}
// Customizable Area Start
export default class ProjectDetailsSubController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTrainingDataApiId: string = "";
  updateStepStatusApiCallId: string = "";
  getStepsDataWithProjectDataApiId: string = "";
  getProjectsDataApiId: string = "";
  getProjectsDataPreviewApiId: string = "";
  plusPlan: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      // Customizable Area Start
      showSnackbar: false,
      modalStaetTech: false,
      modalStateLms: false,
      severity: undefined,
      message: "",
      loading: false,
      tabId: 0,
      projectDetails: {} as JsonResponse,
      overview: true,
      previewMode: "",
      htmlContent: "<></>",
      standardTab: "0",
      selectedPrevieStepId: "",
      copied: false,
      clipboardContent: '',
      getID:"",
      projectStepData:{},
      allProjectDetails:{},
      paramTitle:"",
      currentProjectId:0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let getDataID = await getStorageData("id")
    let getDataMode = await getStorageData("mode")
    let getParamTitle = this.props.navigation?.getParam("id");
    let formattedTitle = getParamTitle.replace(/-/g, ' ');
    this.setState({paramTitle:formattedTitle})
    this.setState({ getID: getDataID})
    this.setState({
      previewMode: getDataMode,
    });
      this.getProjectsDataPreview();
      
    // Customizable Area End
  }

  async componentWillUnmount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      this.setState({
        loading: false,
      });

      if (!responseJson.errors) {
        if (
          apiRequestCallId === this.getProjectsDataApiId ||
          apiRequestCallId === this.getStepsDataWithProjectDataApiId
        ) {
           this.setState({
             projectDetails: responseJson,
           });
         }
        else if (apiRequestCallId === this.updateStepStatusApiCallId) {
          this.getNextStepId(!!this.state.projectDetails?.project.project_chapters.find((item: { status: string }) => item.status !== "completed"));
           this.setState({
             projectDetails: responseJson,
           });
         }

         else /*istanbul ignore if*/ if (apiRequestCallId === this.getProjectsDataPreviewApiId) {
           this.setState({
             allProjectDetails: responseJson,
           });
           let findData = this.state.allProjectDetails.data.find((item:any)=> {let itemTitle = item.attributes?.title.replace(/\?/g, "");
           return itemTitle === this.state.paramTitle;})
           this.setState({currentProjectId:findData.id})
           this.getProjectsData(findData.id);
        }
        else /*istanbul ignore if*/ if (apiRequestCallId === this.getProjectsDataApiId) {
           this.setState({
             projectDetails: responseJson,
             htmlContent:
               responseJson?.project.project_chapters[0]?.project_chapter_data?.data?.attributes.content || "<></>",
           });
        }
      }

      // Customizable Area End
    }
  }
  
  toggleOverview = (value: boolean) => {
    this.setState({
      overview: value,
      projectStepData: this.state.projectDetails?.project?.project_chapters[0]
    });
  };
  setContent = (content: string, stepId: string) => {
    this.setState({
      htmlContent: content,
      overview: false,
      selectedPrevieStepId: stepId
    });
  };



 





/* istanbul ignore next */
  getPreviousStepId = () => {

    if (
     
      this.state.projectDetails?.project?.project_chapters[0]
       
      ?.project_chapter_id ===
      this.state.projectDetails?.current_project_step_data?.project_chapter_id
    )
     {
     
      return;
    } 
    else {
      let indexOfNextStep = 0;
     
      this.state.projectDetails?.project?.project_chapters.map(
        
        (item: {project_chapter_id: string | number}, index: number) => {
          if (
           
            item.project_chapter_id ===
          
            this.state.projectDetails?.current_project_step_data
              .project_chapter_id
          ) {
            indexOfNextStep = index - 1;
          }
         
          return item;
        }
      );
      this.getStepsDataWithTraining(
        
         this.state.projectDetails?.project?.project_chapters[indexOfNextStep]
           
         .project_chapter_id
       );
      
       this.setState({
      
         overview: false,
       });
     }
   };
 /* istanbul ignore next */
   showPrevious = () => {
     return (
       this.state?.projectDetails?.project?.project_chapters[0]
         .project_chapter_id !==
       this.state.projectDetails?.current_project_step_data?.project_chapter_id
     );
   };
  /* istanbul ignore next */
  getProjectsDataPreview = () => {

    let getIDx = this.state.getID
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(

      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsDataPreviewApiId = requestMessage.messageId;
    requestMessage.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects`
    );

    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)

    );
    this.setState({
      loading: true,
    });


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  };
/* istanbul ignore next */
getNextStepId = (setp: boolean) => {
  if (
    this.state.projectDetails?.project?.project_chapters[
     
       this.state.projectDetails?.project?.project_chapters.length - 1
 
     ]?.project_chapter_id ===
 
     this.state.projectDetails?.current_project_step_data?.project_chapter_id
 
   ) {
     
     handleUncompleteSteps(this.state, this.props.navigation, this.getStepsDataWithTraining, setp)
   } else 
   {
    
     let indexOfNextStep = 0;
     this.state.projectDetails?.project?.project_chapters.map(
 
       (item: {project_chapter_id: string | number}, index: number) => {
         if (
           item?.project_chapter_id ===
           this.state.projectDetails?.current_project_step_data
             ?.project_chapter_id
         ) 
         {
           indexOfNextStep = index + 1;
         }
         
         return item;
       }
     );
     
     this.getStepsDataWithTraining(
      
       this.state.projectDetails?.project?.project_chapters[indexOfNextStep]
         .project_chapter_id
     );
   }
 };
/*istanbul ignore next*/
   getProjectsData = (findDataID:any) => {
     let getIDx = findDataID
     const header = {
     
      "Content-Type": configJSON.categoryApiContentType,
     };
     
 
 
      const requestMessage = new Message(
       
       getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getProjectsDataApiId = requestMessage.messageId;
      requestMessage.addData(
      
       getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/projects/${getIDx}`
      );
  
      requestMessage.addData(
        
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
        );
        requestMessage.addData(
          
         getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        this.setState({
          loading: true,
        });
 
      
  
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
      return true;
    };

  navigatFinshStep = () => {
    this.props.navigation.navigate('CompletedProjectSub');
  } 

   getStepsDataWithTraining = (stepID: string | number | undefined) => {
    
     let stepData = this.state.projectDetails.project?.project_chapters.find((item) => item.project_chapter_id === stepID)
     this.setState({ projectStepData: stepData })
    let token = getAuthToken();
   
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
     
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStepsDataWithProjectDataApiId = requestMessage.messageId;
    requestMessage.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/project_steps/${stepID}`

    );

    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)

    );
    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType

    );


    this.setState({

      loading: true,

      overview: false,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  


  
}
// Customizable Area End

// Customizable Area End
