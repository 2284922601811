import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DoneIcon from "@material-ui/icons/Done";
import { rewardsIcon, hoursIcon, rightArrowIcon, closeIcon } from "../assets";
import "../../assets/trainings.scss";
import { getCommaSeperator, getHrs } from "../../../../components/src/NativeWebRouteWrapper/Utils";
export default function TrainingModal({
  open,
  handleClose,
  trainingData,
  navigation,
  onPatnerTrainingOrder,
  patnerTrainingOrder,
  patnerTrainingOrderError,
  onClickRequestTraining,
}: any) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <MuiDialogContent className="training-div">
        <img
          className="trainingModal-img"
          src={trainingData?.attributes?.hero_image.url}
        />
        <img
          onClick={handleClose}
          className="training-close-icon"
          src={closeIcon}
          alt="i"
        />
      </MuiDialogContent>
      <MuiDialogActions>
        <Grid container className="pad-34-20">
          <Grid item sm={12} className="p-b-15">
            <span className="training-title-txt">
              {trainingData?.attributes?.content_tags.join(", ")}
            </span>
          </Grid>
          <Grid item sm={12} className="p-b-15">
            <span className="training-subtitle-txt">
              {trainingData?.attributes?.title}
            </span>
          </Grid>
          {trainingData?.attributes?.training_type === "Partner" && (
            <Grid item sm={12} className="modal-logo-div">
              <img
                className="modal-logo-icon"
                src={trainingData?.attributes?.creater_logo.url}
              />
              <span className="modal-logoname">
                {trainingData?.attributes?.creater_name}
              </span>
            </Grid>
          )}
          <Grid item sm={12} className="p-b-15 p-b-150">
            <span className="training-desc-txt">
              {trainingData?.attributes?.summary}
            </span>
          </Grid>
          <Grid className="box-div" sm={12}>
            <Grid item sm={6} className="box-content">
              <img className="box-icon" src={hoursIcon} />
              <div className="training-icon-box">
                <span className="training-icon-label">Duration</span>
                <br />
                <span className="training-icon-name">
                  {trainingData?.attributes?.duration} {getHrs(trainingData?.attributes?.duration)}
                </span>
              </div>
            </Grid>
            <Grid item sm={6} className="box-content">
              <img className="box-icon" src={rewardsIcon} />
              <div className="training-icon-box">
                <span className="training-icon-label">{trainingData?.attributes?.credit_type === "Cost" ? "Cost" : "Reward"} </span>
                <br />
                <span className="training-icon-name">
                  {trainingData?.attributes?.credit_amount == 1 ? `${getCommaSeperator(trainingData?.attributes?.credit_amount)} Credit` : `${getCommaSeperator(trainingData?.attributes?.credit_amount)} Credits`}

                </span>
              </div>
            </Grid>
          </Grid>

          {patnerTrainingOrderError &&
            (trainingData.attributes.status === null || trainingData.attributes.status === "declined") && (
              <Grid item sm={12}>
                <div className="error-on-order">
                  <span className="error-text">
                    The Credits Cost of this training exceeds your current
                    balance. You can submit an approval request to your Classbox
                    Lead to order this training.
                  </span>
                </div>
              </Grid>
            )}
          <Grid item container sm={12} className="training-modal-actions">
            {trainingData?.attributes?.training_type === "Partner" &&
              patnerTrainingOrderError &&
              (trainingData.attributes.status === null || trainingData.attributes.status === "declined") && (
                <Button
                  className="right-btn"
                  onClick={() => onClickRequestTraining(trainingData?.id)}
                >
                  Request to Order Training &nbsp;
                  <img src={rightArrowIcon} alt="i" />
                </Button>
              )}


            {trainingData.attributes.training_type === "Partner" &&
              trainingData.attributes.status === "completed" && (
                <Button
                
                  className="completed-btn"
                  endIcon={<DoneIcon style={{ color: "#039855" }} />}
                >
                  Completed &nbsp;&nbsp;
                </Button>
              )}


            {trainingData?.attributes?.training_type === "Partner" &&
              trainingData?.attributes?.status === "requested" && (
                <Button
                 
                  className="completed-btn"
                  endIcon={<DoneIcon style={{ color: "#039855" }} />}
                >
                  Requested &nbsp;&nbsp;
                </Button>
              )}


            {trainingData?.attributes?.training_type === "Partner" &&
              trainingData?.attributes?.status === "ordered" && (
                <Button
                  onClick={() => onPatnerTrainingOrder(trainingData?.id)}
                  className="completed-btn"
                  endIcon={<DoneIcon style={{ color: "#039855" }} />}
                >
                  Ordered &nbsp;&nbsp;
                </Button>
              )}

            {trainingData.attributes.training_type === "Partner" && !patnerTrainingOrderError  && (trainingData.attributes.status === null || trainingData.attributes.status === "declined") && (
              <Button
                className="right-btn"
                onClick={() => onPatnerTrainingOrder(trainingData?.id)}
              >
                Order Training &nbsp;&nbsp;
                <img src={rightArrowIcon} alt="i" />
              </Button>
            )}

            {trainingData.attributes.training_type !== "Partner" && trainingData.attributes.status === null && (
              <Button
                id="btn-navigate"
                className="right-btn"
                onClick={() =>
                  navigation.navigate("TrainingDetails", {
                    id: trainingData.id,
                    credit: trainingData.attributes.credit_amount
                  })
                }
              >
                Start Training &nbsp;&nbsp;
                <img src={rightArrowIcon} alt="i" />
              </Button>
            )}
          </Grid>
        </Grid>
      </MuiDialogActions>
    </Dialog>
  );
}
