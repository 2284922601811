import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  errorText: boolean;
  successDialog: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordOneController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: true,
      // Customizable Area Start
      errorText: false,
      successDialog: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.setState({
        loading: false,
        successDialog: true,
      });
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.resetPasswordApiCallID
      ) {
        this.setState({successDialog:true})
      }
    }
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
    return true;
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
    return true;
  };

  // Customizable Area Start

  changePassword = (values: any) => {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      data: { new_password: values.password, confirm_password: values.confirmPassword },
    };

    //@ts-ignore
    let params = (new URL(window.location)).searchParams;
    let userId = params.get('id'); // is the string "Jonathan Smith".

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.httpResetPasswordPostEndPoint}${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ...httpBody })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetPasswordMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDiaog = () => {
    this.setState({ successDialog: !this.state.successDialog });
    this.props.navigation.navigate("Login");
    return true;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };
  // Customizable Area End
}
