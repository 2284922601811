// Customizable Area Start
import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Tabs,
  Tab,
} from "@material-ui/core";
export const configJSON = require("./config");
import TrainingsController, { Props } from "./TrainingsController";
import "../assets/trainings.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import TrainingModal from "./Components/TrainingModal";
import { getCommaSeperator, getHours, isEmptyObj, shortString } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { creditIcon, debitIcon, hoursIcon } from "./assets";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import InfiniteScroll from "react-infinite-scroll-component";

export default class Trainings extends TrainingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <div>
          <Tabs
            className="main-tab"
            value={this.state.trainingType}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            // @ts-ignore
            onChange={this.handleTrainingChange}
          >
            <Tab
              value=""
              className={
                this.state.trainingType == "" ? "active-tab" : "deactive-tab"
              }
              label="All Trainings"
            />
            <Tab
              value="Classbox"
              className={
                this.state.trainingType == "Classbox"
                  ? "active-tab"
                  : "deactive-tab"
              }
              label="Classbox"
            />
            <Tab
              value="Partner"
              className={
                this.state.trainingType == "Partner"
                  ? "active-tab"
                  : "deactive-tab"
              }
              label="Partners"
            />
          </Tabs>
        </div>
        <Grid container item spacing={2} className="training-container">
          {this.state.trainingList.map((trainingdata: any, index: number) => {
            return (
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                key={trainingdata.id}
                className="pointer"
              >
                <Card
                  onClick={() => this.openTrainingModal(trainingdata)}
                  className="card-main"
                  id="training-card"
                  style={{ height: "100%", position: "relative" }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <img
                          className="card-icon"
                          src={trainingdata.attributes.hero_image.url}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <span className="card-heading">
                          {trainingdata.attributes.content_tags.join(", ")}
                        </span>
                        <div style={{ height: "10px" }} />
                        <span className="card-subheading">
                          {trainingdata.attributes.title}
                        </span>
                        <div style={{ height: "10px" }} />
                        <div className="logo-div">
                          <img
                            className="logo-icon"
                            src={trainingdata.attributes.creater_logo.url}
                          />
                          <Typography className="card-logoname">
                            {trainingdata.attributes.creater_name}
                          </Typography>
                        </div>
                        <div className="hours-credit-div">
                          <Chip
                            className="hours-chip"
                            label={trainingdata.attributes.duration + " " + getHours(trainingdata.attributes.duration)}
                            avatar={
                              <Avatar
                                className="iconavatar"
                                alt="Natacha"
                                src={hoursIcon}
                              />
                            }
                          />
                          <Chip
                            className={
                              trainingdata.attributes.credit_type === "Cost"
                                ? "dedit-chip"
                                : "credit-chip"
                            }
                            label={

                              trainingdata?.attributes?.credit_amount == 1 ? `${getCommaSeperator(trainingdata?.attributes?.credit_amount)} Credit` : `${getCommaSeperator(trainingdata?.attributes?.credit_amount)} Credits`
                            }
                            avatar={
                              <Avatar
                                className="iconavatar"
                                alt="Natacha"
                                src={
                                  trainingdata.attributes.credit_type === "Cost"
                                    ? debitIcon
                                    : creditIcon
                                }
                              />
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="card-desc">
                          {shortString(trainingdata.attributes.summary, 178)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <LinearProgress
                    className="training-progressbar"
                    variant="determinate"
                    value={
                      trainingdata.attributes.training_complition_percentage
                    }
                    style={{
                      overflow: "hidden",
                      position: "absolute",
                      bottom: "0px",
                      width: "100%",
                    }}
                  />
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {!isEmptyObj(this.state.trainingData) && <TrainingModal
          trainingData={this.state.trainingData}
          open={this.state.showTrainingModal}
          handleClose={this.closeTrainingModal}
          navigation={this.props.navigation}
          onPatnerTrainingOrder={this.onPatnerTrainingOrder}
          patnerTrainingOrder={this.state.patnerTrainingOrder}
          patnerTrainingOrderError={this.state.patnerTrainingOrderError}
          onClickRequestTraining={this.onClickRequestTraining}
        />}

        <LoaderComponent open={this.state.loading} />
      </>
    );
  }
}
// Customizable Area End
