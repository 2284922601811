import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import {
  hand,
  thunder,
} from "../assets";
import Avatar from "@material-ui/core/Avatar";
import { getUserDetails } from "../../../../components/src/NativeWebRouteWrapper/Utils";
import { rightArrowIcon } from "../../../catalogue/src/assets";

export default function RequestCard({ navigation }: any) {
  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    lead_type: "",
    nvited_by: "",
    teacher_type: "",
    role: {
      name: "",
    },
    image: { url: "" },
    account_credits: {
      data: [
        {
          attributes: {
            recieved: 0,
            award: 0,
            reward: 0,
            claimed: 0,
            reclaimed: 0,
            spent: 0,
            current_balance: 0,
          },
        },
      ],
    },
    school: {
      data: {
        id: "",
        type: "",
        attributes: {
          name: "",
          address: "",
          current_balance: 0,
          spent: 0,
          school_credits: {
            data: [
              {
                attributes: {
                  recieved: 0,
                  award: 0,
                  reward: 0,
                  claimed: 0,
                  reclaimed: 0,
                  spent: 0,
                  current_balance: 0,
                },
              },
            ],
          },
          manager_details: {
            email: "",
            first_name: "",
            last_name: "",
          },
        },
      },
    },
    invited_by: "",
    teacher_mode: false
  });

  useEffect(() => {
    setUser(getUserDetails());
  }, []);
  return (
    <div className="container-comfirmed1 xavc1">
      <img src={hand} alt="img" className="img1"></img>
      <div className="request-top-div1">
        <span className="request-title1">Waiting for approval</span>
        <br></br>
        <br></br>

        <span className="request-description1">
          <span className="request-description2">{user?.school?.data?.attributes?.name}</span>’s Classbox
          Lead{" "}
          <span className="request-description2">{user.teacher_mode ? user.first_name + ' ' + user.last_name : user.invited_by}</span> has
        </span>
        <span className="request-description1">
          received this request and it is currently under review.
        </span>
      </div>
      <div className="bottom-div1">
        <div className="request-classbox-item">
          <div className="avatar-card">
            <Avatar className="avtar icon-radius-of-round1212" src={user?.image?.url || ""}>
              <span className="avtar-fallback">A</span>
            </Avatar>
            <div className="request-classbox-text">
              {/* <span className="name">{user?.invited_by || "Lead Name"}</span> */}
              <span className="name">{user.teacher_mode ? user.first_name + ' ' + user.last_name : user.invited_by}</span>

              <span className="admin">Your Administrator</span>
            </div>
          </div>
          <Button className="request-btn fon-gom00">
            Requested &nbsp; <img src={thunder} alt="i"></img>
          </Button>
        </div>


        <Button className="right-btn1" onClick={() => navigation.navigate("Dashboard")}>
          Dashboard &nbsp;
          <img src={rightArrowIcon} alt="i" />
        </Button>
      </div>
    </div >
  );
}
