import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const DeclineRequestModal = ({
  state,
  handleDeclineModal,
  handleDeclineReq,
}: any) => {
  const Schema = Yup.object().shape({
    reason: Yup.string().required("Field is Required."),
  });

  return (
    <Dialog
      className="dialog-container-1"
      onClose={handleDeclineModal}
      aria-labelledby="customized-dialog-title"
      open={state.desclineModalOpen}
    >
      <DialogTitle id="customized-dialog-title">
        <div className="header-main header-main-decline">
          <Typography
            className="main-text title-medium modal-title-bold"
            gutterBottom
            style={{padding:0}}
          >
            Decline a request
          </Typography>
          <IconButton onClick={handleDeclineModal} style={{padding:"20px !important"}}>
            <CancelIcon />
          </IconButton>
        </div>
       
        <Typography className="desc" style={{ width: "425px" }}>
          You’re about to decline a request, Please tell the teacher the
          reasoning behind
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <form noValidate autoComplete="off">
            <Formik
              initialValues={{
                reason: "",
              }}
              onSubmit={(values) => {
                handleDeclineReq(values);
              }}
              validationSchema={Schema}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form>
                    <InputLabel
                      htmlFor="my-input"
                      style={{fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace', marginTop: "2%" }}
                      className="pop-up-input-text"
                    >
                      Reason for a decline
                    </InputLabel>
                    <TextField
                      className="input-label"
                      style={{fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace', marginTop: "1%" }}
                      onChange={handleChange("reason")}
                      data-test-id={"txtInputProductId"}
                      value={values.reason}
                      multiline={true}
                      minRows={5}
                      name="reason"
                      placeholder="Your reason here"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="reclaimCredit" />}
                      error={!!errors.reason && !!touched.reason}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ outline: "none" }}
                          >
                            {!!errors.reason && !!touched.reason ? (
                              <ErrorOutlineIcon style={{ color: "#D92D20" }} />
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <DialogActions className="dialog-actions-container">
                      <Button
                        autoFocus
                        onClick={handleDeclineModal}
                        className="cancle-btn"
                        style={{ fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace', color: "#475467" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => handleSubmit()}
                        className="add-btn-1"
                        style={{fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace', backgroundColor: "#B42318" }}
                      >
                        Decline
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeclineRequestModal;
