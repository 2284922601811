import { createSlice } from "@reduxjs/toolkit";
// @ts-ignore
export const pendingRequestSlice = createSlice({
  name: "pendingRequestData",
  initialState: {
    value: 0,
  },
  reducers: {
    setPendingRequest: (state, action) => {
      state.value = action.payload;
    }
  },
});

export const { setPendingRequest } = pendingRequestSlice.actions;

export default pendingRequestSlice.reducer;
