import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Divider,
  DialogActions,
  Button,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";
import "../../assets/order-model.scss";
import { orangeWarn, warningIcon } from "../assets";
import { rightAeroWhite } from "../../../dashboard/src/assets";
import {
  getCheckoutCostData,
  getCommaSeperator,
  getCredits,
  getExtendedCost,
  getModalDescriptionForClassBoxesModal,
  totalCostxq,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";
export default function OrderModal({
  handleClose,
  handleModalTabChange,
  state,
  handleReturnClassBox,
  handleKeepClassBox,
  handleExtendClassBox,
  requestCreditsForTraining,
}: any) {
  let { dicountPrice, actualPrice, saveCost, CreditsToOwnWithCheckoutCost } =
    getCheckoutCostData(
      state.selectedCheckoutCard.checkout_cost,
      state.selectedCheckoutCard.checkout_month,
      state.selectedCheckoutCard.purchase_cost,
      state.selectedCheckoutCard.quantity
    );
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      className="order-modal"
      open={state.orderModalOpen}
    >
      {state.errorMessage && (
        <div className="header-order-modal">
          <img src={orangeWarn} alt="icon" className="mb-1" />
          &nbsp;&nbsp;
          <span className="header-txt">
            You don’t have enough credits, please submit for approval by your
            school’s administrator
          </span>
        </div>
      )}
      <DialogTitle className="dialog-title-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span className="order-modal-title">Manage Classbox</span>
          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="order-modal-content">
          <Grid item container sm={12} className="tabs" spacing={2}>
            <Grid item sm={4}>
              <div
                id="keepTab"
                onClick={() => handleModalTabChange("keep")}
                className={
                  state.modalTab === "keep"
                    ? "activebtn pointer"
                    : "nonactivebtn pointer"
                }
              >
                <span>🤲🏻&nbsp;Keep</span>
              </div>
            </Grid>
            <Grid item sm={4}>
              <div
                id="ReturnTab"
                onClick={() => handleModalTabChange("Return")}
                className={
                  state.modalTab === "Return"
                    ? "activebtn pointer"
                    : "nonactivebtn pointer"
                }
              >
                <span>📦&nbsp;Return</span>
              </div>
            </Grid>
            <Grid item sm={4}>
              <div
                id="checkoutTab"
                onClick={() => handleModalTabChange("checkout")}
                className={
                  state.modalTab === "checkout"
                    ? "activebtn pointer"
                    : "nonactivebtn pointer"
                }
              >
                <span>⌛&nbsp;Extend Checkout</span>
              </div>
            </Grid>
          </Grid>
          <div className="description-txt">
            <span>
              {getModalDescriptionForClassBoxesModal(
                state.modalTab,
                state.selectedCheckoutCard.checkout_cost,
                dicountPrice
              )}
            </span>
          </div>
          <Grid container className="box-content" wrap="wrap">
            <Grid sm={5} item className="blue-box">
              <img
                src={state.selectedCheckoutCard.hero_emoji?.url}
                alt="icon"
                style={{ width: "30%" }}
              />
              <span className="txt-des">
                {state.selectedCheckoutCard.title}
              </span>
            </Grid>
            <Grid sm={7} item container spacing={5} className="right-box">
              <Grid sm={12} container className="right-container" spacing={2}>
                {state.selectedCheckoutCard?.classbox_parts?.data?.map(
                  (item: any) => (
                    <>
                      <Grid sm={10} item className="txt-fonts">
                        {item?.attributes?.name}
                      </Grid>
                      <Grid sm={2} item className="right-align txt-fonts">
                        {item?.attributes?.quantity}
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          {state.modalTab === "keep" && (
            <Grid
              sm={12}
              item
              container
              spacing={5}
              className="price-container"
            >
              <Grid sm={12} container className="right-container" spacing={2}>
                <Grid sm={5} item className="txt-fonts">
                  Checkout Cost:
                </Grid>
                <Grid sm={7} item className="right-align">
                  <span className="txt1">{getCommaSeperator(actualPrice)}</span>
                  &nbsp;
                  <span className="txt2">{getCredits(actualPrice)}</span>&nbsp;
                </Grid>
              </Grid>
              <Grid sm={12} container className="right-container" spacing={2}>
                <Grid sm={5} item className="txt-fonts">
                  Total Classbox Cost:
                </Grid>
                <Grid sm={7} item className="right-align">
                  <span className="txt0">{getCommaSeperator(actualPrice)}</span>&nbsp;
                  <span className="txt1">{getCommaSeperator(dicountPrice)}</span>&nbsp;
                  <span className="txt2">{getCredits(dicountPrice)}</span>
                </Grid>
              </Grid>
              <Divider className="divider0" />
              <Grid sm={12} container className="right-container" spacing={2}>
                <Grid sm={5} item className="txt-fonts">
                  Own It Now Cost:
                </Grid>
                <Grid sm={7} item className="right-align">
                  <span className="txt3">{getCommaSeperator(CreditsToOwnWithCheckoutCost)}</span>
                  &nbsp;
                  <span className="txt4">{getCredits(CreditsToOwnWithCheckoutCost)}</span>&nbsp;
                  <div className="text-tag">Save {saveCost}</div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {state.modalTab === "checkout" && (
            <Grid sm={12} className="checkout-one-month-tab">
              <span className="title001">Credits Cost:</span>
              <span className="title002">
                {getCommaSeperator(getExtendedCost(state))}{" "}
                <span className="title003">{getCredits(totalCostxq(state))}</span>
              </span>
            </Grid>
          )}
        </div>
      </DialogContent>
      <DialogActions
        className={
          state.modalTab === "Return"
            ? "order-btn-container pad-top-150"
            : "order-btn-container"
        }
      >
        <Button className="left-btn-order" onClick={handleClose}>
          Cancel
        </Button>
        {state.modalTab === "keep" && !state.errorMessage && (
          <Button
            className="right-btn-order"
            onClick={() => {
              handleKeepClassBox(
                state.selectedCheckoutCard.order_id,
                CreditsToOwnWithCheckoutCost
              );
            }}
          >
            Confirm Order &nbsp;&nbsp;
            <img src={rightAeroWhite} />
          </Button>
        )}
        {state.modalTab === "Return" && (
          <Button
            className="right-btn-order"
            onClick={() =>
              handleReturnClassBox(state.selectedCheckoutCard.order_id)
            }
          >
            Return Classbox &nbsp;&nbsp;
            <img src={rightAeroWhite} />
          </Button>
        )}
        {state.modalTab === "checkout" && !state.errorMessage && (
          <Button
            className="right-btn-order"
            onClick={() =>
              handleExtendClassBox(
                state.selectedCheckoutCard.order_id,
                state.selectedCheckoutCard.checkout_cost,
                state.selectedCheckoutCard.end_date,
                state.selectedCheckoutCard.start_date || "",
                state.selectedCheckoutCard.quantity
              )
            }
          >
            1 Month Extension &nbsp;&nbsp;
            <img src={rightAeroWhite} />
          </Button>
        )}
        {state.modalTab !== "Return" && state.errorMessage && (
          <Button
            className="right-btn-order"
            id="click-right-010"
            onClick={() =>
              requestCreditsForTraining(
                state.modalTab === "keep" ? "keep_requested" : "extended",
                +state.selectedCheckoutCard.id,
                CreditsToOwnWithCheckoutCost
              )
            }
          >
            Submit For Approval &nbsp;&nbsp;
            <img src={rightAeroWhite} />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
