export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const img14441703d5a937cc65a62353cbea2a59834b3da3 = require("../../global_assets/14441703d5a937cc65a62353cbea2a59834b3da3.png");
export const img734bc1ec08e7f8f448efe762ab29ec51881e2bb8 = require("../../global_assets/734bc1ec08e7f8f448efe762ab29ec51881e2bb8.png");
export const img7afca663860b36355db50e67ee3ad26e11cb2bfc = require("../../global_assets/7afca663860b36355db50e67ee3ad26e11cb2bfc.png");
export const imga6752e837ca0b00c8a7f4872aed8aab91e8282d0 = require("../../global_assets/a6752e837ca0b00c8a7f4872aed8aab91e8282d0.png");
export const imgb0ec28dfc4fdff0a7aa0ceadbbd2a62a2e22288e = require("../assets/b0ec28dfc4fdff0a7aa0ceadbbd2a62a2e22288e.png");
export const img306f027e9e170f2dd21eb1d305705774bafe39c3 = require("../assets/306f027e9e170f2dd21eb1d305705774bafe39c3.png");
export const img64eeb30681b756a30c83b1b5e5314819682b15ac = require("../assets/64eeb30681b756a30c83b1b5e5314819682b15ac.png");
export const img2080bf546b141a859ae7748888ca1016b0030a2b = require("../assets/2080bf546b141a859ae7748888ca1016b0030a2b.png");
export const img6a98ae706d385d0b690d79fa9a90119bce6577f5 = require("../assets/6a98ae706d385d0b690d79fa9a90119bce6577f5.png");
