// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectTitle:string;
  studentViewURL:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CompleteProjectPageSubController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      projectTitle : "",
      studentViewURL:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    let getDataID = await getStorageData("projectTitle")
    let studentURL = await getStorageData("studentUrl")
    this.setState({projectTitle: getDataID})
    this.setState({studentViewURL: studentURL})
    // Customizable Area End
  }
}
// Customizable Area End
