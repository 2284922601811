import React from "react";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { setStorageData } from "../../../../framework/src/Utilities";





 interface JsonResponse {

  current_project_step_data: {
    status: string;
    data: {
      attributes: {
        step_name: string
        }
     }
     project_chapter_id: string | number;
   }
   project: {
     project_emoji: string;
     project_id: number | string;
     project_status_calculation: number;
     project_chapters: {
       project_chapter_id: string | number;
       title: string;
       status: string;
     }[];

    account_project: string
  };

}
/* istanbul ignore next */
const ProjectDetailsHeaderSub = ({ state, getStepsDataWithTraining, navigatFinshStep }: any) => {
  const currentData = state?.projectStepData?.project_chapter_data?.data
  const fullArr = state.projectDetails?.project?.project_chapters
  const currentIndex = fullArr?.findIndex((item: any) => item?.project_chapter_id == currentData?.id)
  const nextIndexId = fullArr?.[currentIndex + 1]?.project_chapter_id
  const buttonText = fullArr?.length === currentIndex + 1 ? "Finish" : "Next Step"
  return (
    <Grid
      className="header"
      item sm={12}>
      <span
        className="titles"
      >
        {state?.projectStepData?.title}
      </span>
      {state.projectStepData && (
        <>
          {
            state?.current_project_step_data?.status !==
              "not_started"
              ? (
                <Button
                  className="finish-btn"
                  endIcon={<ArrowForwardIcon />}
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    if (fullArr.length !== currentIndex + 1) {
                      getStepsDataWithTraining(nextIndexId)
                    } else {
                      navigatFinshStep()
                      setStorageData("projectTitle", state.projectDetails?.project?.title);
                      setStorageData("studentUrl", state.projectDetails.project.overview.data.attributes.student_view)
                    }
                  }
                  }
                >
                  {buttonText}
                </Button>
              )
              : (
                <Button
                  className="finish-btn"
                  endIcon={<ArrowForwardIcon />}
                  style={{ marginRight: "20px" }}
                >
                  {buttonText}
                </Button>
              )}
        </>
      )}
    </Grid>
  );
};

export default ProjectDetailsHeaderSub;
