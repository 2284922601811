// Customizable Area Start
import React from "react";
import { Grid, Button } from '@material-ui/core';
export const configJSON = require("./config");
import InviteUserController, {
    Props,
} from "./InviteUserController.web";
import { header_logo, invite_icon } from "./assets";
import "../assets/inviteuser.scss";
import InviteUserModal from "./components/InviteUserModal";

export default class InviteUser extends InviteUserController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Grid container className="inviteuser-container">
                <Grid item sm={12} className="page-header">
                    <img src={header_logo} alt="logo" className="logo" />
                </Grid>
                <Grid container item sm={12} className="page-body">
                    <span className="title">
                        Invite User
                    </span>
                    <Button variant="outlined" className="invite-user-btn" onClick={this.openModal}>
                        <img src={invite_icon} alt="invite-user" />Invite
                    </Button>
                </Grid>
                <InviteUserModal open={this.state.open} handleModal={this.handleModal} onOk={this.onOk} onCancle={this.onCancle} />
            </Grid>
        );
    }
}
// Customizable Area End
