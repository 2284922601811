import React from "react";
import { Grid } from "@material-ui/core";
import LineChartComponent from "./LineChartComponent";
import ListTransaction from "./ListTransaction";

export default function LeadDrawerComponent({ state, fetchMoreData }: any) {
  return (
    <>
      <Grid sm={12} className="chart-title-container">
        <span className="chart-title">Balance History</span>
      </Grid>
      <Grid sm={12} className="chart-container">
        <LineChartComponent state={state} />
      </Grid>
      <Grid sm={12} className="chart-title-container-1">
        <span className="chart-title">Last Activity</span>
      </Grid>
      <Grid sm={12} style={{ paddingBottom: "25px" }}>
        {state?.schoolCreditHistory.length > 0 && (
          <ListTransaction state={state} fetchMoreData={fetchMoreData} />
        )}
      </Grid>
    </>
  );
}
