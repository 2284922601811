import React, { useRef } from "react";
import { Grid, TextField } from "@material-ui/core";
import { duration, durationCheck, gradesCheck, grades, subject, subjectCheck, classBox, classBoxCheck, standard, standardCheck } from "../assets"
import CheckBoxComponent from "../components/ChexkBox";

import StandardDropDown from "./StandardDropDown"
import useOutsideClick from "../../../../components/src/NativeWebRouteWrapper/useOutsideClick";
const PopoverComponent = ({
    storeIdsOfCheckedStandard,
    selectAllStandard,
    setStandardFilter,
    state,
    handleCloseModal,
    handletab0Checkbox,
    handleChangeTab,
    handleCompareChange,
    handleChangeV1,
    handleChangeV2,
    handleGrades,
    handleSelectSubject
}: any) => {
    const refComponent = useRef<HTMLDivElement>(null);
    useOutsideClick(refComponent, handleCloseModal);
    let getClassName = (tab: string) => state.tab === tab ? "active-tab" : "tab";
    let imageOne = () => state.tab === "tab1" ? durationCheck : duration;
    let imageTwo = () => state.tab === "tab2" ? gradesCheck : grades;
    let imageThree = () => state.tab === "tab4" ? subjectCheck : subject;
    let imageFour = () => state.tab === "tab0" ? classBoxCheck : classBox;
    let imageFive = () => state.tab === "tab3" ? standardCheck : standard
    return (
        <div className="popover-filters" ref={refComponent || ""}>
            <div className="tabs">
                <div
                    id="tab1btn"
                    className={getClassName("tab1")}
                    onClick={() => handleChangeTab("tab1")}
                >
                    <img
                        src={imageOne()}
                        alt="icon"
                    />
                    &nbsp;&nbsp;<span className="text">Duration</span>
                </div>
                <div
                    id="tab2btn"
                    className={getClassName("tab2")}
                    onClick={() => handleChangeTab("tab2")}
                >
                    <img
                        src={imageTwo()}
                        alt="icon"
                    />
                    &nbsp;&nbsp;<span className="text">Grades</span>
                </div>
                <div
                    id="tab4btn"
                    className={getClassName("tab4")}
                    onClick={() => handleChangeTab("tab4")}
                >
                    <img
                        src={imageThree()}
                        alt="icon"
                    />
                    &nbsp;&nbsp;<span className="text">Subjects</span>
                </div>
                <div
                    id="tab3btn"
                    className={getClassName("tab0")}
                    onClick={() => handleChangeTab("tab0")}
                >
                    <img
                        src={imageFour()}
                        alt="icon"
                    />
                    &nbsp;&nbsp;<span className="text">Classbox</span>
                </div>
                <div
                    id="tab5btn"
                    className={getClassName("tab3")}
                    onClick={() => handleChangeTab("tab3")}
                >
                    <img
                        src={imageFive()}
                        alt="icon"
                    />
                    &nbsp;&nbsp;<span className="text">Standards</span>
                </div>
            </div>
            <div className="content" id="content-for-select">
                {state.tab === "tab0" && (
                    <Grid item container className="tab0-content" spacing={2}>
                        <Grid item sm={12}>
                            <CheckBoxComponent
                                handleChange={(event: any) => handletab0Checkbox(event)}
                                checked={state.tab0Checkboxes.classBoxNeeded}
                                label="Classbox Needed"
                                name="classBoxNeeded"
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <CheckBoxComponent
                                handleChange={(event: any) => handletab0Checkbox(event)}
                                checked={state.tab0Checkboxes.noClassBoxNeeded}
                                label="No Classbox Needed"
                                name="noClassBoxNeeded"
                            />
                        </Grid>
                    </Grid>
                )}
                {state.tab === "tab1" && (
                    <Grid className="tab1-content" container>
                        <Grid item container className="compare" sm={12} spacing={2}>
                            <Grid item sm={2}>
                                <div
                                    id="checkout-btn3"
                                    onClick={() => handleCompareChange("is")}
                                    className={
                                        state.duration.compare === "is"
                                            ? "activebtn"
                                            : "nonactivebtn"
                                    }
                                >
                                    <span>Is</span>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div
                                    id="compare0"
                                    onClick={() => handleCompareChange("between")}
                                    className={
                                        state.duration.compare === "between"
                                            ? "activebtn"
                                            : "nonactivebtn"
                                    }
                                >
                                    <span>Is between</span>
                                </div>
                            </Grid>
                            <Grid item sm={3}>
                                <div
                                    id="compare1"
                                    onClick={() => handleCompareChange("morethan")}
                                    className={
                                        state.duration.compare === "morethan"
                                            ? "activebtn"
                                            : "nonactivebtn"
                                    }
                                >
                                    <span>More than</span>
                                </div>
                            </Grid>
                            <Grid item sm={3}>
                                <div
                                    id="compare2"
                                    onClick={() => handleCompareChange("lessthan")}
                                    className={
                                        state.duration.compare === "lessthan"
                                            ? "activebtn"
                                            : "nonactivebtn"
                                    }
                                >
                                    <span>Less than</span>
                                </div>
                            </Grid>
                        </Grid>

                        {state.duration.compare !== "" && (
                            <Grid item container sm={12} className="fields" spacing={2}>
                                <Grid
                                    item
                                    sm={state.duration.compare === "between" ? 6 : 12}
                                >
                                    <TextField
                                        className="input-field"
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        placeholder={state.duration.compare === "between" ? "Min Minutes" : "Minutes"}
                                        onChange={handleChangeV1}
                                        value={state.duration.value1}
                                        fullWidth
                                        name="value1"
                                    />
                                </Grid>
                                {state.duration.compare === "between" && (
                                    <Grid item sm={6}>
                                        <TextField
                                            className="input-field"
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            placeholder="Max Minutes"
                                            onChange={handleChangeV2}
                                            value={state.duration.value2}
                                            name="value2"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
                {state.tab === "tab2" && (
                    <Grid item container className="tab2-content" spacing={2}>
                        {["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"].map((ele: string, index: number) => {
                            return <Grid item sm={4} key={ele}>
                                <CheckBoxComponent
                                    handleChange={(event: any) => handleGrades(event, ele)}
                                    checked={state.selectedGrade.includes(ele)}
                                    label={index + 1}
                                    name={ele}
                                />
                            </Grid>
                        })}

                    </Grid>
                )}
                {state.tab === "tab4" && <Grid item container className="tab2-content" spacing={2}>
                    {state?.subjectsValue?.map((item: string) => (
                        <Grid className={state.selectedSubjects.includes(item) ? "subject-checked" : "subject"} key={item} onClick={() => handleSelectSubject(item)} >
                            {/* <img src={duration}></img> */}
                            <span className="subject-text">{item}</span>
                        </Grid>
                    ))}
                </Grid>}
                {state.tab === "tab3" && (
                    <Grid item container className="tab0-content-standard" spacing={2}>
                        {state.standardDataList.map((items: any, index: any) => {
                            return <>
                                {
                                    items.attributes.standard_data.length > 0 && <Grid  item sm={12} className={items.id == state.standardIdOfStandard ? "standard-grid-checked standard-grid" : "standard-grid"}>
                                        <div className="title-div">
                                            <img src={items.attributes.logo.url} className="stadard-img"></img>
                                            <span className="standard-title">{items.attributes.title}</span>
                                        </div>
                                        {/* @ts-ignore */}
                                        <StandardDropDown storeIdsOfCheckedStandard={storeIdsOfCheckedStandard} selectAllStandard={selectAllStandard} setStandardFilter={setStandardFilter} standardData={items.attributes.standard_data.map((item: any) => ({ ...item, stdId: items.id, titleX: items.attributes.title }))} state={state} />
                                    </Grid>
                                }
                            </>
                        }

                        )}

                    </Grid>
                )}
            </div>
        </div>
    );
};

export default PopoverComponent;
