Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validateApiMethodDelete = "DELETE"
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions";
exports.labelBodyText = "RolesPermissions Body";
exports.addUserEndPoint = "account_block/accounts"
exports.btnExampleTitle = "CLICK ME";
exports.httpPostMethod = "POST"
exports.getAllUserEndPoint = "account_block/accounts/show_invitation_mails"
exports.deleteUserEndPoint = "account_block/accounts"
exports.sendInvites = "account_block/accounts/send_invitation"
// Customizable Area End