import React,{Component } from "react";
import {OutlinedInput} from "@material-ui/core";

export interface MaskedProps {
    value: string;
    isPassword: boolean;
    name: string;
  }
class MaskedInput extends Component<MaskedProps> {
    constructor(props:MaskedProps) {
      super(props);
    }  
    maskValue = (value:string) => {
      const maskedPart = this.props.name === "accountNumber"? "******":"*****";
      const visiblePart = value.slice(-4);
      return maskedPart + visiblePart;
    };
  
    render() {
      const { value , isPassword } = this.props;
      const displayValue = isPassword ? this.maskValue(value) : value;
  
      return (
        <OutlinedInput
          type="text"
          value={displayValue}
          style={{ width: '530px' }}
        />
      );
    }
  }

  export default MaskedInput;