import { createSlice } from "@reduxjs/toolkit";
// @ts-ignore
export const classboxesSlice = createSlice({
  name: "counter",
  initialState: {
    currentClassboxData: null,
    selectedOptions: []
  },
  reducers: {
    setCurrentClassbox: (state, action) => {
      state.currentClassboxData = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOptions = action.payload
    }
  },
});

export const { setCurrentClassbox, setSelectedOption } = classboxesSlice.actions;

export default classboxesSlice.reducer;
