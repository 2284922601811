// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { driftInitializer, handleTokenError, scrollToTop } from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  data: null | string;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  blogs: any[];
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class SupportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  submitApiCallId: any;
  getBlogsApiId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      data: null,
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      blogs: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    scrollToTop();
    this.getBlogsData();
    driftInitializer()
    //@ts-ignore
    window.drift.load("afrs4mhmt3ki"); // Your embed ID found within your account goes here

    // Customizable Area End
  }

  async componentWillUnmount() {
    // Customizable Area Start
    //@ts-ignore
    window.drift.hide();

    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      this.setState({
        loading: false,
      });
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getBlogsApiId) {
          let x = [];
          for (let blog in responseJson) {
            x.push({ title: blog, blogs: responseJson[blog] });
          }
          console.log("console.log", x)
          this.setState({
            blogs: x,
          });
        }
      }
      // Customizable Area End
    }
  }
  getBlogsData = () => {
    const header = {
      "Content-Type": configJSON.getUserDetailsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlogsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBlogsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBlogsAPIMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  setData = (data: any) => {
    this.setState({
      data: data,
    });
    return true;
  };
}
// Customizable Area End

// Customizable Area End
