import * as React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const SwitchLoader = ({ loader }: any) => {
  return (
    <div
      style={{
        background: "#D2EFFF",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Backdrop
        style={{
          color: "#fff",
          zIndex: 1000000000,
          background: "#D2EFFF",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={loader.open}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#004b77",marginBottom:"12%" }} />
          <span className="loader-title">Switching to</span>
          <br />
          <span className="loader-title">
            {loader.mode === "teacher" ? "Teaching mode" : "Lead mode"}
          </span>
        </div>
      </Backdrop>
    </div>
  );
};

export default SwitchLoader;
