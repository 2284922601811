import React from 'react'
import { useDispatch } from "react-redux";
import { setSelectedOption } from './classboxesSlice';

export const StandardEmpty = () => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(setSelectedOption([]))
    }, [])

    return (
        <></>
    )
}
