import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  TermsCheckBox: boolean;
  forgotPasswordDialog: boolean;
  openMailDialog: boolean;
  loading: boolean;
  errorText: boolean;
  showSnackbar: boolean;
  otpAuthToken: string;
  message: string;
  initialValues: {
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    ageCheckbox: string;
    termsCheckbox: string;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start

  // Customizable Area End

export default class VendorAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS


> {
  // Customizable Area Start
  getProductsApiCallId = "";
  apiEmailLoginCallId: any;
  validationApiCallId: any;
  forgotPasswordMailApiCallId: any;
  labelTitle: string = "";
  apiEmailSignUpCallId: any = "";
  userAttr: any;
  userProfileGetApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleGetUserData();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      TermsCheckBox: false,
      errorText: false,
      forgotPasswordDialog: false,
      openMailDialog: false,
      showSnackbar: false,
      message: "",
      loading: false,
      otpAuthToken: "",
      initialValues: {
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        ageCheckbox: "",
        termsCheckbox: "",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.errors) {
        const errors = responseJson.errors;
        this.setState({
          showSnackbar: true,
          message: errors[0].account || errors[0] || "Something Went Wrong!",
        });
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.apiEmailSignUpCallId
      ) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...responseJson.account.data.attributes,
            id: responseJson.account?.data.id,
            videoUrl:
              responseJson.site_setting?.data[0]?.attributes?.video_link,
          })
        );
        localStorage.setItem('authToken', responseJson.account?.meta.token);
        if (responseJson.account.data.attributes.role.id === 3) {
          this.props.navigation.navigate("LeadRoleScreen");
        } else {
          this.props.navigation.navigate("Login");
        }
      } else if (apiRequestCallId === this.userProfileGetApiCallId) {

        if (
          responseJson
        ) {

          this.userAttr = responseJson.data.attributes;

          if (this.userAttr !== null && this.userAttr !== undefined) {
            if (this.userAttr.activated === true) {
              this.props.navigation.navigate("/Login");
            }
            let email = this.userAttr.email;
            let firstName = this.userAttr.first_name;
            let lastName = this.userAttr.last_name;
            let companyName = this.userAttr.vendor_company.data.attributes.company_name;
            this.setState({
              initialValues: {
                email: email,
                firstName: firstName,
                lastName: lastName,
                companyName: companyName,
                password: "",
                confirmPassword: "",
                ageCheckbox: "",
                termsCheckbox: "",
              },
            });
          }
        }
      }
    }

    // Customizable Area End
  }

 
  // Customizable Area Start
  handalCheck = ()=> {
    this.setState({TermsCheckBox : !this.state.TermsCheckBox});
  };

  handleUpdateVendorSignUp = async (data: any) => {
    // @ts-ignore
    let params = new URL(document.location).searchParams;
    let userid = params.get("id");

    if (userid?.includes("role_id")) {
      userid = userid?.split("?")[0];
    }

    const header = {
      "Content-Type": configJSON.signUpApiContentType
    };

    let datas = {
      term_and_condition:true,
      age_limit:14,
      data: {
        type: "email_account",
        attributes: {
          //@ts-ignore
          id: +userid,
          first_name: data.firstName,
          last_name: data.lastName,
          password: data.password,
          password_confirmation: data.confirmPassword,
          activated: true,
        },
      },
    };
	
    const httpBody = {
      ...datas,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signUpApiEndPoint}?id=${userid}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
   handleGetUserData = async () => {
    // @ts-ignore
    let params = new URL(document.location).searchParams;
    let userid = params.get("id");
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetUserProfile}${userid}`
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  showError = (state: any, errors: any, touched: any) => {
    return state.errorText || (!!errors.password && !!touched.password);
  };
  handleGoogleSignUpApi = (responseJson?: any) => {
    // @ts-ignore
    let params = new URL(document.location).searchParams;
    let userid = params.get("id");

    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    let datas = {
      term_and_condition: true,
      age_limit: 14,
      activated: true,
      data: {
        type: "social_account",
        attributes: {
          id: userid,
          unique_auth_id: responseJson.credential,
        }
      }
    }

    const httpBody = {
      ...datas,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
