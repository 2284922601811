// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getAuthToken, handleTokenError } from "../../../components/src/NativeWebRouteWrapper/Utils";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  filterSelected: boolean;
  tab1show: any[];
  tab2show: string;
  openFilter: boolean;
  getClasssboxFiltersURL: string;
  duration: {
    compare: "is" | "between" | "morethan" | "lessthan" | "";
    value1: any;
    value2: any;
  };
  tab0Checkboxes: {
    classBoxNeeded: boolean;
    noClassBoxNeeded: boolean;
  };
  tab1Checkboxes: {
    makerspaceEquipment: boolean;
    STEMTechnology: boolean;
    reusableMaterials: boolean;
    consumableMaterials: boolean;
  };
  selectedItem: string;
  setCurrentprojectData: any;
  showLeftDiv: any;
  setSlectedCard: any;
  tab: string;
  selectedGrade: string[];
  gradeForShow: string;
  subjectsValue: string[];
  selectedSubjects: string[];
  standardDataList: any[];
  selectedOptions: any[];
  showStandard: any[];
  standardIDs: any[];
  empty: boolean;
  standardIdOfStandard: any
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class ProjectController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getListProjectDetails: Message | string = "";
  getClassBoxesApiId: any;
  plusPlan: any;
  getStandardListApiId: any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      tab: "tab1",
      showSnackbar: false,
      severity: undefined,
      getClasssboxFiltersURL: "",
      message: "",
      loading: false,
      filterSelected: false,
      tab1show: [],
      tab2show: "",
      openFilter: false,
      tab0Checkboxes: {
        classBoxNeeded: false,
        noClassBoxNeeded: false,
      },
      tab1Checkboxes: {
        makerspaceEquipment: false,
        STEMTechnology: false,
        reusableMaterials: false,
        consumableMaterials: false,
      },
      selectedItem: "",
      setCurrentprojectData: {},
      showLeftDiv: false,
      setSlectedCard: null,
      duration: {
        compare: "",
        value1: "",
        value2: "",
      },
      selectedGrade: [],
      gradeForShow: "",
      subjectsValue: [],
      selectedSubjects: [],
      standardDataList: [],
      selectedOptions: [],
      showStandard: [],
      standardIDs: [],
      empty: false,
      standardIdOfStandard: ""

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getProjectDetails();
    this.getSTandardList()
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (
      prevState.tab0Checkboxes.classBoxNeeded !==
      this.state.tab0Checkboxes.classBoxNeeded ||
      prevState.tab0Checkboxes.noClassBoxNeeded !==
      this.state.tab0Checkboxes.noClassBoxNeeded ||
      prevState.duration.compare !== this.state.duration.compare ||
      prevState.duration.value1 !== this.state.duration.value1 ||
      prevState.duration.value2 !== this.state.duration.value2 ||
      prevState.selectedGrade !== this.state.selectedGrade ||
      prevState.selectedSubjects !== this.state.selectedSubjects ||
      prevState.standardIDs !== this.state.standardIDs
    ) {
      let y: any[] = [];
      if (
        this.state.duration.compare === "between" &&
        this.state.duration.value1 !== "" &&
        this.state.duration.value2 !== ""
      ) {
        this.setState({
          tab2show: `Between ${this.state.duration.value1} and ${this.state.duration.value2}`,
        });
      } else if (
        this.state.duration.compare !== "between" &&
        this.state.duration.value1 !== ""
      ) {
        switch (this.state.duration.compare) {
          case "is":
            this.setState({
              tab2show: `${this.state.duration.value1}`,
            });
            break;
          case "lessthan":
            this.setState({
              tab2show: `Less than ${this.state.duration.value1}`,
            });
            break;
          case "morethan":
            this.setState({
              tab2show: `More than ${this.state.duration.value1}`,
            });
            break;
          default:
            break;
        }
      } else {
        this.setState({
          tab2show: "",
        });
      }

      this.setState(
        {
          filterSelected: this.isFilterApplied(),
          tab1show: y,
          gradeForShow: this.state.selectedGrade
            .map((item: string) => item.split(" ")[1])
            .join(", "),
        },
        () => this.appendFilters()
      );
    }
    if (
      prevState.getClasssboxFiltersURL !== this.state.getClasssboxFiltersURL
    ) {
      this.getProjectDetails();
    }
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ loading: false });
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getListProjectDetails) {
          let itemArray: string[] = [];
          responseJson?.data?.map((item: any) => {
            itemArray = [...itemArray, ...item.attributes.subject_tags];
            return item;
          });
          this.setState({
            setCurrentprojectData: responseJson,
            subjectsValue: itemArray.filter(
              (item: string, index: number) => itemArray.indexOf(item) === index
            ),
          });
        } else if (apiRequestCallId === this.getStandardListApiId) {
          this.setState({ standardDataList: responseJson.data })
        }else if(apiRequestCallId === this.plusPlan){
          this.props.navigation.navigate("Dashboard")
        }
      }
      // Customizable Area End
    }
  }

  setFilterOpen = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
    return true;
  };

  handleCloseModal = () => {
    this.setState({
      openFilter: false,
    });
    return true;
  };
  handletab1Checkbox = (event: any) => {
    this.setState({
      tab1Checkboxes: {
        ...this.state.tab1Checkboxes,
        [event.target.name]: event.target.checked,
      },
    });
    return true;
  };
  handletab0Checkbox = (event: any) => {
    this.setState({
      tab0Checkboxes: {
        classBoxNeeded: false,
        noClassBoxNeeded: false,
        [event.target.name]: event.target.checked,
      },
    });
    return true;
  };
  handleChangeTab = (value: any) => {
    this.setState({
      // @ts-ignore
      tab: value,
    });
    return true;
  };
  handleGrades = (event: any, value: any) => {
    if (this.state.selectedGrade.includes(value)) {
      this.setState({
        selectedGrade: this.state.selectedGrade.filter(
          (item: string) => item !== value
        ),
      });
      return;
    }
    this.setState({ selectedGrade: [...this.state.selectedGrade, value] });
  };

  addProjectPlan = () => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.plusPlan = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plusPlanEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        project_id: this.state.setSlectedCard?.attributes?.id
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getProjectDetails = () => {
    this.setState({ loading: true });
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListProjectDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.appendFilters()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleLeftCard = (element: any) => {
    this.setState({
      showLeftDiv: !this.state.showLeftDiv,
      setSlectedCard: element,
    });
  };
  getCompareValue = (value: string) => {
    switch (value) {
      case "is":
        return "is_equal";
      case "between":
        return "in_between";
      case "morethan":
        return "more_then";
      case "lessthan":
        return "less_then";
      default:
        return "";
    }
  };
  appendFilters = () => {
    let url = `${configJSON.getListProject}?=`;

    if (this.state.tab0Checkboxes.noClassBoxNeeded) {
      url += `&related_classbox=${!this.state.tab0Checkboxes.noClassBoxNeeded}`;
    }
    if (this.state.tab0Checkboxes.classBoxNeeded) {
      url += `&related_classbox=${this.state.tab0Checkboxes.classBoxNeeded}`;
    }
    if (
      this.state.duration.compare !== "" &&
      this.state.duration.value1 !== ""
    ) {
      url += `&duration_type=${this.getCompareValue(
        this.state.duration.compare
      )}`;
      if (this.state.duration.value1 !== "") {
        url += `&value1=${this.state.duration.value1}`;
      }
      if (this.state.duration.value2 !== "") {
        url += `&value2=${this.state.duration.value2}`;
      }
    }
    if (this.state.selectedGrade.length > 0) {
      url += `&grades=[${this.state.selectedGrade
        .map((item: string) => `"${item}"`)
        .join(", ")}]`;
    }
    if (this.state.selectedSubjects.length > 0) {
      url += `&subjects=[${this.state.selectedSubjects
        .map((item: string) => `"${item}"`)
        .join(", ")}]`;
    }
    if (this.state.standardIDs.length > 0) {
      url += `&standard_set_id=[${this.state.standardIDs.join(", ")}]`
    }
    this.setState({
      getClasssboxFiltersURL: url,
    });
    return url;
  };
  isFilterApplied = () => {
    return (
      this.state.duration.value1 !== "" ||
      this.state.duration.value2 !== "" ||
      this.state.tab0Checkboxes.classBoxNeeded ||
      this.state.tab0Checkboxes.noClassBoxNeeded ||
      this.state.selectedGrade.length > 0 ||
      this.state.selectedSubjects.length > 0 ||
      this.state.showStandard.length > 0
    );
  };
  handleChangeV1 = (e: any) => {
    (!!Number(e.target.value) || e.target.value === "") &&
      this.setState({
        duration: {
          ...this.state.duration,
          value1: e.target.value,
        },
      });
    return true;
  };
  handleChangeV2 = (e: any) => {
    (!!Number(e.target.value) || e.target.value === "") &&
      this.setState({
        duration: {
          ...this.state.duration,
          value2: e.target.value,
        },
      });
    return true;
  };
  handleCompareChange = (
    value: "is" | "between" | "morethan" | "lessthan" | ""
  ) => {
    if (this.state.duration.compare === value) {
      this.setState({
        duration: {
          ...this.state.duration,
          compare: "",
        },
      });
      return;
    }
    this.setState({
      duration: {
        ...this.state.duration,
        compare: value,
      },
    });
    return true;
  };
  handleSelectSubject = (value: string) => {
    if (this.state.selectedSubjects.includes(value)) {
      this.setState({
        selectedSubjects: this.state.selectedSubjects.filter(
          (item: string) => item !== value
        ),
      });
      return;
    }
    this.setState({
      selectedSubjects: [...this.state.selectedSubjects, value],
    });
  };
  resetFilterClassBoxes = () => {
    this.setState({
      tab0Checkboxes: {
        classBoxNeeded: false,
        noClassBoxNeeded: false,
      },
    });
  };
  resetFilterDuration = () => {
    this.setState({
      tab2show: "",
      duration: {
        compare: "",
        value1: "",
        value2: "",
      },
    });
  };
  resetFilterSubjects = () => {
    this.setState({
      selectedSubjects: [],
    });
  };
  resetGradeForShow = () => {
    this.setState({
      gradeForShow: "",
      selectedGrade: [],
    });
  };

  // onnClickSelectStandard = (item: any) => {
  //   this.setState({ selectStandard: item })
  // }

  getSTandardList = () => {
    this.setState({ loading: true });
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStandardListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStandardsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  setStandardFilter = (value: any, remove: boolean) => {

    if (remove) {
      this.setState({ standardIDs: this.state.standardIDs.filter((item) => item !== value.id), showStandard: this.state.showStandard.filter((text) => text !== value.title) })
    } else {
      this.setState({ standardIDs: [...this.state.standardIDs, value.id], showStandard: [...this.state.showStandard, value.title] })
    }
  }

  clearAllStandards = () => {
    this.setState({ standardIDs: [], showStandard: [], empty: true })
  }

  selectAllStandard = (ids: any, titles: any) => {
    if (ids.length == 0) {
      this.clearAllStandards()
      return
    }
    this.setState({ standardIDs: [...this.state.standardIDs, ...ids], showStandard: [...this.state.showStandard, ...titles] })

  }

  storeIdsOfCheckedStandard = (id: any) => {
    this.setState({ standardIdOfStandard: id })
  }
}
// Customizable Area End

// Customizable Area End
