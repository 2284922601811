import React from 'react'
import {
    DialogTitle,
    Dialog,
    DialogContent,
    Grid,
    Divider,
    DialogActions,
    Button,
    IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";

function DialougePopOver({ handleDialouge, state, deleteProject, item }: any) {
    return (
        <>
            <Dialog
                id='dialouge-content'
                className="remove-background-div"
                open={state?.dialougContent}
                onClose={handleDialouge}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className='heading-div-project-project'>
                    <span className='dialouge-header'>Remove a project</span>
                    <CancelIcon className='close-icon-teacher-project' onClick={handleDialouge} />
                </div>
                <DialogContent>
                    <span className='dialouge-text'>Are you sure you'd like to remove this project from your list? You can always add it back by selecting it from the Projects page</span>
                </DialogContent>
                <DialogActions className='dialouge-action-xzy'>
                    <Button className='cancel-btn' onClick={handleDialouge}>Cancel</Button>
                    <Button variant='contained' id="remove-btn1" className='remove-btn' onClick={() => deleteProject(item)}>
                        Yes, remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialougePopOver