import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getAuthToken,
  getUserDetails,
  handleTokenError,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { error } from "console";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  radioButtonSelected: string;
  // Customizable Area Start
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeadRoleScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateUSerDetailsApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      radioButtonSelected: "stem_teacher",
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    if (isTeacher()) {
      this.props.navigation.goBack()
    }
    // Customizable Area End
  }

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      this.setState({
        loading: false,
      });

      if (responseJson && responseJson.errors) {
        this.setState({
          showSnackbar: true,
          severity: "error",
          message: "Something Went wrong!",
        });
      }

      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.updateUSerDetailsApiId
      ) {
        let data = JSON.parse(localStorage.getItem("userInfo") || "");
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
            ...responseJson.data.attributes,
          })
        );
        this.props.navigation.navigate("InviteTeachers");
      }
    }

    // Customizable Area End
  }

  updateUserData = (data: any) => {
    let user = getUserDetails();
    let token = getAuthToken();
    const header = {
      token: token,
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUSerDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserDetailsAPiEndPoint}${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserDetailsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area Start
  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handleRoleSelect = () => {
    let formData = new FormData();
    formData.append("lead_type", this.state.radioButtonSelected);
    this.updateUserData(formData);
    return true;
  };
  handleCheckBoxChange = (title: string) => {
    this.setState({
      radioButtonSelected: title,
    });
    return true;
  };
  // Customizable Area End
}
