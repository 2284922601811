import React from "react";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Grid, Divider, AccordionDetails } from "@material-ui/core";
import "../../assets/accordion.scss";

const StandardsAccordian = ({ standard_sets }: any) => {
  return (
    <div className="accordian-container-2">
      <Grid className="accordian-container">
        {standard_sets.map((item: any, index: number) => {
          return (
            <Accordion
              key={item.id}
              className={index === 0 ? "remove-before" : ""}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span className="title-project-overview">
                  <img
                    src={item.attributes.logo.url}
                    className="profile-image"
                  />
                  {item.attributes.title}
                </span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details steps-container">
                {item.attributes.standards.map((item: any) => {
                  return (
                    <div className="details-card" key={item.id}>
                      <div className="left-content-container">
                        <span className="standard-title-txt">{item.title}</span>
                        <span className="standard-description-txt">
                          {item.description}
                        </span>
                      </div>
                      <a
                        className="pointer"
                        href={item.external_url}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.00001V3.00001M17.5 3.00001H12.5M17.5 3.00001L10 10.5M8.33333 3H6.5C5.09987 3 4.3998 3 3.86503 3.27248C3.39462 3.51217 3.01217 3.89462 2.77248 4.36503C2.5 4.8998 2.5 5.59987 2.5 7V14C2.5 15.4002 2.5 16.1002 2.77248 16.635C3.01217 17.1054 3.39462 17.4878 3.86503 17.7275C4.3998 18 5.09987 18 6.5 18H13.5C14.9002 18 15.6002 18 16.135 17.7275C16.6054 17.4878 16.9878 17.1054 17.2275 16.635C17.5 16.1002 17.5 15.4002 17.5 14V12.1667"
                            stroke="#98A2B3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  );
                })}
                <Divider className="divider" />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
    </div>
  );
};

export default StandardsAccordian;
