// Customizable Area Start
import React from "react";
import { Grid, Divider ,Box,Button,Typography} from "@material-ui/core";
export const configJSON = require("./config");
import ProjectDetailsSubController, { Props } from "./ProjectDetailsSubController";
import ProjectsSidebarSub from "./components/ProjectsSidebarSub";
import "../assets/training.scss";
import ProjectDetailsMainSub from "./components/ProjectDetailsMainSub";
import ProjectDetailsHeaderSub from "./components/ProjectDetailsHeaderSub";
import { ClassboxLogo, bgImg, durationIcon, right, subject } from "./assets";
import { styled  } from "@material-ui/core/styles";
import { getMinutes } from "../../../components/src/NativeWebRouteWrapper/Utils";

export default class ProjectDetailsSub extends ProjectDetailsSubController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <StudentViewBox>
      <Grid container spacing={3} className="training-details">
        <Box className="ClassboxLogo"><img src={ClassboxLogo}/></Box>
        <Grid item sm={3} className="sidebar">
          <ProjectsSidebarSub
            data-test-id="projecctSidebar"
            state={this.state}
            getStepsDataWithTraining={this.getStepsDataWithTraining}
            toggleOverview={this.toggleOverview}
            setContent={this.setContent}
          />
        </Grid>
        {!this.state.overview && (
          <Grid container item sm={9} className="main">
            {this.state.previewMode !== "preview" && (
              <ProjectDetailsHeaderSub
              data-test-id="projectDetailsHeader"
                state={this.state}
                navigatFinshStep={this.navigatFinshStep}
                getStepsDataWithTraining={this.getStepsDataWithTraining}
              />
            )}
            {this.state.previewMode === "preview" && (
              <>
              <div className="header-preview-mode">
                <div className="left-container-txt">
                  <h1 className="title-txt0">
                    {this.state.projectDetails?.overview.data.attributes.title}
                  </h1>
                </div>
              </div>
              {/* <Box style={{padding:"30px 85px",width:"100%"}}>
                <Typography style={{color:"black",}}>First things first</Typography>
              </Box> */}
              </>
            )}
            <Divider />
            <ProjectDetailsMainSub
            data-test-id="ProjectDetailsId"
              state={this.state}
              showPrevious={this.showPrevious}
              getPreviousStepId={this.getPreviousStepId}
              navigatFinshStep={this.navigatFinshStep}
              getStepsDataWithTraining={this.getStepsDataWithTraining}
            />
          </Grid>
        )}
         {this.state.overview === true && ( <>
         <Grid className="grid_Box" item xs={12} sm={9} md={9} lg={9}>
            <Box className="header-one">
            <img src={this.state.projectDetails?.project?.overview.data.attributes.hero_image?.url} alt="img" className="back-image" />
              <div className="cover-div"> </div>
              <Box className="heroImgBox">
                <h1 className="title">{this.state.projectDetails?.project?.overview.data.attributes.title}</h1>
                <Button
                data-test-id="btnidcheck"
                  id="handleStartTech"
                  className="start-tech-btn"
                  onClick={()=> this.toggleOverview(false)}
                >
                  Start learning{" "}
                  &nbsp;
                  <img src={right}  alt="icon" />
                </Button>
              </Box>
            </Box>
           <Box className="projectDetailBox">
            <Grid className="GridBoxMain" container xs={12}>
              <Grid className="projectDGrid" item xs={8}>
                <Typography className="projectDText">Project Details</Typography>
                <Typography className="projectDSubText">{this.state.projectDetails?.project?.overview.data.attributes.short_description}</Typography>
              <Box className="cardMainBox">
                <Box className="cardBox">
                  <img src={subject} className="cardImg"/>
                  <Typography className="cardTitle">Subjects</Typography>
                  <Typography className="cardSubTitle">{this.state.projectDetails?.project?.overview.data.attributes.subject_tags}</Typography>
                </Box>
                <Box className="cardBox">
                <img src={durationIcon} className="cardImg"/>
                  <Typography className="cardTitle">Duration</Typography>
                  <Typography className="cardSubTitle">{this.state.projectDetails?.project?.overview.data.attributes.duration} {getMinutes(this.state.projectDetails?.project?.overview.data.attributes.duration)}</Typography>
                </Box>
              </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid className="projectDGrid" item xs={3}>
              <Typography className="projectDText">Resources</Typography>
              {this.state.projectDetails.project?.overview.data.attributes.related_classboxes && <Box className="cardBoxResources">
                <Box className="ResourcesTextCard">
                  <Typography className="resourcesTitle">Classbox:</Typography>
                  <Typography className="resourcesSubTitle">{this.state.projectDetails?.project?.overview.data.attributes.related_classboxes.title}</Typography>
                  </Box>
                  
                  <Box className="resourcesSubImgBox">
                  <img src={this.state.projectDetails?.project?.overview.data.attributes.related_classboxes.emoji_url.url} className="emojiImgResources"/>
                    <img src={bgImg} className="cardImgResources"/>
                  </Box>
                </Box>}
              </Grid>
            </Grid>
           </Box>
          </Grid>
          </>
          )}
      </Grid>
      </StudentViewBox>
    );
  }
}
const StudentViewBox = styled(Box)({
  "& .ClassboxLogo":{
    height:"60px",
    background:"#00649E",
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .sidebar":{
    height:"auto",
  },
  "& .grid_Box":{
    padding:"0px",
  },
  "& .header-one":{
    position: "relative",
  height: "35vh",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  },
  "& .back-image":{
    width: "100%", 
    height: "100%",
  },
  "& .start-tech-btn":{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    background: "#FFFFFF",
    borderRadius: "10px",
    color: "#00649E",
    fontWeight: "bold",
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace !important',
  },
  "& .title":{
    paddingLeft: "3%",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "2.5vw",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.02em",
    color: "#f9f9f9",
    fontFamily: '"Gotham-Bold", "Courier New", Courier, monospace'
  },
  "& .cover-div":{
    margin: "0px !important",
    padding: "0px !important",
    display: "block",
    height: "100vh",
    overflowY: "scroll",
  },
  "& .componet-abs":{
    display: "flex",
    justifyContent: "space-between",
    width: "94%",
    left: "3%",
    bottom: "-1.8vw",
    padding: "4px",
    background: "#ffffff",
    border: "1px solid #f2f4f7",
    borderRadius: "10px",
  },
  "& .heroImgBox":{
    position:"absolute",
    left:"20px",
    height:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-around",
    alignItems:"start",
  },
  "& .projectDetailBox":{
    marginTop:'10px',
    backgroundColor:"#FCFCFD",
    width:"100%",
    height:"60%",
    padding:"20px",

  },
  "& .projectDGrid":{
    display:"flex",
    flexDirection:"column",
    gap:"20px",
    padding:"10px",
  },
  "& .projectDText":{
    fontSize:"18px",
    lineHeight:"28px",
    fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace !important',
    color:"black"
  },
  "& .projectDSubText":{
    color:"#242A32",
    fontSize:"16px",
    lineHeight:"24px",
    fontFamily: '"Gotham-Light", "Courier New", Courier, monospace !important',
  },
  "& .cardMainBox":{
    marginTop:"20px",
    width:"100%",
    height:"auto",
    display:"flex",
    gap:"20px"
  },
  "& .cardBox":{
    padding:"20px",
    width:"50%",
    height:"auto",
    background:"white",
    borderRadius:"10px",
    boxShadow: "0px 0px 12px 0px #0000000F",
    display:"flex",
    flexDirection:"column",
    gap:"5px",
  },
  "& .cardImg":{
    height:"20px",
    width:"20px",
    marginBottom:"10px",
  },
  "& .cardTitle": {
    fontSize: "16px",
    lineHeight: "24px",
    color: "black",
    fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace !important',
  },
  "& .cardSubTitle":{
    fontSize: "16px",
    lineHeight: "24px",
    color: "#475467",
    fontFamily:'"Gotham-Light", "Courier New", Courier, monospace',
  },
  "& .cardBoxResources": {
    width: "100%",
    height: "auto",
    background: "white",
    borderRadius: "10px",
    boxShadow: "0px 0px 12px 0px #0000000F",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .ResourcesTextCard": {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
  },
  "& .resourcesTitle": {
    color: "#007DC6",
    fontSize: "14px",
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace !important',
    lineHeight: "20px",
  },
  "& .resourcesSubTitle": {
    color: "#004B77",
    fontSize: "18px",
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace !important',
    lineHeight: "28px",
  },
  "& .resourcesSubImgBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .emojiImgResources":{
   position:"absolute",
   height:"85px",
  },
  "& .cardImgResources":{
    height:"100%",
  },
  "& .GridBoxMain":{
    height:"100%",
  },
})
// Customizable Area End
