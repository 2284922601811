import React from 'react'
import {
    calIcon,
    vectorIcon,
    iIcon,
    plus,
    minus,
    errorMinus,
    errorplus
} from "../assets";
import { Button } from "@material-ui/core";
import { Divider } from "react-native-elements";
import Tooltip from '@material-ui/core/Tooltip';
import { showClassbox } from '../../../../components/src/NativeWebRouteWrapper/Utils';
const AlignmentTwo = ({ alignment, state, increasePurchaseQuantity, classBoxDetails }: any) => {
    return <>
        {alignment === "1" && (
            <>
                <div className="center-div">
                    <div className="center-left">
                        <div className="heading-div">
                            <img src={calIcon} alt="img" className="cal-img" />
                            <span>{classBoxDetails?.attributes?.checkout ? "Order QTY" : "Classbox QTY"}</span>
                        </div>
                        <div className="padl-26">
                            <span className="order-text">
                                Ordering{" "}
                                <span className="order-text1">
                                    {state?.quantityPurchase}
                                </span>{" "}
                                {state.purchaseDetails?.attributes?.title} will allow{" "}
                                <span className="order-text1"> {state.studentPerNumber.attributes.students_per_group} </span>students to work in
                                groups of<span className="order-text1"> {state.purchaseGroup}</span>.
                            </span>
                        </div>
                    </div>
                    <div className={state.quantityPurchase == "5" ? "increment-error" : "increment"}>
                        <Button
                            className="increment-btn"
                            id="btn-25-vv-554"
                            onClick={() => {
                                if (state.quantityPurchase > 0) {
                                    increasePurchaseQuantity("decrement");
                                }
                            }}
                        >
                            <img src={state.quantityPurchase == "5" ? errorMinus : minus} />
                        </Button>
                        <span className="increment-text">{state.quantityPurchase}</span>
                        <Button
                            className="increment-btn"
                            id="btn-25-vv-553"
                            onClick={() => {
                                if (state.quantityPurchase <= 4)
                                    increasePurchaseQuantity("increment");
                            }}
                        >
                            <img src={state.quantityPurchase == "5" ? errorplus : plus} />
                        </Button>
                    </div>
                </div>
                <Divider style={{ marginTop: "24px" }} />
                <div className="center-div">
                    <div className="center-left">
                        <div className="heading-div">
                            <img src={vectorIcon} alt="img" className="cal-img" />
                            <span>Classbox Parts {classBoxDetails?.attributes?.checkout && ":"}</span>
                        </div>
                    </div>
                </div>
                {!classBoxDetails?.attributes?.checkout && <div className="purchase-div delesect-tag001">

                    <span className="purchasetext">
                        Deselect any parts in this Classbox that you already have and do not need to order.
                    </span>
                </div>}

                <div className="sub-classbox">
                    {showClassbox(state.alignment, classBoxDetails).map(
                        (item: any) => {
                            return <div className="sub-project">
                                <div className="sub-project-div">
                                    <span className="sub-project-text">
                                        {item?.attributes?.name}
                                    </span>
                                    <Tooltip title={item?.attributes?.tooltip}>

                                        <img src={iIcon} className="sub-project-img" />
                                    </Tooltip>
                                </div>
                                <span className="sub-project-text1">
                                    {item?.attributes?.quantity} Per {item?.attributes?.unit}
                                </span>
                            </div>
                        }

                    )}
                </div>
            </>
        )}
    </>

}

export default AlignmentTwo