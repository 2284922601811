import {FormHelperText} from "@material-ui/core";
import React from "react";
export const renderFormErrorHelperText = (error:string|undefined, touched:boolean|undefined) => {
    const errors= Boolean(error) && touched;
    let helpertext = undefined;
    if (error && touched) {
     helpertext = <FormHelperText error={true}>{error}</FormHelperText>;
    }
   return {
    error:errors,
    helpertext:helpertext,
   } 
  };

export const getParameterProject = (params: string, getID: string) => {
  let getIDx;
  if (/^\d+$/.test(params)) {
    getIDx = params;
  } else {
    getIDx = getID;
  }
  return getIDx;
};
