// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface Item {
  title: string;
  blogs: {
    id: number;
    bx_block_blogpostsmanagement_blogpost_categories_id: number;
    title: string;
    content: string;
    content_show: boolean;
  }[];
}
// Customizable Area End

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  blogData: {
    title: string;
    blogs: {
      id: number;
      bx_block_blogpostsmanagement_blogpost_categories_id: number;
      title: string;
      content: string;
      content_show: boolean;
    }[];
  }[];
  blogData1: {
    title: string;
    blogs: {
      id: number;
      bx_block_blogpostsmanagement_blogpost_categories_id: number;
      title: string;
      content: string;
      content_show: boolean;
    }[];
  }[];
  isLoading: boolean;
  content: boolean;
  blogContent: string | null;
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export interface StoreInfo {
  data?: string;
  type?: string;
  name?: string;
  attribs: {
    src: string;
    height: string;
  };
}
export interface Types {
  item: {
    content: string;
    title:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  };
}
export interface AttributesInfo {
  // Customizable Area Start
  title: string;
  content: string;
  blogs: {
    map(
      arg0: (item: {
        content: string;
        title:
        | boolean
        | import("react").ReactChild
        | import("react").ReactFragment
        | import("react").ReactPortal
        | null
        | undefined;
      }) => JSX.Element
    ): string | number | boolean | {} | null | undefined;
  };

  // Customizable Area End
}

export default class BlogPostsManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBlogsApiCallId: Message | string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      blogData: [],
      isLoading: false,
      content: false,
      blogContent: "",
      blogData1: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getBlogsApiCallId) {
          let store: {
            title: string;
            blogs: {
              id: number;
              bx_block_blogpostsmanagement_blogpost_categories_id: number;
              title: string;
              content: string;
              content_show: boolean;
            }[];
          }[] = [];
          for (let blogs in responseJson) {
            store.push({
              title: blogs,
              blogs: responseJson[blogs],
            });
          }

          let blogsData: {
            title: string;
            blogs: {
              id: number;
              bx_block_blogpostsmanagement_blogpost_categories_id: number;
              title: string;
              content: string;
              content_show: boolean;
            }[];
          }[] = [];
          for (let blogs in responseJson) {
            blogsData.push({ title: blogs, blogs: responseJson[blogs] });
          }
          this.setState({
            blogData: store,
            blogData1: blogsData,
            isLoading: false,
          });
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getTheBlogs();
  }

  goToDashbordPage = (item: string) => {
    this.props.navigation.navigate("BlogContent", { itemcontent: item });
  };

  getTheBlogs = () => {
    this.setState({
      isLoading: true,
    });

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlogsApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.BlogpostEndPoint
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
    return true;
  };
  setData = (data: string) => {
    this.setState({
      blogContent: data,
    });
  };
  // Customizable Area End
}
// Customizable Area End
