import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { down, up } from "../assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSymolColor } from "../../../../components/src/NativeWebRouteWrapper/Utils";

export default function ListTransaction({ state, fetchMoreData }: any) {
  return (
    <List className="transaction-list">
      <InfiniteScroll
        dataLength={state?.schoolCreditHistory.length}
        next={fetchMoreData}
        hasMore={state.page < state.pagination.total_pages}
        loader={
          <div className="more-loader">
            <CircularProgress />
          </div>
        }
        scrollableTarget="scrollableDiv"
        height={"100%"}
      >
        {state?.schoolCreditHistory?.map((item: any) => (
          <>
            <ListItem className="list-item-container">
              <div className="left">
                <span className="title">Credits&nbsp;{item.credit_type}</span>
                <span className="sec-txt">{item.operation}</span>
              </div>
              <div className={getSymolColor(item) ? "green" : "red"}>
                {item.value}
                <img
                  src={getSymolColor(item) ? up : down}
                  alt="icon"
                  className="p-l5"
                />
              </div>
            </ListItem>
            <Divider />
          </>
        ))}
      </InfiniteScroll>
    </List>
  );
}
