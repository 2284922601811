// Customizable Area Start
import React from "react";
import { Grid, Button } from "@material-ui/core";
export const configJSON = require("./config");
import TeacherOnboardingController, {
  Props,
} from "./TeacherOnboardingController";
import "../assets/teacher-onboarding.scss";
import SimpleDialogModal from "./components/SimpleDialogModal";
import { aeroIcon } from "./assets";
import { getTimesTitle } from "../../../components/src/NativeWebRouteWrapper/Utils";

export default class TeacherOnboarding extends TeacherOnboardingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container className="invite-teacher-container">
        <Grid container item sm={6} className="left">
          <div className="content">
            <span className="step">1/1</span>
            <span className="title">Configure</span>
            <span className="title">My Classroom</span>
            <span className="desc">Help Classbox understand the learning</span>
            <span className="desc">environment in your classroom.</span>
          </div>
        </Grid>
        <Grid container item sm={6} className="right">
          <div className="content">
            <span className="field">
              I am a
              <input
                className={
                  this.state.grade !== ""
                    ? "input-field color-blue"
                    : "input-field"
                }
                name="grade"
                placeholder="6th"
                value={this.state.grade}
                onClick={(e) => this.handleOpenModal(e,"grade")}
              />
              grade
            </span>
            <span className="field">
              <input
                className={
                  this.state.type !== ""
                    ? this.state.type === "General Studies Teacher"
                      ? "input-field-1 big color-blue"
                      : this.state.type === "Administrator" ? "input-field-1 medium color-blue" :"input-field-1 color-blue"
                    : "input-field-1"
                }
                name="type"
                placeholder="STEM"
                value={this.state.type.replace("Teacher","")}
                onClick={(e) => this.handleOpenModal(e,"type")}
              />
              teacher
            </span>
            <span className="field">and I see my students</span>
            <span className="field">
              <input
                className={
                  this.state.sessionPerWeek !== ""
                    ? "input-field-2 color-blue"
                    : "input-field-2"
                }
                name="sessionPerWeek"
                placeholder="1"
                value={this.state.sessionPerWeek}
                onClick={(e) => this.handleOpenModal(e,"sessionPerWeek")}
              />
              {getTimesTitle(this.state.sessionPerWeek)} per week
            </span>
            <span className="field">
              for
              <input
                className={
                  this.state.tutionTime !== ""
                    ? "input-field-3 color-blue"
                    : "input-field-3"
                }
                name="tutionTime"
                placeholder="60"
                value={this.state.tutionTime}
                onClick={(e) => this.handleOpenModal(e,"tutionTime")}
              />
              minutes
            </span>

            <Button data-test-id="btnRememberMe"  className="sbmt-btn" onClick={this.handleSubmit}>
              Next &nbsp;&nbsp;
              <img src={aeroIcon} alt="aero" />
            </Button>
          </div>
        </Grid>
        {this.state.openModal === "grade" && (
          <SimpleDialogModal
            id={'grade'}
            anchorEl={this.state.anchorEl}
            selectedValue={this.state.grade}
            open={this.state.openModal === "grade"}
            onClose={this.handleCloseModal}
            handleItemClick={this.setSelection}s
            name="grade"
            values={this.state.grades}
          />
        )}
        {this.state.openModal === "type" && (
          <SimpleDialogModal
            id={'type'}
            anchorEl={this.state.anchorEl}
            selectedValue={this.state.type}
            open={this.state.openModal === "type"}
            onClose={this.handleCloseModal}
            handleItemClick={this.setSelection}
            name="type"
            values={this.state.types}
          />
        )}
        {this.state.openModal === "sessionPerWeek" && (
          <SimpleDialogModal
            id={'sessionPerWeek'}
            anchorEl={this.state.anchorEl}
            selectedValue={this.state.sessionPerWeek}
            open={this.state.openModal === "sessionPerWeek"}
            onClose={this.handleCloseModal}
            handleItemClick={this.setSelection}
            name="sessionPerWeek"
            values={this.state.sessions}
          />
        )}
        {this.state.openModal === "tutionTime" && (
          <SimpleDialogModal
            id={'tutionTime'}
            anchorEl={this.state.anchorEl}
            selectedValue={this.state.tutionTime}
            open={this.state.openModal === "tutionTime"}
            onClose={this.handleCloseModal}
            handleItemClick={this.setSelection}
            name="tutionTime"
            values={this.state.tutionTimes}
          />
        )}
      </Grid>
    );
  }
}
// Customizable Area End
