Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.trainingDataGetApiEndpoint= "bx_block_trainings/trainings/"
exports.updateStepStatusEndpoint = "bx_block_trainings/steps/"
exports.fetchStepsDataWithTraining = "/bx_block_trainings/steps/"
exports.plusPlanEndPoint = "bx_block_dashboard/dashboards"

// Customizable Area End