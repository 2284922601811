// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  handleLastStep,
  newReward,
  setLocalStorageCurrentBalance,
  unCompletedArray,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  trainingDetails: any;
  severity: Color;
  message: string;
  loading: boolean;
  showSnackbar: boolean;
  count:any;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class TrainingDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTrainingDataApiId: any;
  updateStepStatusApiCallId: any;
  getStepsDataWithTrainingDataApiId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      trainingDetails: {
        training_data: {
          title: "",
          projects: [],
          training_status_calculation: 0,
        },
        current_step_data: {
          chapter_id: "",
          data: {
            id: "",
            attributes: {
              content: "",
              step_image: "",
              title: "",
            },
          },
        },
      },
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      count: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getTrainingData();
    // Customizable Area End
  }

  async componentWillUnmount() {
    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson) {
        this.setState({
          loading: false,
        });

        if (responseJson.message) {
          this.setState({
            severity: "error",
            message: responseJson.message,
            showSnackbar: true,
          });
          this.props.navigation.navigate("Trainings");
        }

        if (!responseJson.errors) {
          if (apiRequestCallId === this.getTrainingDataApiId) {
            if (responseJson.message) {
              this.setState({
                severity: "error",
                message: responseJson.message,
                showSnackbar: true,
              });
              this.props.navigation.goBack();
            }
            this.setState({
              trainingDetails: responseJson,
            });
          } else if (apiRequestCallId === this.updateStepStatusApiCallId) {
            this.setState({
              trainingDetails: responseJson,
            }, () => this.getNextStepId(true))
          } else if (
            apiRequestCallId === this.getStepsDataWithTrainingDataApiId
          ) {
            this.setState({
              trainingDetails: responseJson,
            });
          }
        }
      }
      // Customizable Area End
    }
  }

  getButtonTitle = () => {
    let Projectidx = 0;
    let chpterIdx = 0;
    let project = this.state.trainingDetails.training_data?.projects?.map(
      (item: any, index: number) => {
        let x = item.chapters.find((item: any, inx: number) => {
          if (
            item.chapter_id ===
            this.state.trainingDetails.current_step_data.chapter_id
          ) {
            chpterIdx = inx;
          }
          return (
            item.chapter_id ===
            this.state.trainingDetails.current_step_data.chapter_id
          );
        });
        if (x) {
          Projectidx = index;
        }
      }
    );
    if (
      this.state.trainingDetails.training_data?.projects[
        this.state.trainingDetails.training_data?.projects?.length - 1
      ]?.chapters[
        this.state.trainingDetails.training_data?.projects[
          this.state.trainingDetails.training_data?.projects?.length - 1
        ]?.chapters?.length - 1
      ]?.chapter_id === this.state.trainingDetails.current_step_data?.chapter_id
    ) {
      return "Finish Trainings";
    } else if (
      (this.state.trainingDetails.training_data?.projects.length > 1 &&
        this.state.trainingDetails.current_step_data?.chapter_id ===
          this.state.trainingDetails.training_data?.projects[
            this.state.trainingDetails.training_data?.projects.length
          ]?.chapters?.chapter_id) ||
      this.state.trainingDetails.training_data?.projects[Projectidx]?.chapters
        ?.length === 1 ||
      !this.state.trainingDetails.training_data?.projects[Projectidx]?.chapters[
        chpterIdx + 1
      ]
    ) {
      return "Finish Training";
    } else {
      return "Finish Step";
    }
  };

  getPreviousStepId = () => {
    let Projectidx = 0;
    let chpterIdx = 0;
    let project = this.state.trainingDetails.training_data?.projects?.map(
      (item: any, index: number) => {
        let x = item.chapters.find((item: any, inx: number) => {
          if (
            item?.chapter_id ===
            this.state.trainingDetails.current_step_data?.chapter_id
          ) {
            chpterIdx = inx;
          }
          return (
            item?.chapter_id ===
            this.state.trainingDetails.current_step_data?.chapter_id
          );
        });
        if (x) {
          Projectidx = index;
        }

        if (
          this.state.trainingDetails.training_data?.projects[Projectidx]
            ?.chapters[chpterIdx - 1]?.chapter_id
        ) {
          this.getStepsDataWithTraining(
            this.state.trainingDetails.training_data?.projects[Projectidx]
              ?.chapters[chpterIdx - 1]?.chapter_id
          );
        }

        return true;
      }
    );
  };
  getNextStepId = (redirect: boolean) => {
    let Projectidx = 0;
    let chpterIdx = 0;
    let project = this.state.trainingDetails.training_data.projects?.map(
      (item: any, index: number) => {
        let x = item.chapters.find((item: any, inx: number) => {
          if (
            item?.chapter_id ===
            this.state.trainingDetails.current_step_data?.chapter_id
          ) {
            chpterIdx = inx;
          }
          return (
            item?.chapter_id ===
            this.state.trainingDetails.current_step_data?.chapter_id
          );
        });
        if (x) {
          Projectidx = index;
        }
        if (
          this.state.trainingDetails.training_data?.projects[Projectidx]
            ?.chapters[chpterIdx + 1]?.chapter_id
        ) {
          this.getStepsDataWithTraining(
            this.state.trainingDetails.training_data?.projects[Projectidx]
              ?.chapters[chpterIdx + 1]?.chapter_id
          );
        } else {
          if (
            this.state.trainingDetails.training_data?.projects[Projectidx + 1]
              ?.chapters[0]?.chapter_id
          ) {
            this.getStepsDataWithTraining(
              this.state.trainingDetails.training_data?.projects[Projectidx + 1]
                ?.chapters[0]?.chapter_id
            );
          } else {
          let incompleted:any = unCompletedArray(this.state.trainingDetails.training_data.projects)
            handleLastStep(incompleted, this.getStepsDataWithTraining, this.props.navigation.getParam("credit"), this.state, this.props.navigation)
          }
        }
        return true;
      }
    );
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  showPrevious = () => {
    let Projectidx = 0;
    let chpterIdx = {};
    let project = this.state.trainingDetails.training_data?.projects?.map(
      (item: any, index: number) => {
        let x = item.chapters.find((item: any, inx: number) => {
          if (
            item?.chapter_id ===
            this.state.trainingDetails.current_step_data?.chapter_id
          ) {
            chpterIdx = inx;
          }
          return (
            item?.chapter_id ===
            this.state.trainingDetails.current_step_data?.chapter_id
          );
        });
        if (x) {
          Projectidx = index;
        }
      }
    );

    if (chpterIdx === 0) {
      return false;
    } else {
      return true;
    }
  };

  getStepsDataWithTraining = (id: string | number) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStepsDataWithTrainingDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchStepsDataWithTraining}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getTrainingData = () => {
    let token = getAuthToken();
    let x = this.props.navigation?.getParam("id");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrainingDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.trainingDataGetApiEndpoint}${x}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateStepStatus = () => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };
    let incompleted:any = unCompletedArray(this.state.trainingDetails.training_data.projects)
    this.setState({
       count: incompleted.length
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateStepStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateStepStatusEndpoint}${this.state?.trainingDetails?.current_step_data?.chapter_id}?status=completed`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
// Customizable Area End

// Customizable Area End
