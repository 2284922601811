// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  handleTokenError,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { cardImg, secondImg, thirdImg } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  historyList: any;
  loading: boolean;
  successManager: string;
  addData: any;
  managerDetails: any;
  page: number;
  pagination: any;
  schoolCreditHistory: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class AddCreditsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  submitApiCallId: any;
  historyDetails: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      open: false,
      historyList: [],
      managerDetails: [],
      loading: false,
      successManager: "",
      addData: [
        {
          id: 1,
          discount: "",
          credits: 1000,
          price: 5000,
          icon: cardImg,
          download_quote:'https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/373a8f66-c530-4692-9029-e8d7f192d901/Classbox1000__1_.pdf'
        },
        {
          id: 2,
          discount: 10,
          credits: 2500,
          price: 11250,
          icon: secondImg,
          download_quote:'https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/c06c9283-c7bc-4eda-b8a0-54095984a3cc/Classbox2500__1_.pdf'
        },
        {
          id: 3,
          discount: 15,
          credits: 5000,
          price: 21250,
          icon: thirdImg,
          download_quote:'https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/db4b055e-af8f-40fd-86a3-b1df2f71aee1/Classbox5000__1_.pdf'
        },
      ],
      page: 1,
      pagination: {},
      schoolCreditHistory: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getHistoryDetails();

    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });
      }

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.historyDetails) {
          this.setState({
            historyList: responseJson.history,
            managerDetails: responseJson.manager_details,
            pagination: responseJson.meta.pagination,
            schoolCreditHistory: [
              ...this.state.schoolCreditHistory,
              ...responseJson.history,
            ],
          });
        }
      }
    }
    // Customizable Area End
  }
  getHistoryDetails = () => {
    const token = getAuthToken();
    const userInfo = getUserDetails();
    const id = userInfo.school.data.id;
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.historyDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.historyDetails}?school_id=${id}&page=${this.state.page
      }&per_page=${5}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDownload = (url: any) => {
    window.open(url);
  };

  openManagerMail = () => {
    window.location.href = `mailto:${getUserDetails().school.data.attributes.manager_details.data.attributes
        .email
      }?subject=SendMail&body=Description`;
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getHistoryDetails()
    );
  };
}

// Customizable Area End

// Customizable Area End
