export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const more = require("../assets/more.svg");
export const plus = require("../assets/plus.svg");
export const right = require("../assets/right.svg");
export const rightAero = require("../assets/right-aero.svg");
export const up = require("../assets/up.svg");
export const down = require("../assets/down.svg");
export const play = require("../assets/play.svg");
export const rightAeroWhite = require("../assets/right-aero-white.svg");
export const classboxHeaderImg = require("../assets/classboxHeader.svg");
export const keepItIcon = require("../assets/keepItIcon.svg");
export const returnIcon = require("../assets/returnIcon.svg");
export const emptyDocIcon = require("../assets/emptyDocIcon.svg");
export const exploreIcon = require("../assets/exploreIcon.svg");
export const cardBackImage = require("../assets/FrameCard.svg");
export const pinWhiteIcon = require("../assets/pinWhite.svg");
export const pinBlankIcon = require("../assets/pinBlank.svg");