import React from "react";
import { Button } from "@material-ui/core";
import { confirmation } from "../assets";
import "../../assets/successcard.scss";
import { rightArrowIcon } from "../../../catalogue/src/assets";
import { dateFormatMM, getCommaSeperator, getCredits } from "../../../../components/src/NativeWebRouteWrapper/Utils";

export default function KeepClassboxSuccess({
  navigation,
  cost,
  tab,
  newDate,
}: any) {
  return (
    <div className="container-comfirmed1">
      <img src={confirmation} alt="img" className="img1"></img>
      {tab === "keep" ? (
        <div className="top-div1">
          <span className="title1">You now own this Classbox!</span>
          <br></br>
          <br></br>
          <span className="description1">
            You can now manage this Classbox in your locker.
          </span>
        </div>
      ) : (
        <div className="top-div1">
          <span className="title1">Checkout Extended!</span>
          <br></br>
          <br></br>
          <span className="description1">
            Whew, more time for hands-on learning!
          </span>
        </div>
      )}
      <div className="bottom-div2">
        {tab === "keep" ? (
          <div className="classbox-container">
            <div className="classbox-item-1">
              <span className="classbox-item-txt">
                {getCommaSeperator(cost)} <span className="classbox-subtext">{getCredits(cost)}</span>
              </span>
              <span className="classbox-item-txt1">Total amount charged</span>
            </div>
          </div>
        ) : (
          <div className="classbox-container">
            <div className="classbox-item-1">
              <span className="classbox-item-txt">
                {dateFormatMM(newDate)}
              </span>
              <span className="classbox-item-txt1">New Return Date</span>
            </div>
          </div>
        )}
        <Button
          className="right-btn1"
          onClick={() => {
            navigation.navigate("Dashboard");
          }}
        >
          Dashboard &nbsp;
          <img src={rightArrowIcon} alt="i" />
        </Button>
      </div>
    </div>
  );
}
