import React from "react";
import ProjectComponent from "./ProjectsComponent";
import TeacherHeader from "./TeacherHeader";
import Grid from "@material-ui/core/Grid";
import VideoCard from "./VideoCard";
import EmptyTeacherDashboard from "./EmptyTeacherDashboard";
import { getEmojiFromItem, getTitleFromItem, getUserDetails } from "../../../../components/src/NativeWebRouteWrapper/Utils";
import { cardBackImage } from "../assets";
import { Button } from "rsuite";
import { aeroIcon } from "../../../invitefriends/src/assets";
import OrderModal from "../../../ContentManagement/src/components/OrderModal";
export const TeacherDashBoardMain = ({
  state,
  handleClose,
  isFirstTime,
  showTitle,
  startStopProject,
  deleteProject,
  handleDialouge,
  navigation,
  handleClose2,
  handleModalTabChange,
  handleCheckOut,
  handleReturnClassBox,
  handleKeepClassBox,
  handleExtendClassBox,
  requestCreditsForTraining,
  props
}: any) => {
  let user = getUserDetails();
  let showHeader = !(localStorage.getItem("showHeader") === "true");
  return (
    <Grid container spacing={5} className="teacher-dashboard">
      <>
        {showHeader ? (
          <Grid sm={12} item className="title">
            <span>Welcome back,</span>&nbsp;
            <span className="name"><span>{user.first_name.charAt(0).toUpperCase()}</span>{user.first_name.slice(1)}</span>
          </Grid>
        ) : (
          <Grid sm={12} item className="title">
            <span>Welcome,</span>&nbsp;
            <span className="name"><span>{user.first_name.charAt(0).toUpperCase()}</span>{user.first_name.slice(1)}</span>
          </Grid>
        )}
      </>
      {state.getData.length === 0 &&
        state.projectCompletionData.length === 0 &&
        state.upcomingDelivery.length === 0 ? (
        <Grid item sm={12} className="empty-dashboard-teacher">
          <EmptyTeacherDashboard props={props} />
        </Grid>
      ) : (
        <>
          {state.projectCompletionData.length > 0 &&
            state.projectCompletionData.map((items: any) => {
              return (
                <>
                  {
                    <div className="card pointer">
                      <img
                        src={cardBackImage}
                        alt="icon"
                        className="backimage"
                      />
                      <div className="content">
                        <div className="content-inside">
                          <span className="title1">
                            Congrats on finishing all the
                          </span>
                          <span className="title1">
                            {" "}
                            {getTitleFromItem(items)}!
                          </span>
                          <span className="title2">
                            Now, choose if you'd prefer to keep the Classbox
                          </span>
                          <span className="title2">
                            used in these projects, or return it.
                          </span>
                          <Button
                            onClick={() => handleCheckOut(items)}
                            className="manage-btn"
                          >
                            Manage Classbox
                            <img src={aeroIcon} className="exploreIcon" />
                          </Button>
                        </div>
                        <img
                          src={getEmojiFromItem(items)}
                          alt="icon"
                          className="icon card-img0width-sxy10n"
                        />
                      </div>
                    </div>
                  }
                </>
              );
            })}

          {state.upcomingDelivery.length !== 0 && (
            <Grid item sm={12}>
              <TeacherHeader
                navigation={navigation}
                deliveryData={state.upcomingDelivery}
              />
            </Grid>
          )}

          {showTitle(state.getData, true) && (
            <Grid item sm={12} className="titie-grid">
              <span className="title-header">Teaching Now:</span>
            </Grid>
          )}

          {state.getData.map((item: any, index: number) => {
            return (
              <>
                {item.attributes.is_teaching && (
                  <Grid item sm={12} className="titie-grid-card">
                    <ProjectComponent
                      navigation={navigation}
                      handleDialouge={handleDialouge}
                      deleteProject={deleteProject}
                      start="Stop teaching"
                      item={item}
                      index={index}
                      startStopProject={startStopProject}
                      state={state}
                    />
                  </Grid>
                )}
              </>
            );
          })}
          {showTitle(state.getData, false) && (
            <Grid item sm={12} className="titie-grid">
              <span className="title-header">My Project Plan: </span>
            </Grid>
          )}

          {state.getData.map((item: any, index: number) => {
            return (
              <>
                {item.attributes.is_teaching === false && (
                  <Grid item sm={12} className="titie-grid-card">
                    <ProjectComponent
                      navigation={navigation}
                      handleDialouge={handleDialouge}
                      deleteProject={deleteProject}
                      start="Start teaching"
                      item={item}
                      index={index}
                      startStopProject={startStopProject}
                      state={state}
                    />
                  </Grid>
                )}
              </>
            );
          })}
        </>
      )}
      {isFirstTime && (
        <Grid className="video-card">
          <VideoCard open={state.showVideoModal} handleClose={handleClose} navigation={navigation}/>
        </Grid>
      )}
      {state.orderModalOpen && (
        <OrderModal
          handleClose={handleClose2}
          handleModalTabChange={handleModalTabChange}
          state={state}
          handleReturnClassBox={handleReturnClassBox}
          handleKeepClassBox={handleKeepClassBox}
          handleExtendClassBox={handleExtendClassBox}
          requestCreditsForTraining={requestCreditsForTraining}
        />
      )}
    </Grid>
  );
};
