import React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import { getTrimTitle } from "../../../../components/src/NativeWebRouteWrapper/Utils";

const ProgressBarComponent = (
  props: LinearProgressProps & { value: number; title: string }
) => {
  return (
    <div className="progress-bar-title">
      <div className="title-with-progress">
        <span className="title templdkd">{getTrimTitle(19, props.title)}</span>
        <LinearProgress variant="determinate" {...props} />
      </div>
      <span className="value-progress">{`${Math.round(props.value)}%`}</span>
    </div>
  );
};

export default ProgressBarComponent;
