import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Paper,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { success } from "./assets";
import "../src/assets/index.scss";

const Schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is Required.")
    .min(8, "Your Password must Contain 8 Characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z]).+$/,
      "Must Contain One Uppercase, One Lowercase."
    )
    .matches(/^(?=.*\d).+$/, "Must Contain One Number."),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#027ec8",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
// Customizable Area End

import ForgotPasswordOneController, {
  Props,
  configJSON,
} from "./ForgotPasswordOneController";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { errorIcon } from "../../EmailNotifications2/src/assets";

export default class ForgotPasswordOne extends ForgotPasswordOneController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPasswordSymbol = (errors: any, touched: any) => {
    return !!errors.password && !!touched.password ? (
      <img src={errorIcon} alt="icon" />
    ) : (
      <></>
    );
  };
  getconfirmPasswordSymbol = (errors: any, touched: any) => {
    return !!errors.confirmPassword && !!touched.confirmPassword ? (
      <img src={errorIcon} alt="icon" />
    ) : (
      <></>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="forgotPasswordContainer">
        <ThemeProvider theme={theme}>
          <Box
            style={{
              width: "33%",
              display: "flex",
              margin: "auto",
            }}
          >
            <Paper style={{ padding: "4%", width: "90%" }}>
              <Typography variant="h5" className="modal-title-bold">
                Reset Password
              </Typography>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                //@ts-ignore
                onSubmit={this.changePassword}
                validationSchema={Schema}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  isValid,
                  dirty,
                }) => {
                  return (
                    //@ts-ignore
                    <Form>
                      {!!errors.password && !!touched.password && (
                        <div className="error-box1">
                          <span className="title">
                            Your password doesn't match the criteria
                          </span>
                          <ul className="error-list">
                            <li className="error">
                              At least one capital letter
                            </li>
                            <li className="error">
                              At least one lower case letter
                            </li>
                            <li className="error">At least one number</li>
                            <li className="error">
                              Minimum character length is 8 character
                            </li>
                          </ul>
                        </div>
                      )}
                      <InputLabel
                        style={webStyle.top1}
                        className="input-label-bold-cmn"
                      >
                        New Password
                      </InputLabel>
                      <TextField
                        onChange={handleChange("password")}
                        data-test-id={"txtInputProductId"}
                        value={values.password}
                        name="password"
                        placeholder="Enter Password"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        error={!!errors.password && !!touched.password}
                        InputProps={{
                          endAdornment: this.getPasswordSymbol(errors, touched),
                        }}
                        required
                        type="password"
                      />

                      <InputLabel
                        className="input-label-bold-cmn"
                        style={webStyle.top1}
                      >
                        Confirm New Password
                      </InputLabel>
                      <TextField
                        onChange={handleChange("confirmPassword")}
                        data-test-id={"txtInputProductId"}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        helperText={<ErrorMessage name="confirmPassword" />}
                        error={
                          !!errors.confirmPassword && !!touched.confirmPassword
                        }
                        InputProps={{
                          endAdornment: this.getconfirmPasswordSymbol(
                            errors,
                            touched
                          ),
                        }}
                        required
                        type="password"
                      />
                      <Button
                        style={{ marginTop: "4%", float: "right" }}
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={() => handleSubmit()}
                        data-test-id={"btnAddExample"}
                      >
                        Save changes
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>

            <Dialog
              onClose={this.handleDiaog}
              aria-labelledby="customized-dialog-title"
              open={this.state.successDialog}
            >
              <Box
                style={{
                  height: "20%",
                  backgroundColor: "#f0fdf4",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={success} style={{ margin: "12% 0%" }}></img>
              </Box>
              <DialogContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  className="modal-title-bold"
                >
                  Password changed successfully!
                </Typography>
                <Typography
                  style={{ fontSize: "0.875rem" }}
                  gutterBottom
                  className="modal-desc-light"
                >
                  Please log in again
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                style={{
                  padding: "7px 18px",
                  borderRadius: "8px"
                }}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  autoFocus
                  onClick={this.handleDiaog}
                  color="primary"
                >
                  Log In
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={this.state.showSnackbar}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MuiAlert
              onClose={this.handleCloseSnackbar}
              severity={this.state.severity}
            >
              {this.state.message}
            </MuiAlert>
          </Snackbar>
          <LoaderComponent open={this.state.loading} />
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  top1: {
    marginTop: "4%",
  },
};
// Customizable Area End
