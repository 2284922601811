import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AccordionDetails, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { remaining,sideBarHeader } from "../assets";
import { getClassNameForStepOfProjectSub, getClassNameForStepOfProjectPreview, getProjectProgressTitle, getTrimTitle } from "../../../../components/src/NativeWebRouteWrapper/Utils";
interface JsonResponse {

  current_project_step_data: {
    status: string;
    data: {
      attributes: {
        step_name: string
        }
     }
     project_chapter_id: string | number;
   }
   project: {
     project_emoji: string;
     project_id: number | string;
     project_status_calculation: number;
     project_chapters: {
       project_chapter_id: string | number;
       title: string;
       status: string;
     }[];

    account_project: string
  };

}
/* istanbul ignore next */
const ProjectsSidebarSub = ({
  state,
  getStepsDataWithTraining,
  toggleOverview,
  setContent,
}:any) => {
return (
    <>
    <Grid
      container
      className="sidebar1"
    >
      <Grid 
      item 
      className="header"
      >
        <img 
        src={sideBarHeader}
         alt="back-img" 
         className="header-image" />
        <img src={
            state.projectDetails?.project?.project_emoji ||
            state.projectDetails?.overview?.data?.attributes?.project_emoji.url
          }
          className="icon-sidebar-001"
          alt="title-icon"
        />
        <div className={

            state?.projectDetails?.project?.project_status_calculation === 100
              ? "header-title1"
              : "header-title"
          }
        >
          {state.previewMode == "preview" && 
          (<span className="title templdkd">{getProjectProgressTitle(state)}</span>)}
          {state.previewMode !== "preview" && 
          (<span className="title templdkd" style={{
            width: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            justifyContent:"center"
          }}>{getProjectProgressTitle(state)}</span>)}

        </div>
      </Grid>
      <div
       className="accordian-container-1"
       >
        <Grid
          className="accordian-container"
        >
          <div style={
            state.overview
              ? {
                margin: "8px",
                padding: "10px 0px",
                borderRadius: "8px",
                background: "#E8F6FE",
              }
              : {
                padding: "18px 0px"
              }
          }
          >
            <span id="Open-overview-btn" className="title-project-overview pointer" onClick={() => toggleOverview(true)}
            >
              Overview
            </span>
          </div>
          <Accordion
            key={state?.projectDetails?.project?.project_id}
            defaultExpanded={true}
          >
            <AccordionSummary

              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <span
                className="title-project-overview">Steps</span>
            </AccordionSummary>
            {state.previewMode !== "preview" && (
              <AccordionDetails className="accordion-details steps-container">
                {state?.projectDetails?.project?.project_chapters.map(
                  (item: { project_chapter_id: string | number; title: string; }, index: number) => (
                    <div
                      className={getClassNameForStepOfProjectSub(state, item.project_chapter_id)}
                      key={item.project_chapter_id}
                      id="course-step-btn"
                      onClick={() =>
                        getStepsDataWithTraining(item.project_chapter_id)
                      }
                    >
                      <span className="title">
                        &nbsp;&nbsp;{index + 1}:&nbsp;
                      </span>
                      <span className="title pointer">{getTrimTitle(40, item.title)}</span>
                    </div>
                  )
                )}
              </AccordionDetails>
            )}
            {
              state.previewMode === "preview"
              && (
                <AccordionDetails
                  className="accordion-details steps-container"
                >
                  {state?.projectDetails?.steps?.data.map(
                    (item: { attributes: { content: any; step_name: any; }; id: React.Key | undefined; }, index: number) => {
                      let { content, step_name } = item?.attributes;
                      return (
                        <div
                          className={getClassNameForStepOfProjectPreview(state, item.id)}
                          id="set-content-btn"
                          onClick={() => setContent(content, item.id)}
                          key={item.id}
                        >
                          <img
                            src={remaining}
                            alt="iconn"
                          />
                          <span
                            className="title"
                          >
                            &nbsp;&nbsp;{index + 1}:&nbsp;
                          </span>
                          <span
                            className="title pointer">
                            {getTrimTitle(40, step_name)}
                          </span>
                        </div>
                      );
                    }
                  )}
                </AccordionDetails>
              )}
          </Accordion>
        </Grid>
      </div>
    </Grid>
    </>
  );
};

export default ProjectsSidebarSub;
