export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img14441703d5a937cc65a62353cbea2a59834b3da3 = require("../../global_assets/14441703d5a937cc65a62353cbea2a59834b3da3.png");
export const img734bc1ec08e7f8f448efe762ab29ec51881e2bb8 = require("../../global_assets/734bc1ec08e7f8f448efe762ab29ec51881e2bb8.png");
export const imga6a12f9de0eabddd474cb357fc50c96cb3a1ad4d = require("../../global_assets/a6a12f9de0eabddd474cb357fc50c96cb3a1ad4d.png");
export const imgc0bd80fe52bdf3959f1f3661373dc2420085fb99 = require("../../global_assets/c0bd80fe52bdf3959f1f3661373dc2420085fb99.png");
export const imga8e05f18b254f98a6d2806bb2dd30ae5a21c89d5 = require("../../global_assets/a8e05f18b254f98a6d2806bb2dd30ae5a21c89d5.png");
export const imgb8f5ae1b903b3d44ba612e3e9b49fea6395c955d = require("../../global_assets/b8f5ae1b903b3d44ba612e3e9b49fea6395c955d.png");
export const img45864218faf9136287b783ee1adf42652b42b48a = require("../../global_assets/45864218faf9136287b783ee1adf42652b42b48a.png");
export const imge6c8598ffa2eaba0db1237da4123c4fcc5e030a2 = require("../../global_assets/e6c8598ffa2eaba0db1237da4123c4fcc5e030a2.png");
export const img92e2a4d3eece27f5d33dd9372c777f1d15603498 = require("../../global_assets/92e2a4d3eece27f5d33dd9372c777f1d15603498.png");
export const img65e24f8277df903eda6dee03b47890b440af86ac = require("../../global_assets/65e24f8277df903eda6dee03b47890b440af86ac.png");
export const img06f497f3df875f4aa8b8b95e7a161a16cd7c3ede = require("../../global_assets/06f497f3df875f4aa8b8b95e7a161a16cd7c3ede.png");
export const imga6f4e14bd6b0ce77279a8466b1f5739dd26df6f5 = require("../../global_assets/a6f4e14bd6b0ce77279a8466b1f5739dd26df6f5.png");
export const img871550f4aabd06b1ec352fec357705c79cad85f9 = require("../../global_assets/871550f4aabd06b1ec352fec357705c79cad85f9.png");
export const img3c3a10eb22f39858890b541b34866145517f6c91 = require("../../global_assets/3c3a10eb22f39858890b541b34866145517f6c91.png");
export const imge83aa4ef252161cfa6e9f7c5342bed634d7cfac2 = require("../../global_assets/e83aa4ef252161cfa6e9f7c5342bed634d7cfac2.png");
export const img69c188abcd647f3f3bf2851ae7e39ff60ea591c6 = require("../../global_assets/69c188abcd647f3f3bf2851ae7e39ff60ea591c6.png");
export const img5cf1bd1441f8e95d7da1e0722dea0608a9874ec3 = require("../assets/5cf1bd1441f8e95d7da1e0722dea0608a9874ec3.png");
export const img6d631ba7047b11214b92decfac2826382514611b = require("../assets/6d631ba7047b11214b92decfac2826382514611b.png");
export const img0c77d3f8f9ed331e493dbbadd11f36ef19929f39 = require("../assets/0c77d3f8f9ed331e493dbbadd11f36ef19929f39.png");
export const imgfbb4ea90e7ce23328e068be700f199d7a9ad38e1 = require("../assets/fbb4ea90e7ce23328e068be700f199d7a9ad38e1.png");
export const img6ccf9fcb02ce53b03afe1f9f81c84842fc81fb1e = require("../assets/6ccf9fcb02ce53b03afe1f9f81c84842fc81fb1e.png");
export const img1b8a95c135c67feb466a1e3d1067af87e4d2e66b = require("../assets/1b8a95c135c67feb466a1e3d1067af87e4d2e66b.png");
export const img4ac719b48dacbd932a84af8f8facd113ab488ae1 = require("../assets/4ac719b48dacbd932a84af8f8facd113ab488ae1.png");
export const imgc0ebf168656b0e873ffe28f830275d0e53923434 = require("../assets/c0ebf168656b0e873ffe28f830275d0e53923434.png");
export const img85b7a5b98d462aa6aa685adea8b4767fd3ee4b6c = require("../assets/85b7a5b98d462aa6aa685adea8b4767fd3ee4b6c.png");
export const img2eed92cd28f85d35b7d43f995d9032314722737b = require("../assets/2eed92cd28f85d35b7d43f995d9032314722737b.png");
export const img6b2f81767e5a82b161a0fd5b6fc9bffc574cd09c = require("../assets/6b2f81767e5a82b161a0fd5b6fc9bffc574cd09c.png");
export const imgca459ec29c58665ab72dbcb0a13c2ee799de861c = require("../assets/ca459ec29c58665ab72dbcb0a13c2ee799de861c.png");
export const img43a74f5e0bafcaafd6393a1061450f13debbd1b9 = require("../assets/43a74f5e0bafcaafd6393a1061450f13debbd1b9.png");
export const img102eae2bc2997b506d28d0c99a38f7a69767b651 = require("../assets/102eae2bc2997b506d28d0c99a38f7a69767b651.png");
export const imge2bc9720129deb90b573af5c9bc06fca881fe7e1 = require("../assets/e2bc9720129deb90b573af5c9bc06fca881fe7e1.png");
export const img85f2c9b02143e029ce98c1b27abe868c0d6f901a = require("../assets/85f2c9b02143e029ce98c1b27abe868c0d6f901a.png");
export const profileFallback = require("../assets/profile-fallback.svg")
export const uploadIcon = require("../assets/uploadIcon.svg")
export const alertInfoIcon = require("../assets/alert-icon.svg")