// Customizable Area Start
import React from "react";
import BlogPostsManagementController, { Item, Props } from "./BlogPostsManagementController";
import { backIcon } from "./assets";

export default class BlogpostsManagement extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
          className="blogs-container"
        >
          {
            !this.state.blogContent && <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <h1>Blogs</h1>
            </div>
          }
          {!this.state.blogContent &&
            this.state.blogData1?.map((item: Item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "30%",
                    alignSelf: "center",
                    paddingTop: "30px",
                  }}
                  className="blog-component"
                  key={item.title}
                >
                  <div>
                    <h3>{item.title}</h3>
                  </div>
                  <div
                    style={{
                      paddingLeft: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {item.blogs.map(
                      (item: {
                        id: number;
                        bx_block_blogpostsmanagement_blogpost_categories_id: number;
                        title: string;
                        content: string;
                        content_show: boolean;
                      }) => {
                        return (
                          <li
                            key={item.id}
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                              paddingBottom: "5px",
                            }}
                            className="blog-name"
                            onClick={() => this.setData(item.content)}
                          >
                            {item.title}
                          </li>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {!!this.state.blogContent && (
          <div
            style={{
              width: "100%",
              padding: "50px",
            }}
            className="back-btn-with-blogdata"
          >
            <div onClick={() => this.setData("")} className="back-btn">
              <img src={backIcon} alt="back" /> &nbsp; Back
            </div>
            <div dangerouslySetInnerHTML={{ __html: this.state.blogContent }} />
          </div>
        )}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area End
