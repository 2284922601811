import React from "react";
import {
  FormControl,
  Input,
  MenuItem,
  Select,

} from "@material-ui/core";
import { Polymer } from "@material-ui/icons";
import { checkIcon } from "../assets"
import { Divider } from "rsuite";
import { setSelectedOption } from "../../../../components/src/NativeWebRouteWrapper/classboxesSlice";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../../assets/standard.scss";
import { handleOptionToggle, handleSelectAll, setSelectedOptions, getContainerOfFilter } from "../../../../components/src/NativeWebRouteWrapper/Utils";


const StandardDropDown: React.FC = ({ standardData, state, setStandardFilter, selectAllStandard, storeIdsOfCheckedStandard }: any) => {
  const dispatch = useDispatch()
  const selectedOptions = useSelector((state: any) => state.classboxesSlice.selectedOptions)

  let allOptionsSelected = selectedOptions.length === standardData.length;

  return (
    <FormControl variant="outlined" fullWidth className="accordion-main-divxyz">
      <Select
        id="select-id-standard"
        onFocus={() => storeIdsOfCheckedStandard(standardData[0].stdId)}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null, // Removes vertical offset (optional)
          container: getContainerOfFilter,
        }}
        multiple
        value={selectedOptions}
        input={
          <Input
            id="select-card-bckground"
            disableUnderline
            className="input-multipleselect"
            inputProps={{
              endAdornment: <Polymer color="primary" />,
            }}
          />
        } // Custom class for rounded input box
        IconComponent={ExpandMoreIcon}
        // @ts-ignore
        renderValue={(selected: any) => {
          // @ts-ignore
          return <SelectedForRender selected={selected} allOptionsSelected={allOptionsSelected} state={state} />
        }}
      >
        <MenuRenderItem standardData={standardData} handleSelectAll={handleSelectAll} handleOptionToggle={handleOptionToggle} selectedOptions={selectedOptions} setStandardFilter={setStandardFilter} setSelectedOptions={setSelectedOptions} selectAllStandard={selectAllStandard} dispatch={dispatch} />
      </Select>
    </FormControl >
  );
};

export const MenuRenderItem = ({ standardData, handleSelectAll, handleOptionToggle, selectedOptions, setStandardFilter, setSelectedOptions, selectAllStandard, dispatch }: any) => {
  return <>
    {/* @ts-ignore */}
    <MenuItem value="all" onClick={() => handleSelectAll(selectedOptions, standardData, setStandardFilter, setSelectedOptions, selectAllStandard, dispatch, setSelectedOption)} id="all-standard-set">
      <span className="standard-list-text">All standards in set</span>
    </MenuItem>
    <Divider className="divider-unset" />
    {standardData.map((option: any) => {
      return < MenuItem
        id="standard-set1"
        key={option.id}
        value={option.id}
      // onClick={() => handleOptionToggle(option.id)}
      >
        <div className="standard-select-name" onClick={handleOptionToggle(option, selectedOptions, setStandardFilter, setSelectedOptions, dispatch, setSelectedOption)}>

          <span className={selectedOptions.indexOf(option.id) !== -1 ? "standard-list-text-checked" : "standard-list-text"} >{option.title}</span>
          {selectedOptions.indexOf(option.id) !== -1 && (
            <img src={checkIcon}></img>
          )}
        </div>
      </MenuItem>
    })}
  </>
}

export const SelectedForRender = ({ selected, allOptionsSelected, state }: any) => {
  if (selected.length === 0) {
    return <span className="standard-placeholderxyz1">Not filtering</span>;
  }
  else {
    return <span className="standard-selected-placeholderxyz1">{state.showStandard.join(", ")}</span>;
  }
}

export default StandardDropDown
