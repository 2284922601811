Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.trainingAPiEndPoint = "bx_block_trainings/trainings";
exports.partnerTrainingOrder = "bx_block_trainings/trainings/";
exports.apiMethodTypePost = "POST";
exports.requestTrainingApi = "bx_block_trainings/teacher_requests"
// Customizable Area End