import React from "react";
// Customizable Area Start
import {
  Grid,
  Avatar,
  IconButton,
  OutlinedInput,
  FormHelperText,
  Divider,
  Select,
  MenuItem,
  Button,
  Typography
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { UploadIcon, UploadFileIcon, DownLoadIcon, FileIcon } from "./assets";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/vendorprofile.scss";
import MaskedInput from "../../../components/src/MaskedInput";
const validationSchema = Yup.object({
  companyName: Yup.string()
    .max(255, "Company Name must be less than 255 characters")
    .required(),
  companyEmail: Yup.string()
    .max(255, "Email must be less than 255 characters")
    .required()
});

const validationSchemaPersonalData = Yup.object({
  first_name: Yup.string().required("Name is Required"),
  phone_number: Yup.string().matches(
    /^\+?\d{10,}$/,
    "Phone number must be at least 10 digits and contain only numbers"
  ).nullable()
});

const validationSchemaPasseord = Yup.object().shape({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(/(?=.*[A-Z])/, "Must contain at least one uppercase letter")
    .matches(/(?=.*[a-z])/, "Must contain at least one lowercase letter")
    .matches(/(?=.*[0-9])/, "Must contain at least one number")
    .min(8, "Must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Grid container className="company-container">
          <Grid item className="company-title">
            🏢 Vendor Profile
          </Grid>
          <Grid sm={12} item className="profile-container">
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              className="icon-btn-2"
            >
              <input
                type="file"
                hidden
                accept="image/*"
                className="filetype"
                id="group_image"
                data-test-id="upload-picture"
                onChange={this.onImageChangeVendor}
              />
              <Avatar
                src={this.state.profileAvatar}
                className="profile-avatar"
                data-test-id="avatar"
              />
              <img
                className="nonvalid-profile-icon"
                data-test-id="profile-image"
                src={UploadIcon}
              />
            </IconButton>
          </Grid>
        
            <Grid sm={12} className="company-name">
              {this.state.initialValuesCompanyProfile.companyName}
            </Grid>
          </Grid>
          <Grid item>
            <button
              data-test-id="company-profile"
              className={
                this.state.activeButton === "Company Profile"
                  ? "tab-buttons-clicked"
                  : "tab-buttons"
              }
              onClick={() => this.handleButtonClick("Company Profile")}
            >
              Company Profile
            </button>
            <button
              data-test-id="personal-data-button"
              className={
                this.state.activeButton === "Personal Data"
                  ? "tab-buttons-clicked"
                  : "tab-buttons"
              }
              onClick={() => this.handleButtonClick("Personal Data")}
            >
              Personal Data
            </button>
            <button
              data-test-id="payout-account-button"
              className={
                this.state.activeButton === "Payout Account"
                  ? "tab-buttons-clicked"
                  : "tab-buttons"
              }
              onClick={() => this.handleButtonClick("Payout Account")}
            >
              Payout Account
            </button>
            <button
              data-test-id="documents-button"
              className={
                this.state.activeButton === "Documents"
                  ? "tab-buttons-clicked"
                  : "tab-buttons"
              }
              onClick={() => this.handleButtonClick("Documents")}
            >
              Documents
            </button>
          </Grid>
          {this.state.activeButton === "Company Profile" && (
            <Formik
              initialValues={this.state.initialValuesCompanyProfile}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={this.handleSubmitCompanyProfile}
              data-test-id="company-form-1"
            >
              {(props) => (
                <form
                  onSubmit={props.handleSubmit}
                  className="company-data-container"
                  data-test-id="company-form"
                >
                  <Grid className="company-data-title">Company Data</Grid>
                  <Grid className="textfeild-label">Vendor Company Name</Grid>
                  <Field
                    name="companyName"
                    as={OutlinedInput}
                    className="textfeild-company"
                    placeholder="The Company Name"
                    data-test-id="companyName"
                    onChange={props.handleChange}
                    value={props.values.companyName}
                    error={
                      props.errors.companyName && props.touched.companyName
                    }
                  />
                  {props.errors.companyName && props.touched.companyName && (
                    <FormHelperText error={true}>
                      {props.errors.companyName}
                    </FormHelperText>
                  )}
                  <Divider style={{ marginTop: "20px" }} />
                  <Grid className="textfeild-label">Mailing Address</Grid>
                  <Field
                    name="companyEmail"
                    as={OutlinedInput}
                    data-test-id="companyEmail"
                    className="textfeild-company"
                    placeholder="Mailing Address"
                    onChange={props.handleChange}
                    value={props.values.companyEmail}
                    error={
                      props.errors.companyEmail && props.touched.companyEmail
                    }
                  />
                  {props.errors.companyEmail && props.touched.companyEmail && (
                    <FormHelperText error={true}>
                      {props.errors.companyEmail}
                    </FormHelperText>
                  )}
                  <Grid style={{ marginTop: "20px" }}>
                    <button className="save-button" type="submit">
                      Save Changes
                    </button>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
          {this.state.activeButton === "Personal Data" && (
            <>
              <Formik
                initialValues={this.state.initialValuePersonalData}
                validationSchema={validationSchemaPersonalData}
                onSubmit={this.handleSubmitPersonalData}
                enableReinitialize
              >
                {(props) => (
                  <form
                    onSubmit={props.handleSubmit}
                    className="personal-data-form"
                  >
                    <Grid item className="company-data-container">
                      <Grid
                        className="company-data-title"
                        data-test-id="w9-file-title"
                      >
                        Personal Data
                      </Grid>
                      <Divider
                        style={{ marginTop: "20px", marginBottom: "15px" }}
                      />
                      <Grid container spacing={2}>
                        <Grid item sm={2}>
                          <Grid className="textfeild-label">Prefix</Grid>
                          <Select
                            id="prefix"
                            name="prefix"
                            variant="outlined"
                            value={props.values.prefix}
                            onChange={props.handleChange}
                            placeholder="Prefix"
                            style={{ width: "100%", height: "56px" }}
                          >
                            <MenuItem value="mr">Mr</MenuItem>
                            <MenuItem value="mrs">Mrs</MenuItem>
                            <MenuItem value="ms">Ms</MenuItem>
                            <MenuItem value="dr">Dr</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item sm={5}>
                          <Grid className="textfeild-label">Name</Grid>
                          <Field
                            id="first_name"
                            name="first_name"
                            as={OutlinedInput}
                            fullWidth
                            value={props.values.first_name}
                            placeholder="First Name"
                            onChange={props.handleChange}
                            error={
                              Boolean(props.errors.first_name) &&
                              props.touched.first_name
                            }
                            data-test-id="first_name"
                          />
                          <FormHelperText error={true}>
                            {props.errors.first_name}
                          </FormHelperText>
                        </Grid>
                        <Grid item sm={5}>
                          <Grid className="textfeild-label">Last Name</Grid>
                          <OutlinedInput
                            id="lastName"
                            name="last_name"
                            fullWidth
                            placeholder="Last Name"
                            value={props.values.last_name}
                            onChange={props.handleChange}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Grid className="textfeild-label">Email</Grid>
                          <OutlinedInput
                            id="email"
                            name="email"
                            fullWidth
                            value={props.values.email}
                            placeholder="Email"
                            readOnly
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Grid className="textfeild-label">Phone No</Grid>
                          <Field
                            id="phone"
                            as={OutlinedInput}
                            name="phone_number"
                            fullWidth
                            placeholder="Phone Number"
                            value={props.values.phone_number}
                            onChange={props.handleChange}
                            error={
                              Boolean(props.errors.phone_number) &&
                              props.touched.phone_number
                            }
                            inputProps={{ maxLength: 10 }}
                          />
                          <FormHelperText error={true}>
                            {props.errors.phone_number}
                          </FormHelperText>
                        </Grid>
                        <Grid item sm={12}>
                          <button
                            type="submit"
                            data-test-id="personal-data-button"
                            className="save-button"
                          >
                            Save Changes
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
              <Grid item className="company-data-container">
                <form
                  onSubmit={this.handleSubmitPassword}
                  data-test-id="reset-passwrod-form"
                >
                  <Grid
                    className="company-data-title"
                    data-test-id="w9-file-title"
                  >
                    Change Password
                  </Grid>
                  <Divider
                    style={{ marginTop: "20px", marginBottom: "15px" }}
                  />
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Grid className="textfeild-label">Current Password</Grid>
                      <OutlinedInput
                        name="currentPassword"
                        type="password"
                        value={this.state.currentPassword}
                        onChange={this.handleInputChange}
                        style={{ width: "50%" }}
                        error={!!this.state.passwordErrors.currentPassword}
                        placeholder="Enter Current Password"
                        data-test-id="current-password"
                      />
                      {this.state.passwordErrors.currentPassword && (
                        <Grid item className="errorStyle">
                          {this.state.passwordErrors.currentPassword}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <Grid className="textfeild-label">New Password</Grid>
                      <OutlinedInput
                        name="newPassword"
                        type="password"
                        value={this.state.newPassword}
                        onChange={this.handleInputChange}
                        style={{ width: "50%" }}
                        error={!!this.state.passwordErrors.newPassword}
                        placeholder="Enter New Password"
                        data-test-id="new-password"
                      />
                      {this.state.passwordErrors.newPassword && (
                        <Grid item className="errorStyle">
                          {this.state.passwordErrors.newPassword}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <Grid className="textfeild-label">
                        Retype New Password
                      </Grid>
                      <OutlinedInput
                        name="confirmPassword"
                        type="password"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                        style={{ width: "50%" }}
                        error={!!this.state.passwordErrors.confirmPassword}
                        placeholder="Enter Confirm Password"
                        data-test-id="confirm-password"
                      />
                      {this.state.passwordErrors.confirmPassword && (
                        <Grid item className="errorStyle">
                          {this.state.passwordErrors.confirmPassword}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <button
                        type="submit"
                        data-test-id="reset-password-button"
                        className="save-button"
                      >
                        Reset Password
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </>
          )}
          {this.state.activeButton === "Payout Account" && (
            <Grid item className="company-data-container">
              <Grid className="company-data-title">Payout Account</Grid>
              <Grid className="textfeild-label">Bank Account Number</Grid>
              <MaskedInput
                value={this.state.account_number}
                isPassword={
                  this.state.account_number === "None Provided" ? false : true
                }
                name="accountNumber"
              />
              <Grid className="textfeild-label">Routing Number</Grid>
              <MaskedInput
                value={this.state.routing_number}
                isPassword={
                  this.state.routing_number === "None Provided" ? false : true
                }
                name="routingNumber"
              />
              <Grid className="textfeild-label">Account type</Grid>
              <OutlinedInput
                value={this.state.account_type}
                readOnly
                style={{ width: "530px" }}
              />
            </Grid>
          )}
          {this.state.activeButton === "Documents" && (
            <Grid container>
              <Grid item className="company-data-container">
                <Grid
                  className="company-data-title"
                  data-test-id="w9-file-title"
                >
                  W9
                </Grid>
                <Grid container className="pdf-container">
                  <Grid item xs={12} sm={1} className="round-background">
                    <img src={UploadFileIcon} alt="Upload" />
                  </Grid>
                  <Grid item sm={8} xs={12} className="name-container">
                    <span className="select-title">
                      Select a file or drag and drop here
                    </span>
                    <Grid className="select-sub-title">Upload W9 PDF here</Grid>
                  </Grid>
                  <Grid
                    className="file-upload-button-wrapper"
                    item
                    sm={3}
                    xs={12}
                  >
                    <button className="select-file-button">Select File</button>
                    <input
                      type="file"
                      className="file-input"
                      data-test-id="w9files-input"
                      onChange={(event) => this.handleFileChange(event, true)}
                    />
                  </Grid>
                </Grid>
                {this.state.errorw9Message && (
                  <p className="pdfErrorMessage">{this.state.errorw9Message}</p>
                )}
                {this.state.w9File &&
                   <Grid container className="pdf-box">
                   <Grid item sm={1} xs={2}>
                     <img src={FileIcon} />
                   </Grid>
                   <Grid item sm={10} xs={8}>
                     <span>
                       {this.state.w9File.split("/").pop()}
                     </span>
                   </Grid>
                   <Grid item sm={1} xs={2}>
                     <Typography
                       component="a"
                       href={this.state.w9File}
                       style={{
                         border: "none",
                       }}
                       target="blank"
                     >
                       <img src={DownLoadIcon} />
                     </Typography>
                   </Grid>
                 </Grid>
                }
              </Grid>
              <Grid item className="company-data-container document-container">
                <Grid className="company-data-title">Sole Source Letter</Grid>
                <Grid container className="pdf-container">
                <Grid item xs={12} sm={1} className="round-background">
                    <img src={UploadFileIcon} alt="Upload" />
                  </Grid>
                  <Grid item sm={8} xs={12} className="name-container">
                    <span className="select-title">
                      Select a file or drag and drop here
                    </span>
                    <Grid className="select-sub-title">Upload W9 PDF here</Grid>
                  </Grid>
                  <Grid
                    className="file-upload-button-wrapper"
                    item
                    sm={3}
                    xs={12}
                  >
                    <button className="select-file-button">Select File</button>
                    <input
                      type="file"
                      className="file-input"
                      data-test-id="files-input"
                      onChange={(event) => {
                        this.handleFileChange(event, false);
                      }}
                    />
                  </Grid>
                </Grid>
                {this.state.errorsoleSourceMessage && (
                  <p className="pdfErrorMessage">
                    {this.state.errorsoleSourceMessage}
                  </p>
                )}
                {this.state.soleFile &&
                 <Grid container className="pdf-box">
                 <Grid item sm={1} xs={2}>
                   <img src={FileIcon} />
                 </Grid>
                 <Grid item sm={10} xs={8}>
                   <span>
                     {this.state.soleFile.split("/").pop()}
                   </span>
                 </Grid>
                 <Grid item sm={1} xs={2}>
                   <Typography
                     component="a"
                     href={this.state.soleFile}
                     style={{
                       border: "none",
                     }}
                     target="blank"
                   >
                     <img src={DownLoadIcon} />
                   </Typography>
                 </Grid>
               </Grid>
                }
               
              </Grid>
            </Grid>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={this.state.showSnackbar}
            autoHideDuration={5000}
            data-test-id="sanke-bar"
            onClose={this.handleCloseSnackbar}
          >
            <MuiAlert
              onClose={this.handleCloseSnackbar}
              severity={this.state.severity}
            >
              {this.state.message}
            </MuiAlert>
          </Snackbar>
          <LoaderComponent open={this.state.loading} />
        </Grid>
      </ThemeProvider>
    );

    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
