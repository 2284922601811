// Customizable Area Start
export const MySchool = require("../assets/MySchool.png");
export const UploadIcon = require("../assets/uploadIcon.svg");
export const SchoolIcon = require("../assets/schoolIcon.svg");
export const NgssIcon = require("../assets/Ngss.png");
export const SkillIcon = require("../assets/Skills.png");
export const Professtional = require("../assets/Professtional.png");
export const Alabama = require("../assets/Albama.png");





// Customizable Area End