import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  CardContent,
  CardActions,
  Grid,
  TextField,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import moment from "moment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AdminConsoleController, {
  Props,
  configJSON,
} from "./AdminConsoleController";
import { FormComponent } from "./components/FormComponent";

export default class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  today = new Date();

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.navBar}>
          <Typography variant="h4">Admin</Typography>
        </Box>

        <Box style={{ marginLeft: "1%" }}>
          <TabContext value={this.state.value}>
            <Box>
              <TabList
                data-test-id="txtInput"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#150cf0",
                  },
                }}
                onChange={(e: any) =>
                  this.handleChange(this.state.value === "1" ? "2" : "1")
                }
                aria-label="lab API tabs example"
                data-testId="tableBarInpComponent"
              >
                <Tab label="All Products" value="1" />
                <Tab
                  label="Add Product"
                  className="courseAssesment-btn"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Dialog
                open={this.state.dialog}
                onClose={() => this.setState({ dialog: !this.state.dialog })}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogContent>
                  <DialogContentText>
                    <Box padding={"1%"}>
                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductManufacturer"}
                        value={this.state.updateFormValue.name}
                        name="name"
                        label="Product Name"
                        placeholder="Enter Product Name"
                        variant="standard"
                        fullWidth
                        required
                      />

                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductManufacturer"}
                        value={this.state.updateFormValue.category_id}
                        name="category_id"
                        label="Category"
                        placeholder="Enter Category"
                        variant="standard"
                        fullWidth
                        required
                      />

                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductManufacturer"}
                        value={this.state.updateFormValue.manufacture_date}
                        name="manufacture_date"
                        placeholder="Enter manufacture Date "
                        variant="standard"
                        label="Manufacture Date"
                        fullWidth
                        required
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductManufacturer"}
                        value={this.state.updateFormValue?.expiry_date}
                        defaultValue={this.state.updateFormValue?.expiry_date}
                        name="expiry_date"
                        placeholder="Enter Expiry Date "
                        variant="standard"
                        label="Expiry Date"
                        fullWidth
                        required
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductFeature"}
                        value={this.state.updateFormValue.product_feature}
                        name="product_feature"
                        label="Product description"
                        placeholder="Enter  product description "
                        variant="standard"
                        fullWidth
                        required
                        multiline
                        rows={4}
                      />

                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductFeature"}
                        value={this.state.updateFormValue.price}
                        name="price"
                        label="Product price"
                        type="number"
                        placeholder="Enter  product price "
                        variant="standard"
                        fullWidth
                        required
                      />

                      <TextField
                        onChange={this.handleChangeUpdate}
                        data-test-id={"txtInputProductFeature"}
                        value={this.state.updateFormValue.numStock}
                        name="numStock"
                        type="number"
                        label="Stock"
                        placeholder="Stocks"
                        variant="standard"
                        fullWidth
                        required
                      />

                      <Button
                        style={{
                          backgroundColor: "#150cf0",
                          color: "white",
                          marginTop: "1%",
                        }}
                        variant="contained"
                        fullWidth
                        onClick={() => this.handleUpdate()}
                        data-test-id={"btnAddExample"}
                        data-testId="handleUpdateBTNComponent"
                      >
                        Submit
                      </Button>

                      <Button
                        style={{
                          marginTop: "1%",
                        }}
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                          this.setState({ dialog: !this.state.dialog })
                        }
                        data-test-id={"btnAddExample"}
                        data-testId="handleUpdateBTNComponent"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>

              <Grid container spacing={2}>
                {this.state.productData.map((element: any, index: number) => {

                  return (
                    <Grid
                      item
                      key={element?.attributes.id}
                      xs={12}
                      md={4}
                      sm={6}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CardContent>
                        <Typography style={{ fontSize: 14 }} gutterBottom>
                          Product Id : {element?.attributes.id}
                        </Typography>
                        <Typography variant="h5" component="div" />

                        <Typography style={{ marginBottom: 1.5 }}>
                          Product Name : {element?.attributes.name}
                        </Typography>
                        <Typography variant="body2">
                          Stock: {element?.attributes.stock}
                          <br />
                        </Typography>
                        <Typography variant="body2">
                          Product Description : {element?.attributes.product_feature}
                          <br />
                        </Typography>
                        <Typography variant="body2">
                          Product price : {element?.attributes.price}
                          <br />
                        </Typography>
                        <Typography variant="body2">
                          Product Category : {element?.attributes.category_name}
                          <br />
                        </Typography>
                        <Typography variant="body2">
                          Manufacture Date :{" "}
                          {moment(element?.attributes.manufacture_date).format(
                            "MM/DD/YYYY"
                          )}
                          <br />
                        </Typography>
                        <Typography variant="body2">
                          Expiry Date  :{" "}
                          {moment(element?.attributes.expiry_date).format(
                            "MM/DD/YYYY"
                          )}
                          <br />
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => this.handleDialog(element)}
                          data-testId="handleDialogBTNComponent"
                        >
                          Update
                        </Button>
                        <Button
                          data-test-id="btnAddExample"
                          size="small"
                          onClick={() => this.handleDelete(element.id)}
                        >
                          Delete
                        </Button>
                      </CardActions>
                    </Grid>
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <FormComponent
                handleChangeInput={this.handleChangeInput}
                state={this.state}
                handleSubmit={this.handleSubmit}
              ></FormComponent>
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  navBar: {
    justifyContent: "space-between",
    display: "flex",
    height: 50,
    width: "100%",
    marginTop: "2%",
  },
};
// Customizable Area End
