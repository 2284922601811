import React from "react";
import { Grid, Switch } from "@material-ui/core";
import { addS } from "../../../../components/src/NativeWebRouteWrapper/Utils";

export const Left = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      id="left-svg-btn"
      d="M15 6L9 12L15 18"
      stroke="#98A2B3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const Right = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      id="left-svg-btn"
      d="M9 18L15 12L9 6"
      stroke="#98A2B3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const onScrollEndRight = (callback: () => true) => {
  let element: any = document.getElementById("cards-container-standards") || {};

  let p =
    Math.ceil(element?.scrollWidth -  Math.ceil(element?.scrollLeft)) === element?.clientWidth;
  if (p) callback();
};
export const scrollRight = () => {
  let element: any = document.getElementById("cards-container-standards") || {};
  element.scrollLeft = element?.scrollWidth;
};
export const scrollLeft = () => {
  let element: any = document.getElementById("cards-container-standards") || {};
  element.scrollLeft = 0;
};
const StandardsComponent = ({ state, getStatus, updateStateOfStandard, fetchMoreData }: any) => {
  return (
    <Grid sm={12} className="generel-detail-contaner" item container>
      <Grid item sm={12} className="title-container">
        <span className="general-title">Standards</span>
      </Grid>
      <Grid item sm={12} className="standards-header-container">
        <span className="title-standard-card">My Standard Set{addS(state.standardSetsNumber)} <span className="count-div">{state.standardSetsNumber}</span></span>
        <div className="btn-container-pagination">
          <div className="aero-btn" onClick={scrollLeft}>
            <Left />
          </div>
          &nbsp;&nbsp;
          <div className="aero-btn" onClick={scrollRight}>
            <Right />
          </div>
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        className="cards-container-standards"
        id="cards-container-standards"
        onScroll={() => onScrollEndRight(fetchMoreData)}
      >
        {state.standardsData.map((item: any) => {
          return (
            <div className="card-standard-profile" key={item.id}>
              <div className="header-card-standard-profile">
                <img
                  src={item.attributes.logo.url}
                  className="card-profile-image"
                  alt="profile"
                />
                <div
                  className={
                    item.attributes.account_added ? "selected" : "unselected"
                  }
                >
                  <Switch
                    id="switch-btn"
                    checked={getStatus(item)}
                    onChange={() => updateStateOfStandard(item)}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </div>
              <span
                className={
                  item.attributes.account_added
                    ? "standard-title-1"
                    : "standard-title-0"
                }
              >
                {item.attributes.title}
              </span>
              <div className="tag-container">
                {item.attributes.standards_count}{" "}
                {item.attributes.standards_count <= 1
                  ? "Standard"
                  : "Standards"}
              </div>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default StandardsComponent;
