// Customizable Area Start
import React from "react";
import { Grid, Button } from "@material-ui/core";
export const configJSON = require("./config");
import AddCreditsController, { Props } from "./AddCreditsController";
import {
  onBtnImage,
  downloadImg,
  arrowImg,
} from "./assets";
import TableComponent from "./components/TableComponent";
import "../assets/addcredits.scss";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import {
  getCommaSeperator,
  getCredits,
  getUserDetails,
  managerNameFirstName,
  managerNamelastName,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class AddCredits extends AddCreditsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Grid container className="addcredit-container">
        <Grid sm={12} item container className="card0">
          <Grid sm={6} item className="left">
            <span className="desc">Credit Packages</span>
          </Grid>
          <Grid sm={6} item className="right">
            <Button
              variant="contained"
              color="primary"
              className="btn-plus"
              endIcon={<img src={onBtnImage} alt="plus" />}
            >
              <a
                href="https://betaboxlearning.com/apply"
                target="_blank"
                className="btn-text apply-credit0101"
              >
                Apply for Credit Award
              </a>
            </Button>
          </Grid>
        </Grid>
        <Grid sm={12} item container className="card-item-main" spacing={3}>
          {this.state.addData.map((item: any) => {
            return (
              <Grid sm={4} item key={item}>
                <div className="card-item">
                  <img src={item.icon} alt="image" className="img" />
                  <br />
                  <span className="card-main-text">
                    {getCommaSeperator(item.credits)} {getCredits(item.credits)}
                  </span>
                  <br />
                  <span className="card-sub-text">
                    ${getCommaSeperator(item.price)}
                  </span>
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    className="card-btn-text"
                    endIcon={<img src={arrowImg} alt="plus" />}
                    onClick={() =>
                    (window.location.href =
                      "mailto:sales@betaboxlearning.com?subject=SendMail&body=Description")
                    }
                  >
                    <span className="btn-text">Select</span>
                  </Button>
                  <br />
                  <a href={item.download_quote} target="_blank" download>
                    <Button
                      className="card-btn-text1"
                      startIcon={<img src={downloadImg} alt="plus" />}
                    >
                      <span className="btn-text">Download Quote</span>
                    </Button>
                  </a>
                  {item.discount !== "" && (
                    <span className="discount">Save {item.discount}%</span>
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid sm={12} item container className="card-item-main2" spacing={3}>
          <Grid sm={8} item>
            <span className="desc">History</span>
            <br />
            <br />
            <InfiniteScroll
              dataLength={this.state.schoolCreditHistory.length}
              next={this.fetchMoreData}
              hasMore={this.state.page < this.state.pagination.total_pages}
              loader={
                <div className="more-loader">
                  <CircularProgress />
                </div>
              }
              scrollableTarget="scrollableDiv"
              height={"60vh"}
            >
              {this.state?.schoolCreditHistory?.map((element: any) => {
                return (
                  <TableComponent
                    element={element}
                    handleDownload={this.handleDownload}
                  />
                );
              })}
            </InfiniteScroll>
          </Grid>
          <Grid sm={4} item>
            <span className="desc">Have a Question?</span>
            <br />
            <br />
            <div className="image-container">
              {this.state.managerDetails?.data?.attributes
                ?.betabox_image?.url != null && (
                  <img
                    src={
                      this.state.managerDetails.data.attributes
                        .betabox_image.url
                    }
                    className="image"
                  />
                )}

              <div className="back-img">
              <br/>
                <span className="card-image-main-text">
                  {
                    managerNameFirstName(this.state.managerDetails)
                  }{" "}
                  {
                    managerNamelastName(this.state.managerDetails)
                  }
                </span>
                <br />
                <span className="card-image-sub-text">
                  Your Classbox School Success Manager
                </span>
                <br />
                <br />
                <Button
                  variant="contained"
                  className="btn-image-main"
                  endIcon={<img src={arrowImg} alt="plus" />}
                  onClick={this.openManagerMail}
                >
                  <span className="btn-img">Contact</span>
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}
// Customizable Area End
