import React from 'react'
import { Grid, InputLabel, TextField, FormHelperText } from '@material-ui/core';
import { Formik } from "formik";
import { alertInfoIcon } from  "../assets"; 
import Alert from '@material-ui/lab/Alert';

const SchoolDetailsForm = ({ state, handleSubmit }: any) => {
    return (
        <Formik enableReinitialize initialValues={state.initialValuesSchoolDetails} onSubmit={handleSubmit}>
            {
                props => <form className='school-details-form'>
                    <Grid spacing={2} className="student-detail-contaner" container>
                        <Grid className="school-heading" item sm={12}>
                            <span className="general-title">
                                My School Details
                            </span>
                            <Alert icon={<img width="20px" src={alertInfoIcon} />} className="info-msg-alert" severity="error">Сontact Classbox Manager if these need to be changed</Alert>
                        </Grid>
                        <Grid item sm={6} className="input-container">
                            <InputLabel htmlFor="schoolName" className="input-label">Name of School</InputLabel>
                            <TextField className="input-field" id="outlined-basic" variant="outlined" size="small" name="schoolName"
                                type="text"
                                placeholder="School name"
                                onChange={props.handleChange}
                                value={props.values.schoolName} 
                                disabled
                                />
                            {props.errors.schoolName && props.touched.schoolName && <FormHelperText error={true} >{props.errors.schoolName}</FormHelperText>}
                        </Grid>
                        <Grid item sm={6} className="input-container">
                            <InputLabel htmlFor="classboxLead" className="input-label">Classbox Lead</InputLabel>
                            <TextField className="input-field" id="outlined-basic" variant="outlined" size="small" name="classboxLead"
                                type="text"
                                placeholder="Classbox lead"
                                onChange={props.handleChange}
                                value={props.values.classboxLead} 
                                disabled/>
                            {props.errors.classboxLead && props.touched.classboxLead && <FormHelperText error={true} >{props.errors.classboxLead}</FormHelperText>}
                        </Grid>
                        <Grid item sm={12} className="input-container">
                            <InputLabel htmlFor="schoolAddress" className="input-label">School Address</InputLabel>
                            <TextField className="input-field" id="outlined-basic" variant="outlined" size="small" name="schoolAddress"
                                type="text"
                                placeholder="School address"
                                onChange={props.handleChange}
                                value={props.values.schoolAddress}
                                disabled />
                            {props.errors.schoolAddress && props.touched.schoolAddress && <FormHelperText error={true} >{props.errors.schoolAddress}</FormHelperText>}
                        </Grid>
                    </Grid>
                </form>
            }
        </Formik>
    )
}

export default SchoolDetailsForm