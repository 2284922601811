Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "UserGroups2";
exports.labelBodyText = "UserGroups2 Body";
exports.deleteMethod = "DELETE"
exports.getStandardsDataApiEndpoint = "bx_block_profile/standard_sets"; 
exports.setStandardsDataApiEndpoint = "bx_block_profile/account_standards";
exports.unSetStandardsDataApiEndpoint = "bx_block_profile/account_standards/";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End