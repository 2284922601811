// Customizable Area Start
import React from "react";

import {
  Container,
  Button,
  Typography,

  // Customizable Area Start
  CardContent,
  Grid,
  Card,
  TextField,

  // Customizable Area End
} from "@material-ui/core";

export const FormComponent = ({
  handleChangeInput,
  state,
  handleSubmit,
}: any) => {
  return (
    <Container className="addProductForm" maxWidth="md">
      <Card style={{ maxWidth: "70%", margin: "0 auto" }}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Add Product
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                onChange={handleChangeInput}
                data-test-id={"txtInputProductManufacturer"}
                value={state.formValue.name}
                name="name"
                label="Product Name"
                placeholder="Enter Product Name "
                variant="standard"
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={handleChangeInput}
                data-test-id={"txtInputProductManufacturer"}
                value={state.formValue.category_id}
                name="category_id"
                label="Category"
                placeholder="Enter Category"
                variant="standard"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChangeInput}
                data-test-id={"txtInputProductManufacturer"}
                value={state.formValue.manufacture_date}
                name="manufacture_date"
                label="Manufacture Date"
                placeholder="Enter Manufacture Date "
                variant="standard"
                fullWidth
                required
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChangeInput}
                data-test-id={"txtInputProductManufacturer"}
                value={state.formValue.expiry_date}
                name="expiry_date"
                label="Expiry Date"
                placeholder="Enter Expiry Date "
                variant="standard"
                fullWidth
                required
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <TextField
              onChange={handleChangeInput}
              data-test-id={"txtInputProductFeature"}
              value={state.formValue.price}
              name="price"
              type="number"
              label="Product price"
              placeholder="Enter  product price "
              variant="standard"
              fullWidth
              required
            />

            <TextField
              onChange={handleChangeInput}
              data-test-id={"txtInputProductFeature"}
              value={state.formValue.numStock}
              type="number"
              name="numStock"
              label="Number of Stock"
              placeholder="Number of Stocks"
              variant="standard"
              fullWidth
              required
            />

            <Grid item xs={12}>
              <TextField
                onChange={handleChangeInput}
                data-test-id={"txtInputProductFeature"}
                value={state.formValue.product_feature}
                name="product_feature"
                label="Product Description"
                placeholder="Enter  product description "
                variant="standard"
                fullWidth
                required
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#150cf0", color: "white" }}
                variant="contained"
                fullWidth
                onClick={() => handleSubmit()}
                data-test-id={"btnAddExample"}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};
// Customizable Area End
