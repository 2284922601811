import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  handleTokenError,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  dialogOpen: boolean;
  // Customizable Area Start
  teacherDetails: any;
  leadMail: any;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InviteTeacherController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addTeacherApiCallId: any;
  getAllIvitedUserApiCallId: any;
  deleteApiCallId: any;
  sendInviteCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      dialogOpen: false,
      // Customizable Area Start
      teacherDetails: [],
      leadMail: "",
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (isTeacher()) {
      this.props.navigation.goBack();
    }
    this.getAllInvitedList();
    this.setLeadMail();
  }

  setLeadMail = () => {
    let userMail = getUserDetails().email;
    let domain = userMail.replace(/.*@/, "");
    this.setState({ leadMail: domain });
    return true;
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      this.setState({
        loading: false,
      });

      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.addTeacherApiCallId
      ) {
        this.getAllInvitedList();
      }
      if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.addTeacherApiCallId
      ) {
        this.setState({
          showSnackbar: true,
          severity: "error",
          message: responseJson.message,
        });
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.getAllIvitedUserApiCallId
      ) {
        this.setState({ teacherDetails: responseJson.data || [] });
      }

      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.deleteApiCallId
      ) {
        this.getAllInvitedList();
      }

      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.sendInviteCallId
      ) {
        this.props.navigation.navigate("StandardSelection", { isLead: "true" });
      }

      if (responseJson && responseJson.errors) {
        this.setState({
          showSnackbar: true,
          severity: "error",
          message: "Something Went Wrong!",
        });
      }
    }
    // Customizable Area End
  }

  // web events

  handleDialog = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  onSubmitAddTeacher = (value: any) => {
    this.handleDialog();
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({
      loading: true,
    });

    let datas = {
      data: {
        type: "email_account",
        attributes: {
          first_name: value.firstName,
          last_name: value.lastName,
          email: value.email,
          role_id: "4",
          admin_create: true,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addTeacherApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addUserEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllInvitedList = () => {
    const token = getAuthToken();
    const header = {
      token: token,
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllIvitedUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllUserEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sendInvitesToTechers = () => {
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendInviteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendInvites}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDelete = (id: any) => {
    const token = getAuthToken();
    const header = {
      token: token,
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validateApiMethodDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area Start
  // Customizable Area End
}
