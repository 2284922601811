// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import TrainingDetailsController, { Props } from "./TrainingDetailsController";
import TrainingDeatilsHeader from "./components/TrainingDeatilsHeader";
import TrainingSideBar from "./components/TrainingSideBar";
import TrainingDetailsMain from "./components/TrainingDetailsMain";
import "../assets/training.scss";
import { Divider } from "react-native-elements";
export default class TrainingDetails extends TrainingDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container spacing={3} className="training-details">
        <Grid item sm={3} className="sidebar">
          <TrainingSideBar
            state={this.state}
            navigation={this.props.navigation}
            getStepsDataWithTraining={this.getStepsDataWithTraining}
          />
        </Grid>
        <Grid item sm={9} className="main">
          <TrainingDeatilsHeader
            state={this.state}
            getButtonTitle={this.getButtonTitle}
            updateStepStatus={this.updateStepStatus}
          />
          <Divider />
          <TrainingDetailsMain
            state={this.state}
            getButtonTitle={this.getButtonTitle}
            showPrevious={this.showPrevious}
            updateStepStatus={this.updateStepStatus}
            getStepsDataWithTraining={this.getStepsDataWithTraining}
            getPreviousStepId={this.getPreviousStepId}
          />
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} state={this.state} />
      </Grid>
    );
  }
}
// Customizable Area End
