// Customizable Area Start
import React from "react";
import { Grid, Divider } from "@material-ui/core";
import ProjectController, { Props } from "./ProjectController";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import { crossIcon, filterIcon } from "../../ContentManagement/src/assets";
import ProjectCards from "./components/ProjectCards";
import ProjectDetails from "./components/ProjectDetails";
import PopoverComponent from "./components/PopOver";
// @ts-ignore
import { StandardEmpty } from "../../../components/src/NativeWebRouteWrapper/StandardEmpty"
import "../assets/index.scss";

class Project extends ProjectController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container spacing={5} className="classboxes-container">
        <Grid item container sm={7} className="left-container1">
          <Grid item sm={12} className="header">
            <span className="title">🔎 Projects</span>
          </Grid>
          <div className="relative-grid-class">
            {this.state.openFilter && (
              <PopoverComponent
                storeIdsOfCheckedStandard={this.storeIdsOfCheckedStandard}
                selectAllStandard={this.selectAllStandard}
                setStandardFilter={this.setStandardFilter}
                handleCloseModal={this.handleCloseModal}
                state={this.state}
                handletab1Checkbox={this.handletab1Checkbox}
                handletab0Checkbox={this.handletab0Checkbox}
                handleChangeV1={this.handleChangeV1}
                handleChangeV2={this.handleChangeV2}
                handleCompareChange={this.handleCompareChange}
                handleChangeTab={this.handleChangeTab}
                handleGrades={this.handleGrades}
                handleSelectSubject={this.handleSelectSubject}
              />
            )}
            <Grid item sm={12} className="filter-container">
              <div className="filter pointer">
                <img src={filterIcon} alt="icon" />
                &nbsp;
                <span
                  className="title"
                  id="open-filter-btn"
                  onClick={this.setFilterOpen}
                >
                  Project Filters
                </span>
              </div>
              <>
                <Divider orientation="vertical" />
                <div>
                  {this.state.filterSelected ? (
                    <span className="title1">Applied filters:</span>
                  ) : (
                    <span className="title1">No filters applied</span>
                  )}
                </div>
              </>
            </Grid>
            {(this.state.tab0Checkboxes.classBoxNeeded ||
              this.state.tab0Checkboxes.noClassBoxNeeded ||
              this.state.tab2show !== "" ||
              this.state.tab1show.length > 0 ||
              this.state.gradeForShow !== "" ||
              this.state.showStandard.length > 0 ||
              this.state.selectedSubjects.length > 0) && (
                <Grid
                  item
                  container
                  sm={12}
                  className="filters-collection"
                  spacing={3}
                >
                  {(this.state.tab0Checkboxes.classBoxNeeded ||
                    this.state.tab0Checkboxes.noClassBoxNeeded) && (
                      <Grid item>
                        <div className="filter-container1">
                          <span className="txt">
                            Classboxes:{" "}
                            {`${this.state.tab0Checkboxes.classBoxNeeded}`}{" "}
                            &nbsp;&nbsp;{" "}
                            <img
                              className="cross-icon"
                              src={crossIcon}
                              alt="cancle"
                              onClick={this.resetFilterClassBoxes}
                            />
                          </span>
                        </div>
                      </Grid>
                    )}
                  {this.state.tab2show !== "" && (
                    <Grid item>
                      <div className="filter-container1">
                        <span className="txt">
                          Duration: {this.state.tab2show} &nbsp;&nbsp;{" "}
                          <img
                            src={crossIcon}
                            className="cross-icon"
                            alt="cancle"
                            onClick={this.resetFilterDuration}
                          />
                        </span>
                      </div>
                    </Grid>
                  )}
                  {this.state.selectedSubjects.length > 0 && (
                    <Grid item>
                      <div className="filter-container1">
                        <span className="txt">
                          Subjects: {this.state.selectedSubjects.join(", ")}{" "}
                          &nbsp;&nbsp;{" "}
                          <img
                            className="cross-icon"
                            src={crossIcon}
                            alt="cancle"
                            onClick={this.resetFilterSubjects}
                          />
                        </span>
                      </div>
                    </Grid>
                  )}
                  {this.state.showStandard.length > 0 && (
                    <Grid item>
                      <div className="filter-container1">
                        <span className="txt">
                          Standards: {this.state.showStandard.join(", ")}{" "}
                          &nbsp;&nbsp;{" "}
                          <img
                            className="cross-icon"
                            src={crossIcon}
                            alt="cancle"
                            onClick={this.clearAllStandards}
                          />
                        </span>
                      </div>
                    </Grid>
                  )}
                  {this.state.gradeForShow !== "" && (
                    <Grid item>
                      <div className="filter-container1">
                        <span className="txt">
                          Grades: {this.state.gradeForShow} &nbsp;&nbsp;{" "}
                          <img
                            className="cross-icon"
                            src={crossIcon}
                            alt="cancle"
                            onClick={this.resetGradeForShow}
                          />
                        </span>
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
            <Grid
              item
              container
              sm={12}
              className="filters-collection"
              spacing={3}
            />
            <ProjectCards
              state={this.state}
              handleLeftCard={this.handleLeftCard}
            />
          </div>
        </Grid>
        {this.state.setSlectedCard !== null && <ProjectDetails state={this.state} navigation={this.props.navigation} addProjectPlan={this.addProjectPlan} />}
        <LoaderComponent open={this.state.loading} />
        {this.state.empty && <StandardEmpty />}

      </Grid>
      // Customizable Area End
    );
  }
}

export default Project;
// Customizable Area End
