import React from "react";
import { Grid } from "@material-ui/core";
import {
  backCardImage,
  rightIcon,
  rightIconOrange,
  warningIcon,
  warningIconOrange,
} from "../assets";
import Moment from "moment";
import {
  filtereredDataOfNotification,
  getClassNameCard,
  shouldShowCard,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";

const NotificationCard = ({ state, handleCheckOut, navigation }: any) => {
  return (
    <>
      {filtereredDataOfNotification(state.purchasedClassboxes).map((item: any) => {
        return (
          item &&
          shouldShowCard(item) && (
            <Grid item sm={12} className="notification-container">
              <div className={getClassNameCard(item.order_details.status)}>
                <img
                  src={backCardImage}
                  className={
                    item.order_details.status !== "completed"
                      ? "back-image"
                      : "back-image br-16px"
                  }
                  alt="back-image"
                />
                <div
                  className={
                    item.order_details.status !== "completed"
                      ? "content-container"
                      : "content-container01"
                  }
                  id="classbox-card-click"
                  onClick={() => handleCheckOut(item)}
                >
                  <div className="card1-content">
                    <span className="date-txt">
                      Due Date:{" "}
                      {Moment(item.order_details.end_date).format("DD-MM-YYYY")}
                    </span>
                    <span className="lead-txt">{item.title}</span>
                    <span className="date-txt">
                      If you’d like to make changes to this Checkout, click this
                      card.
                    </span>
                  </div>
                  <img
                    src={item.emoji?.url}
                    alt="icon"
                    className={
                      item.order_details.status !== "completed" ? "icon1" : "icon"
                    }
                  />
                </div>
                {item.order_details.status !== "completed" && (
                  <div className="warn">
                    <div className="straight">
                      <img
                        src={
                          item.order_details.status === "past_due"
                            ? warningIcon
                            : warningIconOrange
                        }
                        alt="icon"
                        className="mb-1"
                      />
                      &nbsp;&nbsp;
                      <span
                        className={
                          item.order_details.status === "past_due"
                            ? "title"
                            : "title color-noti-organge"
                        }
                      >
                        {item.order_details.status === "past_due"
                          ? "This Checkout is past due"
                          : "This Checkout is Due Soon"}
                      </span>
                    </div>
                    <div className="straight">
                      <span
                        id="handle-checkout"
                        className={
                          item.order_details.status === "past_due"
                            ? "title1"
                            : "title1 color-noti-organge"
                        }
                        onClick={() => handleCheckOut(item)}
                      >
                        Manage Checkout
                        <img
                          src={
                            item.order_details.status === "past_due"
                              ? rightIcon
                              : rightIconOrange
                          }
                          alt="icon"
                          className="icon-ero mb-1"
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          )
        );
      })}
    </>
  );
};

export default NotificationCard;
