import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AccordionDetails, Grid, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { completed, remaining, right, sideBarHeader } from "../assets";
import ProgressBarComponent from "./ProgressBarComponent";
import { getClassNameForStep, getTrimTitle } from "../../../../components/src/NativeWebRouteWrapper/Utils";

const TrainingSideBar = ({
  state,
  getStepsDataWithTraining,
  navigation,
}: any) => {
  return (
    <>
      <Grid container className="sidebar1">
        <Grid item className="header">
          <img src={sideBarHeader} alt="back-img" className="header-image" />
          <img
            src={state.trainingDetails?.training_data?.training_emoji}
            className="icon-sidebar-001"
            alt="title-icon"
          />
          {state?.trainingDetails?.training_data?.projects && (
            <div className="header-title">
              <ProgressBarComponent
                value={
                  state?.trainingDetails?.training_data
                    ?.training_status_calculation
                }
                title={state.trainingDetails.training_data.title}
                className="progress-bar"
              />
            </div>
          )}
        </Grid>
        <div className="accordian-container-1">
          {state?.trainingDetails?.training_data?.projects.map(
            (item: {
              chapters: [];
              course_project_id: string;
              status: string;
              title: string;
            }) => (
              <>
                <Grid className="accordian-container">
                  <Accordion key={item.course_project_id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="title-project-overview">
                        {item.title}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details steps-container">
                      {item.chapters.map((item: any, index: number) => (
                        <div
                          className={getClassNameForStep(state, item.chapter_id)}
                          key={item.chapter_id}
                          onClick={() =>
                            getStepsDataWithTraining(item.chapter_id)
                          }
                        >
                          <img
                            src={
                              item.status === "not_started"
                                ? remaining
                                : completed
                            }
                            alt="iconn"
                          />
                          <span className="title">
                            &nbsp;&nbsp;{index + 1}:&nbsp;
                          </span>
                          <span className="title">
                            {getTrimTitle(40, item.title)}
                          </span>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Divider className="divider" />
              </>
            )
          )}
        </div>
      </Grid>
      {state?.trainingDetails?.training_data?.projects && (
        <div className="footer-card">
          <div className="first">
            <span className="title">Feeling overwhelmed?</span>
          </div>
          <div
            className="second"
            onClick={() => navigation.navigate("Support")}
          >
            <span className="title">
              Support&nbsp;
              <img src={right} alt="iconn" />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainingSideBar;
