import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import {
  getAllocatedData,
  getCommaSeperator,
  getCredits,
  getTeacherName,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#FFFFFF",
      boxShadow: "0 0 3px #e1dede",
      borderRadius: "10px",
    },
    media: {
      height: 0,
      paddingTop: "56.25%",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
    header: {
      height: "71px",
    },
    color: {
      backgroundColor: "#D2EFFF",
      width: "2px",
    },
    subHeader: {
      color: "#007DC6",
    },
  })
);

export default function TeacherCard({ data, index }: any) {
  const classes = useStyles();
  let userDetails = data;
  let usedCredit = userDetails.spent;
  let earnedCredit = getAllocatedData(userDetails.unusedCredit)
  let allotedCredit = getAllocatedData(userDetails.allocated)
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            src={userDetails.image}
            style={{ height: "50px", width: "50px" }}
          ></Avatar>
        }
        title={userDetails.name}
        subheader={
          <span className="teacher-card-header-desc">
             {getTeacherName(userDetails.teacher_type)}
          </span>
        }
        className={classes.header}
      />

      <CardContent className="bg-card">
        <div className="card-content">
          <div className="item">
            <span className="number">
              {getCommaSeperator(earnedCredit)}
            </span>
            <span className="desc">Earned {getCredits(earnedCredit)}</span>
          </div>
          <Divider orientation="vertical" className={classes.color} />
          <div className="item padding-left">
            <span className="number">
              {getCommaSeperator(allotedCredit)}
            </span>
            <span className="desc">Allocated {getCredits(allotedCredit)}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
