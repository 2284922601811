import React from 'react'
import { Grid, OutlinedInput, InputAdornment, IconButton, InputLabel, Button, FormHelperText } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from "formik";
import * as Yup from 'yup';

const PasswordResetForm = ({ state, handleMouseDownPassword, handleClickShowPassword, handleSubmit }: any) => {

    const changePasswordSchema = Yup.object().shape({
        password: Yup.string().required('Current password is Required.'),
        newPassword: Yup.string().required('Password is Required.').min(8, "Your password Must Contain 8 Characters.")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z]).+$/,
                "Must Contain One Uppercase, One Lowercase."
            )
            .matches(/^(?=.*\d).+$/, "Must Contain One Number."),
        repeatePassword: Yup.string().required('Repeat Password is required.')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
    })

    return (
        <Formik initialValues={state.initialValuesChangePassword} onSubmit={handleSubmit} validationSchema={changePasswordSchema}>
            {
                props => <form onSubmit={props.handleSubmit} className="password-reset-form">
                    <Grid item sm={12} className="generel-detail-contaner" container>
                        <Grid item sm={12}>
                            <span className="general-title">
                                Change Password
                            </span>
                        </Grid>
                        {
                            (!!props.errors.newPassword && !!props.touched.newPassword) && (<Grid item sm={12}>
                                <div className="error-box">
                                    <span className="title">
                                        Your password doesn't match the criteria
                                    </span>
                                    <ul className="error-list">
                                        <li className="error">
                                            At least one capital letter
                                        </li>
                                        <li className="error">
                                            At least one lower case letter
                                        </li>
                                        <li className="error">At least one number</li>
                                        <li className="error">
                                            Minimum character length is 8 character
                                        </li>
                                    </ul>
                                </div>
                            </Grid>)}
                        <Grid item sm={6} className="input-container">
                            <InputLabel htmlFor="password" className="input-label">Current Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={state.showPassword0 ? 'text' : 'password'}
                                onChange={props.handleChange}
                                value={props.values.password}
                                placeholder="Current password"
                                name="password"
                                error={(!!props.errors.password && !!props.touched.password) || (state.message === "Incorrect current password")}
                                endAdornment={
                                    <InputAdornment position="end" className="eye-icom">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { handleClickShowPassword("password0") }}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword0 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {((props.errors.password && props.touched.password) || (state.message === "Incorrect current password")) && <FormHelperText error={true} >{props.errors.password || state.message}</FormHelperText>}
                        </Grid>
                        <Grid item sm={6} className="input-container">
                            <InputLabel htmlFor="newPassword" className="input-label">New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={state.showPassword1 ? 'text' : 'password'}
                                placeholder="New password"
                                onChange={props.handleChange}
                                value={props.values.newPassword}
                                name="newPassword"
                                error={(!!props.errors.newPassword && !!props.touched.newPassword)}
                                endAdornment={
                                    <InputAdornment position="end" className="eye-icom">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { handleClickShowPassword("password1") }}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                             {(props.errors.newPassword && props.touched.newPassword) && <FormHelperText error={true} >{props.errors.newPassword}</FormHelperText>}
                        </Grid>
                        <Grid item sm={6} className="input-container">
                            <InputLabel htmlFor="repeatePassword" className="input-label">Retype New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={state.showPassword2 ? 'text' : 'password'}
                                placeholder="Repeat password"
                                onChange={props.handleChange}
                                value={props.values.repeatePassword}
                                name="repeatePassword"
                                error={(!!props.errors.repeatePassword && !!props.touched.repeatePassword)}
                                endAdornment={
                                    <InputAdornment position="end" className="eye-icom">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { handleClickShowPassword("password2") }}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {props.errors.repeatePassword && props.touched.repeatePassword && <FormHelperText error={true} >{props.errors.repeatePassword}</FormHelperText>}
                        </Grid>
                        <Grid item sm={12}>
                            <Button type="submit" variant="contained" color="primary" className="submit-btn">
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            }
        </Formik>
    )
}

export default PasswordResetForm