import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Avatar, Box, Typography, IconButton} from "@material-ui/core"
import "../../blocks/TeamBuilder/assets/confirm-dialog.scss";
import CancelIcon from "@material-ui/icons/Clear";

interface DialogProps {
  onCancle : ()=> void;
  onOk?: ()=> void;
  title : string;
  content:( string | JSX.Element)[];
  onOkText : string;
  onCancleText: string;
  open : boolean;
  handleClose: ()=> void;
  warningText?: string;
  iconUrl: string;
  userRole?: string;
  btnClass? : string;
}

export default function DraggableDialog({
  onCancle,
  onOk,
  title,
  content,
  onOkText,
  onCancleText,
  open,
  handleClose,
  warningText,
  iconUrl,
  userRole,
  btnClass
}: DialogProps) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        className="dialog-container"
      >
        <Box className="title-cross-box">
          <div>
              <DialogTitle id="dialogTitleText" className="title-text modal-title-bold">{title}</DialogTitle>
          </div>
          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText className="content-text">{content}</DialogContentText>
          {
            warningText && 
            <div className="warn-container">
              <Avatar alt="User Icon" src={iconUrl} />
              <Box className="userNameBox">
                <span className="warn-text">{warningText}</span>
                <Typography className="role-text">{userRole}</Typography>
              </Box>
            </div>
          }
        </DialogContent>
        <DialogActions className="btn-container-confirm-dialog">
          <Button data-test-id="onCloseClick"  onClick={onCancle} className="cancle-btn">
            {onCancleText}
          </Button>
          {
            onOk && 
            <button data-test-id="onOkClick" onClick={onOk} className={btnClass}>
              {onOkText}
            </button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
