// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { appLogo, backIcon } from "./assets";
import CardComponent from "./component/CardComponent";
import PurchaseClassBoxController, {
  Props,

} from "./PurchaseClassBoxController";
import ReviewOrder from "./component/ReviewOrder";
import CardDetails from "./component/CardDetails";
import "../assets/PurchaseClassBox.scss";
import SuccessCard from "./component/SuccessCard";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
export default class PurchaseClassBox extends PurchaseClassBoxController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container sm={12} item className="main-container" spacing={5}>
        <Grid className="header" sm={12}>
          <img src={appLogo} alt="logo" />
        </Grid>
        {this.state.successCardDetails || this.state.pendingOrSuccess ? (
          <Grid sm={12} className="min-h-100vh">
            <SuccessCard
              state={this.state}
              navigation={this.props.navigation}
              clearSuccessDetails={this.clearSuccessDetails}

            />
          </Grid>
        ) : (
          <>
            <Grid item sm={8} className="main-left">
              <div className="back-btn">
                <img
                  src={backIcon}
                  alt="back"
                  className="pointer"
                  onClick={() => this.toGoBack()}
                />
                <span className="header-text">Classbox Order</span>
              </div>

              {this.state.cardDetails ? (
                <CardDetails
                  classBoxDetails={this.state.purchaseDetails}
                  state={this.state}
                  goBack={this.goBack}
                />
              ) : (
                <CardComponent
                  handleAlignment={this.handleAlignment}
                  alignment={this.state.alignment}
                  state={this.state}
                  increaseQuantity={this.increaseQuantity}
                  increasePurchaseQuantity={this.increasePurchaseQuantity}
                  classBoxDetails={this.state.purchaseDetails}
                  handleClassBoxOrder={this.handleClassBoxOrder}
                  savingToOrder={this.savingToOrder}
                  setSelectedRange={this.setSelectedRange}
                  selectedRange={this.state.selectedRange}
                  months={this.state.months}
                  setMonth={this.setMonth}

                />
              )}
            </Grid>
            <Grid sm={3} className="main-right">
              <ReviewOrder
                checkedStatus={this.checkedStatus}
                handleSaveTo={this.handleSaveTo}
                getSavedOrder={this.getSavedOrder}
                state={this.state}
                handleClassBoxOrder={this.handleClassBoxOrder}
                classBoxDetails={this.state.purchaseDetails}
                alignment={this.state.alignment}
                toSave={this.state.saveTo}
                sendRequest={this.sendRequest}
                subClassBoxUnit={this.subClassBoxUnit}
                checkbox={this.checkbox}
              />
            </Grid>
          </>
        )}
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}
// Customizable Area End
