import React, { useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  InputLabel,
  TextField,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getCommaSeperator, getCredits } from "../../../../components/src/NativeWebRouteWrapper/Utils";
import { errorIcon } from "../../../EmailNotifications2/src/assets";

export const IconComponent3 = (errors: any, touched: any) => {
  return !!errors.AllocateCredit && !!touched.AllocateCredit ? (
    <img src={errorIcon} alt="icon" />
  ) : (
    <></>
  );
};
const AllocateCreditModal = ({
  state,
  handleAddCreditModal,
  onAddCredit,
}: any) => {
  const Schema = useMemo(
    () =>
      Yup.object().shape({
        AllocateCredit: Yup.number()
          .required("Field is required.")
          .max(
            state.mySchoolDetails.school_credit_details.data.attributes
              .current_balance ,
            `You have maximum ${state.mySchoolDetails.school_credit_details.data.attributes
              .current_balance 
            } to allocate from your credit pool.`
          ),
      }),
    [state]
  );

  return (
    <Dialog
      className="dialog-container-1"
      onClose={handleAddCreditModal}
      aria-labelledby="customized-dialog-title"
      open={state.openAddCreditModal}
    >
      <DialogTitle id="customized-dialog-title">
        <div className="header-main">
          <Typography
            className="main-text title-medium modal-title-bold"
            gutterBottom
          >
            Allocate Credits
          </Typography>
          <IconButton onClick={handleAddCreditModal}>
            <CancelIcon />
          </IconButton>
        </div>
        <Typography className="desc">
          These credits will be assigned to{" "}
          <span className="user-name">{state.drawerDetails.name.trim()}</span>{" "}
          from your school’s credit pool.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <form noValidate autoComplete="off" className="form-allocated">
            <Formik
              initialValues={{
                AllocateCredit: "",
              }}
              onSubmit={(values) => {
                onAddCredit(values);
              }}
              validationSchema={Schema}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form>
                    <InputLabel
                      htmlFor="my-input"
                      style={{ marginTop: "2%" }}
                      className="pop-up-input-text"
                    >
                      Add Credits
                    </InputLabel>
                    <TextField
                      className="input-label"
                      style={{ marginTop: "1%" }}
                      onChange={handleChange("AllocateCredit")}
                      data-test-id={"txtInputProductId"}
                      value={values.AllocateCredit}
                      name="AllocateCredit"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="AllocateCredit" />}
                      error={
                        !!errors.AllocateCredit && !!touched.AllocateCredit
                      }
                      required
                      InputProps={{
                        endAdornment: IconComponent3(errors, touched),
                      }}
                    />
                    <div className="footer1">
                      <span className="left">
                        {state.drawerDetails.name.trim()} updated balance:
                      </span>
                      <span className="right">
                        {getCommaSeperator(+state.drawerDetails.currentBalance +
                          +values.AllocateCredit)}{" "}
                        {getCredits(+state.drawerDetails.currentBalance + +values.AllocateCredit)}
                      </span>
                    </div>
                    <DialogActions className="dialog-actions-container">
                      <Button
                        autoFocus
                        onClick={handleAddCreditModal}
                        color="primary"
                        className="cancle-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => handleSubmit()}
                        className="add-btn-1"
                      >
                        Add Credits
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AllocateCreditModal;
