export const invite_mail = require("../assets/mail.png");
export const invite_sms = require("../assets/sms.png");
export const invite_whatsapp = require("../assets/whatsapp.png");
export const invite_friend = require("../assets/invite_friend.png");
export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const img14441703d5a937cc65a62353cbea2a59834b3da3 = require("../../global_assets/14441703d5a937cc65a62353cbea2a59834b3da3.png");
export const img734bc1ec08e7f8f448efe762ab29ec51881e2bb8 = require("../../global_assets/734bc1ec08e7f8f448efe762ab29ec51881e2bb8.png");
export const imga6a12f9de0eabddd474cb357fc50c96cb3a1ad4d = require("../../global_assets/a6a12f9de0eabddd474cb357fc50c96cb3a1ad4d.png");
export const imgc0bd80fe52bdf3959f1f3661373dc2420085fb99 = require("../../global_assets/c0bd80fe52bdf3959f1f3661373dc2420085fb99.png");
export const imga8e05f18b254f98a6d2806bb2dd30ae5a21c89d5 = require("../../global_assets/a8e05f18b254f98a6d2806bb2dd30ae5a21c89d5.png");
export const imgb8f5ae1b903b3d44ba612e3e9b49fea6395c955d = require("../../global_assets/b8f5ae1b903b3d44ba612e3e9b49fea6395c955d.png");
export const img45864218faf9136287b783ee1adf42652b42b48a = require("../../global_assets/45864218faf9136287b783ee1adf42652b42b48a.png");
export const imge6c8598ffa2eaba0db1237da4123c4fcc5e030a2 = require("../../global_assets/e6c8598ffa2eaba0db1237da4123c4fcc5e030a2.png");
export const img92e2a4d3eece27f5d33dd9372c777f1d15603498 = require("../../global_assets/92e2a4d3eece27f5d33dd9372c777f1d15603498.png");
export const img65e24f8277df903eda6dee03b47890b440af86ac = require("../../global_assets/65e24f8277df903eda6dee03b47890b440af86ac.png");
export const img06f497f3df875f4aa8b8b95e7a161a16cd7c3ede = require("../../global_assets/06f497f3df875f4aa8b8b95e7a161a16cd7c3ede.png");
export const imga6f4e14bd6b0ce77279a8466b1f5739dd26df6f5 = require("../../global_assets/a6f4e14bd6b0ce77279a8466b1f5739dd26df6f5.png");
export const img871550f4aabd06b1ec352fec357705c79cad85f9 = require("../../global_assets/871550f4aabd06b1ec352fec357705c79cad85f9.png");
export const img3c3a10eb22f39858890b541b34866145517f6c91 = require("../../global_assets/3c3a10eb22f39858890b541b34866145517f6c91.png");
export const imge83aa4ef252161cfa6e9f7c5342bed634d7cfac2 = require("../../global_assets/e83aa4ef252161cfa6e9f7c5342bed634d7cfac2.png");
export const img69c188abcd647f3f3bf2851ae7e39ff60ea591c6 = require("../../global_assets/69c188abcd647f3f3bf2851ae7e39ff60ea591c6.png");
export const img6c87638e71d26ee43a2de8307f69987b485b6a9a = require("../../global_assets/6c87638e71d26ee43a2de8307f69987b485b6a9a.png");
export const img5b3cc318a3022a4cd0a6e700d20c81a83d484bc2 = require("../../global_assets/5b3cc318a3022a4cd0a6e700d20c81a83d484bc2.png");
export const img60237e856333886b2f46c848293e6e07c5e0ca87 = require("../../global_assets/60237e856333886b2f46c848293e6e07c5e0ca87.png");
export const img7afca663860b36355db50e67ee3ad26e11cb2bfc = require("../../global_assets/7afca663860b36355db50e67ee3ad26e11cb2bfc.png");
export const imga6752e837ca0b00c8a7f4872aed8aab91e8282d0 = require("../../global_assets/a6752e837ca0b00c8a7f4872aed8aab91e8282d0.png");
export const imge9f96baeea36dbb7e06b51db0c14547e0da67a63 = require("../assets/e9f96baeea36dbb7e06b51db0c14547e0da67a63.png");
export const imgda4333667a8127354c8e6109bbfd515619e6c4ac = require("../assets/da4333667a8127354c8e6109bbfd515619e6c4ac.png");
export const imgedf44d939975c99efa2ae43b138a8a84b9250964 = require("../assets/edf44d939975c99efa2ae43b138a8a84b9250964.png");
export const imgd8cdf2bf5148faac9cd635f420e80b74882456b8 = require("../assets/d8cdf2bf5148faac9cd635f420e80b74882456b8.png");
export const img0f14ec9af8e10e621cfdd311d0e57afe8f41ef6d = require("../assets/0f14ec9af8e10e621cfdd311d0e57afe8f41ef6d.png");
export const imgdbb31a0fb4ecd1f02d4f9817f6a4da24f597cc21 = require("../assets/dbb31a0fb4ecd1f02d4f9817f6a4da24f597cc21.png");
export const imgcfd7bdf5abae129a581fe877044de2116a434e12 = require("../assets/cfd7bdf5abae129a581fe877044de2116a434e12.png");
export const imgb5f27c7cd323a7851c9c9348aa357067b400a394 = require("../assets/b5f27c7cd323a7851c9c9348aa357067b400a394.png");
export const img1c118ca658692035298d86fc8dfd6c078d396b3c = require("../assets/1c118ca658692035298d86fc8dfd6c078d396b3c.png");
export const img269a823b17f30f5f628b310573e791b2bf4db173 = require("../assets/269a823b17f30f5f628b310573e791b2bf4db173.png");
export const img1444bc3e82be83bca407042be2647ae4b0153a5b = require("../assets/1444bc3e82be83bca407042be2647ae4b0153a5b.png");
export const imgab3d6a73f964b33e1ca7d673fd6f851fd2de0e45 = require("../assets/ab3d6a73f964b33e1ca7d673fd6f851fd2de0e45.png");
export const imga01e0bfb028ad3009131fbb7e0bd25780fec7b29 = require("../assets/a01e0bfb028ad3009131fbb7e0bd25780fec7b29.png");
export const img2a6229692386dff9b8cf4818962dedc9e2e059d2 = require("../assets/2a6229692386dff9b8cf4818962dedc9e2e059d2.png");
export const img8de6a155de7223e123aecb854b0691a3303b390d = require("../assets/8de6a155de7223e123aecb854b0691a3303b390d.png");
export const img06730324ab6a157120d84126ee7c04b7f064508b = require("../assets/06730324ab6a157120d84126ee7c04b7f064508b.png");
export const imga1ee398c3004af143c47506d43b3f9e9c2a25660 = require("../assets/a1ee398c3004af143c47506d43b3f9e9c2a25660.png");
export const img5441655c98c4e85ccd2f54e82a052c19c6962e14 = require("../assets/5441655c98c4e85ccd2f54e82a052c19c6962e14.png");
export const imgfcb85cf5b6bba1a0fcbc6fdac241c744597a4163 = require("../assets/fcb85cf5b6bba1a0fcbc6fdac241c744597a4163.png");
export const img08494b536ae18338122a8aced86ab87285a10b39 = require("../assets/08494b536ae18338122a8aced86ab87285a10b39.png");
export const img1e6327b5b0b1c4ae3d485e419e93db670118828d = require("../assets/1e6327b5b0b1c4ae3d485e419e93db670118828d.png");
export const img0448511552fab7aaf2749f72eb7f3235d9a33b7b = require("../assets/0448511552fab7aaf2749f72eb7f3235d9a33b7b.png");
export const imgf6d525e9a8b2b29f91e3ae494041d879ce3c04db = require("../assets/f6d525e9a8b2b29f91e3ae494041d879ce3c04db.png");
export const img6eae87d91fd0bd76ab90c71b3252a0725a9299ef = require("../assets/6eae87d91fd0bd76ab90c71b3252a0725a9299ef.png");
export const img53aa63f11eb94619eb262ad706a813efe7216101 = require("../assets/53aa63f11eb94619eb262ad706a813efe7216101.png");
export const imgeb3e9e3bbae34b7eab53274572c7a925b74c55b0 = require("../assets/eb3e9e3bbae34b7eab53274572c7a925b74c55b0.png");
export const imgb458c70ee900f4b9fd52e337c162c835843e4e35 = require("../assets/b458c70ee900f4b9fd52e337c162c835843e4e35.png");
export const img7aa159d5d98110c04306ed143d2fb33340013ff4 = require("../assets/7aa159d5d98110c04306ed143d2fb33340013ff4.png");
export const img741ca4eb2fcde562a1eb1c93d039fb8caa6bb6fa = require("../assets/741ca4eb2fcde562a1eb1c93d039fb8caa6bb6fa.png");
export const img099d4d031ae19d330b61a032bca7e102b5a0dc2d = require("../assets/099d4d031ae19d330b61a032bca7e102b5a0dc2d.png");
export const img7f5d3de6defdfc1b5e02ca07c1c78f0c1ddf4075 = require("../assets/7f5d3de6defdfc1b5e02ca07c1c78f0c1ddf4075.png");
export const img487d10548acf4e978c1560b294437a5b5cd3edc9 = require("../assets/487d10548acf4e978c1560b294437a5b5cd3edc9.png");
export const imgfece207e665cea5f5ba3f09276f2583345a88b6c = require("../assets/fece207e665cea5f5ba3f09276f2583345a88b6c.png");
export const img32887f779ba64345dc91d4d0d26f592538273a89 = require("../assets/32887f779ba64345dc91d4d0d26f592538273a89.png");
export const img925aeabc0fad3ebbe6a9e6394281a5129e099797 = require("../assets/925aeabc0fad3ebbe6a9e6394281a5129e099797.png");
export const img4d88ca898a3ad4d5533eafb4c691fccd0fe1b338 = require("../assets/4d88ca898a3ad4d5533eafb4c691fccd0fe1b338.png");
export const img289300c20fe62b45e17c4b4dd2ff079200c166a5 = require("../assets/289300c20fe62b45e17c4b4dd2ff079200c166a5.png");
export const imgf963bb1e25ccc7c84752233264cac9421beb16fd = require("../assets/f963bb1e25ccc7c84752233264cac9421beb16fd.png");
export const img4ad472819af459c397d4c8bd589a60aae24ad837 = require("../assets/4ad472819af459c397d4c8bd589a60aae24ad837.png");
export const img9009aef40d872c7d9aaf18b318d8585fcbb079f7 = require("../assets/9009aef40d872c7d9aaf18b318d8585fcbb079f7.png");
export const img2e2a138f7fe7bf799f33d3bea5a40c510495defa = require("../assets/2e2a138f7fe7bf799f33d3bea5a40c510495defa.png");
export const imgf285b247b5b5d1122cf6b22d0915ac16d5f49c02 = require("../assets/f285b247b5b5d1122cf6b22d0915ac16d5f49c02.png");
export const img739711c3d1044549c0f63aabb5f822588ec74b90 = require("../assets/739711c3d1044549c0f63aabb5f822588ec74b90.png");
export const img1c28c83e5de10cbbd60ddeae2372115e9e12d788 = require("../assets/1c28c83e5de10cbbd60ddeae2372115e9e12d788.png");
export const imga9abb0e495120933d951941dabb01869f468df35 = require("../assets/a9abb0e495120933d951941dabb01869f468df35.png");
export const img7e6267260596338eba5ccd050060f1c8cd177099 = require("../assets/7e6267260596338eba5ccd050060f1c8cd177099.png");
export const img2d355cfd248b564a2e612f629be5571553e6f0f3 = require("../assets/2d355cfd248b564a2e612f629be5571553e6f0f3.png");
export const img7485dc64f2289f981e5ce3c2cad53ff0bf19ec5e = require("../assets/7485dc64f2289f981e5ce3c2cad53ff0bf19ec5e.png");
export const img55c83eb1533416dbf22da47d8b767a601422e3cd = require("../assets/55c83eb1533416dbf22da47d8b767a601422e3cd.png");
export const imga2858cb171d32319f90cc9a430b0fd76a59c6120 = require("../assets/a2858cb171d32319f90cc9a430b0fd76a59c6120.png");
export const imgb24432c55b8349a7f982b50b416521f1efa5c81f = require("../assets/b24432c55b8349a7f982b50b416521f1efa5c81f.png");
export const img36361a234d42905820d9018d7ceebecf3a2a0362 = require("../assets/36361a234d42905820d9018d7ceebecf3a2a0362.png");
export const img3f322bf50ed8f4af1ce8c59af3f1ddc0840f64ab = require("../assets/3f322bf50ed8f4af1ce8c59af3f1ddc0840f64ab.png");
export const img3e3ee3d3ff9755086f9935fa7b0739a72deed7c9 = require("../assets/3e3ee3d3ff9755086f9935fa7b0739a72deed7c9.png");
export const imgb735a80ffbe4db6e1427f8df687a43db853fb1de = require("../assets/b735a80ffbe4db6e1427f8df687a43db853fb1de.png");
export const imgd6481f2b162715aac617659a85144f587414f92b = require("../assets/d6481f2b162715aac617659a85144f587414f92b.png");
export const img12207c9edd612db39d4965a29d1fb869bd2d667c = require("../assets/12207c9edd612db39d4965a29d1fb869bd2d667c.png");
export const img1562a27a0d0d3b505a56df3eecfed7a4143a87b0 = require("../assets/1562a27a0d0d3b505a56df3eecfed7a4143a87b0.png");
export const imgc3a8e064f139aae331fc4f89022907d97f4a4eeb = require("../assets/c3a8e064f139aae331fc4f89022907d97f4a4eeb.png");
export const imgd2c836a12febf1a0c7637a5b9ec0173ba9e89a85 = require("../assets/d2c836a12febf1a0c7637a5b9ec0173ba9e89a85.png");
export const img6c95f80fdebf87e1bc1db1f68254bad8e35940b6 = require("../assets/6c95f80fdebf87e1bc1db1f68254bad8e35940b6.png");
export const imgf7f072b6233c9ac5dd678aa01ec195640897122d = require("../assets/f7f072b6233c9ac5dd678aa01ec195640897122d.png");
export const img276bc198ca2df6aee2352a9f7dead56f5dbd4abf = require("../assets/276bc198ca2df6aee2352a9f7dead56f5dbd4abf.png");
export const img414489db785b81c39675d5aef68bf6ba5044ff68 = require("../assets/414489db785b81c39675d5aef68bf6ba5044ff68.png");
export const header_logo = require("../assets/Logo.svg");
export const invite_icon = require("../assets/invite.svg");
export const aeroIcon = require("../assets/Aero.svg");
