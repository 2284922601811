import React from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { setStorageData } from "../../../../framework/src/Utilities";
import { Grid,Button  } from "@material-ui/core";
/* istanbul ignore next */
const ProjectDetailsMainSub = ({navigatFinshStep,state
  ,getStepsDataWithTraining,
}: any) => {
  const currentData = state?.projectStepData?.project_chapter_data?.data
  const fullArr = state?.projectDetails?.project?.project_chapters
  const currentIndex = fullArr?.findIndex((item: any) => item?.project_chapter_id == currentData?.id)
  const previousIndexId = fullArr?.[currentIndex - 1]?.project_chapter_id
  const nextIndexId = fullArr?.[currentIndex + 1]?.project_chapter_id
  const buttonText = fullArr?.length === currentIndex + 1 ? "Finish" : "Next Step"
  return (
    <Grid item sm={10} className="content">
      <Grid className="course-content">
        <div
        dangerouslySetInnerHTML={{
          __html:
          state?.projectStepData?.project_chapter_data?.data?.attributes.content
        }}
        className="course-content-container"
      />
      </Grid>
      {state?.projectDetails?.project?.project_chapters && (
        <Grid item sm={12} className={previousIndexId ? "btn" : "btn1"}>
         
           {previousIndexId && <Button
              className="change-btn1"
              startIcon={<KeyboardBackspaceIcon style={{ color: "#007DC6" }} />}
              onClick={() => getStepsDataWithTraining(previousIndexId)}
            >
              Previous step
            </Button>
          }
          <Button
            id="change-btn2"
            variant="contained"
            className="change-btn2"
            endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
            onClick={() => {
              if (fullArr.length !== currentIndex + 1) {
                getStepsDataWithTraining(nextIndexId)
              } else {
                navigatFinshStep()
                setStorageData("projectTitle", state.projectDetails?.project?.title);
                setStorageData("studentUrl", state.projectDetails.project.overview.data.attributes.student_view)
              }
            }}
          >
          {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectDetailsMainSub;
