// Customizable Area Start
import React from "react";
import { Grid} from "@material-ui/core";
export const configJSON = require("./config");
import LeadSchoolController, { Props } from "./LeadSchoolController";
import { Alabama, MySchool, NgssIcon, Professtional, SkillIcon, UploadIcon } from "./assets";
import CustomSwitch from "./components/CustomSwitch";
import "../assets/mySchool.scss";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
export default class LeadSchool extends LeadSchoolController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    this.inputRef = React.createRef<HTMLInputElement>();
    const { imageUrl } = this.state;
    return (
      <Grid
        container
        className="myschool-container"
        test-id="myschool-container"
      >
         <LoaderComponent open={this.state.loading} />
        <Grid item container className="page-heading">
          <img src={MySchool} />
        </Grid>
        <Grid item container className="school-title-card">
          <Grid item className="upload-section">
          <div
        style={{
          borderRadius: "50%",
          backgroundColor: "rgba(0, 100, 158, 0.90)",
          height: "160px",
          width: "160px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          overflow: "hidden", // Ensure the image fits within the circle
        }}
        className="upload-icon-and-text"
        onClick={this.handleDivClick}
        data-testid="divClick"

      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{
              borderRadius: "50%",
              height: "160px",
              width: "160px",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            gap:"8px" 
          }}
          className="upload-photo-btn"
        >
          <img
            src={UploadIcon}
            alt="Upload"
            style={{
              width: "24px",
              height: "24px",
              pointerEvents: "none", // Allow clicks to pass through to the div
            }}
          />
          <span
            className="upload-info-text"
            style={{ pointerEvents: "none", color: "white" }}
          >
            Upload Photo
          </span>
        </div>
        <input
          type="file"
          hidden
          accept="image/*"
          className="filetype"
          id="group_image"
          data-testid="upload-picture"
          ref={this.inputRef}
          onChange={this.onImageChangeVendor}
        />
      </div>
            <Grid item className="school-title-wrapper">
              <span className="school-title">Phillips Exeter Academy</span>
              <span className="school-subtitle">Mobile County Schools</span>
            </Grid>
          </Grid>
          <Grid item className="credits">
            <span className="credits-value">4,240</span> Credits
          </Grid>
        </Grid>
        {/* School address box */}
        <Grid
          item
          container
          className="school-address-card border-with-white-bg"
        >
          <span className="text-24 weight-350">My School</span>

          <Grid item container className="address-details-section">
            <Grid className="flex-column">
              <span className="text-16 weight-350">School</span>
              <span className="text-16 weight-325">
                Phillips Exeter Academy
              </span>
            </Grid>

            <Grid className="flex-column">
              <span className="text-16 weight-350">District</span>
              <span className="text-16 weight-325">Mobile County Schools</span>
            </Grid>
            <Grid className="flex-column">
              <span className="text-16 weight-350">State</span>
              <span className="text-16 weight-325">New Hampshire</span>
            </Grid>
          </Grid>
          <Grid className="flex-column">
            <span className="text-16 weight-350">Address</span>
            <span className="text-16 weight-325">
              60 Front Street Exeter, NH 03833-2460
            </span>
          </Grid>
        </Grid>
        {/* school cards */}
        <Grid item className="school-address-card border-with-white-bg">
          <Grid  className="flex-column">
            <span className="text-24 weight-350">Default School Standards</span>
            <span className="text-16 weight-325">
              Setting these standards will affect the default standards that
              teachers will see when they setup their account.
            </span>
          </Grid>
          <Grid className="flex-column" spacing={8}>
            <Grid className="flex">
              <span className="text-16 weight-350">
                Our Default Standard Sets
              </span>
              <Grid className="numberCard">
                <span color="#007dc6">5</span>
              </Grid>
            </Grid>
            <Grid container item className="standard-sets-cards">
              <Grid
                className={`standard-sets-card ${
                  this.state.standardSets.card1
                    ? "border-with-white-bg"
                    : "border-with-grey-bg"
                }`}
              >
                <Grid className="row-spacebetween">
                  <img src={NgssIcon} />

                  <CustomSwitch
                    data-testid="Ngss-btn"
                    checked={this.state.standardSets.card1}
                    onChange={(event) =>
                      this.handleChange("card1", event.target.checked)
                    }
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid className="flex-column">
                  <span
                    className={`text-20 weight-350 ${
                      this.state.standardSets.card1 ? "primary-700" : "grey-500"
                    }`}
                  >
                    NGSS
                  </span>
                  <span
                    className={`text-12 weight-325 ${
                      this.state.standardSets.card1
                        ? "chip-background-light"
                        : "chip-background-dark"
                    }`}
                  >
                    100 Standards
                  </span>
                </Grid>
              </Grid>
              <Grid
                className={`standard-sets-card ${
                  this.state.standardSets.card2
                    ? "border-with-white-bg"
                    : "border-with-grey-bg"
                }`}
              >
                <Grid className="row-spacebetween">
                  <img src={SkillIcon} />

                  <CustomSwitch
                    data-testid="Skills-btn"
                    checked={this.state.standardSets.card2}
                    onChange={(event) =>
                      this.handleChange("card2", event.target.checked)
                    }
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid className="flex-column">
                  <span
                    className={`text-20 weight-350 ${
                      this.state.standardSets.card2 ? "primary-700" : "grey-500"
                    }`}
                  >
                    {" "}
                    21st Century Skills
                  </span>
                  <span
                    className={`text-12 weight-325 ${
                      this.state.standardSets.card2
                        ? "chip-background-light"
                        : "chip-background-dark"
                    }`}
                  >
                    30 Standards
                  </span>
                </Grid>
              </Grid>
              <Grid
                className={`standard-sets-card ${
                  this.state.standardSets.card3
                    ? "border-with-white-bg"
                    : "border-with-grey-bg"
                }`}
              >
                <Grid className="row-spacebetween">
                  <img src={Alabama} />

                  <CustomSwitch
                    data-testid="Albama-btn"
                    checked={this.state.standardSets.card3}
                    onChange={(event) =>
                      this.handleChange("card3", event.target.checked)
                    }
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid className="flex-column">
                  <span
                    className={`text-20 weight-350 ${
                      this.state.standardSets.card3 ? "primary-700" : "grey-500"
                    }`}
                  >
                    {" "}
                    Alabama CS
                  </span>
                  <span
                    className={`text-12 weight-325 ${
                      this.state.standardSets.card3
                        ? "chip-background-light"
                        : "chip-background-dark"
                    }`}
                  >
                    10 Standards
                  </span>
                </Grid>
              </Grid>
              <Grid
                className={`standard-sets-card ${
                  this.state.standardSets.card4
                    ? "border-with-white-bg"
                    : "border-with-grey-bg"
                }`}
              >
                <Grid className="row-spacebetween">
                  <img src={Professtional} />

                  <CustomSwitch
                   data-testid="Professional-btn"
                    checked={this.state.standardSets.card4}
                    onChange={(event) =>
                      this.handleChange("card4", event.target.checked)
                    }
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid className="flex-column">
                  <span
                    className={`text-20 weight-350 ${
                      this.state.standardSets.card4 ? "primary-700" : "grey-500"
                    }`}
                  >
                    ETF Professional
                  </span>
                  <span
                    className={`text-12 weight-325 ${
                      this.state.standardSets.card4
                        ? "chip-background-light"
                        : "chip-background-dark"
                    }`}
                  >
                    100 Standards
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area End
