// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { addClassRoot, getAuthToken, getQuantity, getUserDetails, handleTokenError, removeClassRoot, monthDiff } from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setCurrentClassbox: (data: any) => {};
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  tab: string;
  alignment: any;
  quantityCheckOut: number;
  quantityPurchase: number;
  cardDetails: boolean;
  purchaseDetails: any;
  classboxId: any;
  checkOutGroup: any;
  purchaseGroup: any;
  saveTo: boolean;
  cost: any;
  savedOrderDetails: any;
  checked: boolean;
  insufficientCredit: boolean;
  successCardDetails: any;
  pendingOrSuccess: boolean;
  checkOutCost: number;
  purchaseCost: number;
  checkOutMonth: number;
  selectedRange: [Date, Date] | [] | null;
  months: any;
  studentPerNumber: any
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class PurchaseClassBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendPurchaseRequest: Message | string = "";
  getPurchaseDetailsId: Message | string = "";
  getOrderList: Message | string = "";
  saveAndOrder: Message | string = "";
  pedingRequest: Message | string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      tab: "tab0",
      saveTo: false,
      classboxId: "",
      alignment: "0",
      quantityCheckOut: 1,
      quantityPurchase: 1,
      cardDetails: false,
      purchaseDetails: {
        attributes:{
          checkout : null
        },
        checkout_cost: 0,
        purchase_cost: 0
      },
      checkOutGroup: 0,
      purchaseGroup: 0,
      cost: 0,
      savedOrderDetails: {
        attributes: {
          cost: 0
        }
      },
      checked: false,
      insufficientCredit: false,
      successCardDetails: null,
      pendingOrSuccess: false,
      checkOutCost: 0,
      purchaseCost: 0,
      checkOutMonth: 1,
      selectedRange: [],
      months: 1,
      studentPerNumber: { attributes: { students_per_group: 0 } }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    addClassRoot()
    // Customizable Area Start
    let confirmationId = this.props.navigation.getParam("ids");
    this.setState({ classboxId: confirmationId });
    this.setState({
      loading: true,
    });
    this.getPurchaseDetails(confirmationId);
    this.setState({
      checkOutCost: this.state.purchaseDetails.checkout_cost, purchaseCost: this.state.purchaseDetails.purchase_cost
    })


    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (prevState.quantityCheckOut !== this.state.quantityCheckOut) {
      this.classBoxQunatity();
    }
    if (prevState.quantityPurchase !== this.state.quantityPurchase) {
      this.classBoxPurchaseQunatity();
    }
    // Customizable Area End
  }


  async componentWillUnmount() {
    this.setState({ pendingOrSuccess: false });
    removeClassRoot()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)

      this.setState({
        loading: false,
      });

      if (!responseJson?.errors) {
        this.setResponse(apiRequestCallId, responseJson);
      } else if (
        apiRequestCallId === this.sendPurchaseRequest && responseJson.errors == "Insufficient balance"
      ) {
        this.setState({ insufficientCredit: !this.state.insufficientCredit });
      } else if (apiRequestCallId === this.pedingRequest) {
        this.setState({ pendingOrSuccess: true })
      }

      // Customizable Area End
    }
  }

  setResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getPurchaseDetailsId) {
      this.setState({
        purchaseDetails: responseJson.data,
        checkOutCost: responseJson.data.attributes.checkout_cost, purchaseCost: responseJson.data.attributes.purchase_cost,
        studentPerNumber: responseJson.data
      }, () => {
        this.classBoxPurchaseQunatity();
        this.classBoxQunatity();
      });
      if (responseJson.data.attributes.checkout === false) {
        this.setState({ alignment: "1" })
      }
    } else if (apiRequestCallId === this.getOrderList) {
      this.setState({ savedOrderDetails: responseJson.data, saveTo: !this.state.saveTo });
    } else if (apiRequestCallId === this.saveAndOrder) {
      this.setState({ savedOrderDetails: responseJson.data, cardDetails: !this.state.cardDetails, saveTo: true });
    } else if (
      apiRequestCallId === this.sendPurchaseRequest
    ) {
      this.setState({
        successCardDetails: responseJson.data,
      });
    }
  }

  setClassBoxDetails = (data: any) => {
    this.props.setCurrentClassbox(data);
  };
  toGoBack = () => {
    if (this.state.cardDetails) {
      this.setState({
        cardDetails: false,
      });
    } else {
      this.props.navigation.goBack();
    }
  };
  clearSuccessDetails = () => {
    this.setState({
      successCardDetails: null,
    });
  };
  handleChangeTab = (value: string) => {
    this.setState({
      tab: value,
    });
    return true;
  };

  handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    this.setState({ alignment: newAlignment });
  };

  increaseQuantity = (value: string) => {
    if (value === "increment") {
      this.setState({
        quantityCheckOut: this.state.quantityCheckOut + 1,
        checkOutCost: this.state.purchaseDetails.attributes.checkout_cost * (this.state.quantityCheckOut + 1) * this.state.checkOutMonth
      });

    } else if (value === "decrement") {
      if (this.state.quantityCheckOut === 1) return;
      this.setState({
        quantityCheckOut: this.state.quantityCheckOut - 1,
        checkOutCost: this.state.purchaseDetails.attributes.checkout_cost * (this.state.quantityCheckOut - 1)

      });
    }

  };

  increasePurchaseQuantity = (value: string) => {
    if (value === "increment") {
      this.setState({
        quantityPurchase: this.state.quantityPurchase + 1,
        purchaseCost: this.state.purchaseDetails.attributes.purchase_cost * (this.state.quantityPurchase + 1)

      });
    } else if (value === "decrement") {
      if (this.state.quantityPurchase === 1) return;
      this.setState({
        quantityPurchase: this.state.quantityPurchase - 1,
        purchaseCost: this.state.purchaseDetails.attributes.purchase_cost * (this.state.quantityPurchase - 1)

      });
    }
  };

  getPurchaseDetails = (confirmationId: any) => {
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPurchaseDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPurchaseDetailsEndPoint}/${confirmationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sendPendingRequest = () => {
    const token = getAuthToken();
    let data = {
      classbox_id: +this.state.purchaseDetails.id,
      request_type: "credit_request_pending",
      order_status_request : this.state.alignment === "0" ? "checkout" : "purchase"
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pedingRequest = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pendingRequestApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  purchaseClassBox = () => {
    const token = getAuthToken();
    let datas = {
      data: {
        attributes: {
          order_status: this.state.alignment == "0" ? "purchase" : "checkout",
          status: "pending",
          quantity:
            this.state.alignment == "0"
              ? this.state.quantityCheckOut
              : this.state.quantityPurchase,
          cost: this.state.alignment === "0" ? this.state.purchaseDetails.attributes.checkout_cost : this.state.purchaseDetails.attributes.purchase_cost,
          // @ts-ignore
          start_date: this.state.selectedRange[0],
          // @ts-ignore
          end_date: this.state.selectedRange[1],
          classbox_id: this.state.purchaseDetails.id,
          order_status_request : this.state.alignment === "0" ? "checkout" : "purchase"
        },
      },
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendPurchaseRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PurchaseClassEnndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  classBoxQunatity = () => {
    let qtyNum = this.state.quantityCheckOut;
    let quatity: any = this.state.studentPerNumber.attributes.students_per_group / (5 * qtyNum);

    if (this.state.quantityCheckOut === 2) {
      quatity = `${Math.floor(quatity)} or ${Math.round(quatity)}`;
    } else if (this.state.quantityCheckOut === 4) {
      quatity = `${Math.floor(quatity)} or 2`;
    } else {
      quatity = Math.round(quatity);
    }
    this.setState({ checkOutGroup: quatity });
  };
  classBoxPurchaseQunatity = () => {
    this.setState({ purchaseGroup: getQuantity(this.state) });
  };
  handleSaveTo = () => {
    const getUser = getUserDetails()

    const token = getAuthToken();
    let datas = {
      data: {
        attributes: {

          status: "completed",


          total_cost: this.state.savedOrderDetails.attributes.total_cost,

          cost: this.state.alignment === "0" ? this.state.purchaseDetails.attributes.checkout_cost : this.state.purchaseDetails.attributes.purchase_cost,

          use_earned_credits: this.state.checked,

        },
      },
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendPurchaseRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PurchaseClassEnndPoint}/${this.state.savedOrderDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutContent
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleClassBoxOrder = () => {
    this.getSavedOrder();
  };
  getSavedOrder = () => {
    const token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PurchaseClassEnndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  savingToOrder = () => {
    const token = getAuthToken();
    let datas = {
      data: {
        attributes: {
          order_status: this.state.alignment == "0" ? "checkout" : "purchase",
          status: "pending",
          quantity:
            this.state.alignment == "0"
              ? this.state.quantityCheckOut
              : this.state.quantityPurchase,
          cost: this.state.alignment === "0" ? this.state.purchaseDetails.attributes.checkout_cost : this.state.purchaseDetails.attributes.purchase_cost,
          // @ts-ignore
          start_date: this.state.selectedRange[0],
          // @ts-ignore
          end_date: this.state.selectedRange[1],
          classbox_id: this.state.purchaseDetails.id,
          total_cost: this.state.alignment === "0" ? this.state.checkOutCost * this.state.months : this.state.purchaseCost,
          checkout_month: this.state.months,
        },
      },
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveAndOrder = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PurchaseClassEnndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkedStatus = () => {
    this.setState({ checked: !this.state.checked, insufficientCredit: false });
  };

  sendRequest = () => {
    this.sendPendingRequest()
    this.setState({ pendingOrSuccess: true, successCardDetails: null })

  }
  subClassBoxUnit = (item: any) => {
    // @ts-ignore
    if (item.attributes.unit === "Classroom") {
      return item.attributes.quantity
    } else {
      if (this.state.alignment == 0) {
        return this.state.quantityCheckOut * item.attributes.quantity
      } else {
        return this.state.quantityPurchase * item.attributes.quantity
      }
    }
  }
  goBack = () => {
    this.setState({ cardDetails: !this.state.cardDetails, saveTo: false });
  }
  checkbox = () => {
    return this.state.cardDetails ? false : true
  }
  setSelectedRange = (value: [Date, Date], callback?: any) => {
    this.setState({ selectedRange: value }, () => callback && callback());
  };
  setMonth = (range: [Date, Date]) => {
    this.setState({ months: monthDiff(range) })
  }
}

// Customizable Area End

// Customizable Area End
