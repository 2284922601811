import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };

  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  value: string;
  enableField: boolean;

  // Customizable Area Start
  dialog: boolean;
  productData: any;
  formValue: any;
  updateFormValue: any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postProductApiCallId: any;
  getProductsApiCallId: any;
  deleteProductApiCallId: any;
  updateProductApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End
    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      value: "1",
      enableField: false,
      // Customizable Area Start
      productData: [],
      dialog: false,
      formValue: {
        name: "",
        category_id: "",
        manufacture_date: "",
        product_feature: "",
        expiry_date: "",
        numStock: "",
        price: "",
      },
      updateFormValue: {
        name: "",
        category_id: "",

        product_feature: "",
        manufacture_date: "",
        expiry_date: "",
        numStock: "",
        price: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getTheProducts();
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (
        apiRequestCallId === this.postProductApiCallId &&
        responseJson.data !== undefined
      ) {
        if (responseJson.data !== undefined) {
          alert("Product added successfully");
          this.setState({
            formValue: {
              name: "",
              category_id: "",
              price: "",
              numStock: "",
              product_feature: "",
              manufacture_date: "",
              expiry_date: "",
            },
          });
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (
        apiRequestCallId === this.getProductsApiCallId &&
        responseJson.data.length !== undefined
      ) {
        this.setState({ productData: responseJson.data });
      } else if (
        apiRequestCallId === this.deleteProductApiCallId &&
        responseJson.message === "Successfully deleted"
      ) {
        alert("Product Deleated successfully");
        this.getTheProducts();
      } else if (
        apiRequestCallId === this.updateProductApiCallId &&
        responseJson.data !== undefined
      ) {
        this.getTheProducts();
        alert("Product successfully updated");
        this.setState({ dialog: !this.state.dialog });
      }
    }

    // Customizable Area End
  }

  handleChange = (newValue: string) => {
    this.setState({ value: newValue });
    if (newValue === "1") {
      this.getTheProducts();
    }
    return true;
  };
  handleChangeInput = (event: any) => {
    const name = event.target.name;
    let value = event.target.value;
    this.setState({ formValue: { ...this.state.formValue, [name]: value } });
    return true;
  };

  handleChangeUpdate = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      updateFormValue: { ...this.state.updateFormValue, [name]: value },
    });
    return true;
  };

  handleSubmit = () => {
    if (
      this.state.formValue.name === "" ||
      this.state.formValue.category_id === "" ||
      this.state.formValue.manufacture_date === "" ||
      this.state.formValue.expiry_date === "" ||
      this.state.formValue.product_feature === "" ||
      this.state.formValue.numStock === "" ||
      this.state.formValue.price === ""
    ) {
      alert("All fields are required");
      return;
    }

    if(!moment(this.state.formValue.expiry_date).isAfter(moment(this.state.formValue.manufacture_date))){
      alert("Expiry Date  can't  be grater than or equal to Manufacture Date");
      return;
    }

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const attrs = {
      name: this.state.formValue.name,
      category_name: this.state.formValue.category_id,

      product_feature: this.state.formValue.product_feature,
      manufacture_date: moment(this.state.formValue.manufacture_date).format(
        "MM/DD/YYYY"
      ),
      expiry_date: moment(this.state.formValue.expiry_date).format(
        "MM/DD/YYYY"
      ),
      price: this.state.formValue.price,
      stock: this.state.formValue.numStock,
    };

    const httpBody = {
      products: attrs,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postProductApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_inventory_management/products"
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
    return true;
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
    return true;
  }

  // web events

  // Customizable Area Start
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
    return true;
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
    return true;
  };

  handleDialog = (e: any) => {
    this.setState({ dialog: !this.state.dialog });
    this.setState({
      updateFormValue: {
        name: e.attributes.name,
        category_id: e.attributes?.category_name,
        id: e.id,
        product_feature: e.attributes.product_feature,
        manufacture_date: e.attributes.manufacture_date
          ?.split(":")[0]
          .split("T")[0],
        expiry_date: e.attributes.expiry_date?.split(":")[0].split("T")[0],
        price: e.attributes.price,
        numStock: e.attributes.stock,
      },
    });
  };

  getTheProducts = () => {
    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InventoryEndPoint
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
    return true;
  };

  handleDelete = (id: number, checkBoolean?: boolean) => {
    let x = window.confirm("Are you sure you want to delete this product ?");
    if (checkBoolean) {
      x = true;
    }
    if (!x) return;

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProductApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_inventory_management/products/${id}`
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiDelete
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
    return true;
  };

  handleUpdate = () => {
    if (
      this.state.updateFormValue.name === "" ||
      this.state.updateFormValue.category_id === "" ||
      this.state.updateFormValue.manufacture_date === "" ||
      this.state.updateFormValue.expiry_date === "" ||
      this.state.updateFormValue.product_feature === "" ||
      this.state.updateFormValue.numStock === "" ||
      this.state.updateFormValue.price === ""
    ) {
      alert("All fields are required");
      return;
    }

    if(!moment(this.state.updateFormValue.expiry_date).isAfter(moment(this.state.updateFormValue.manufacture_date))){
      alert("Expiry Date  can't  be grater than or equal to Manufacture Date");
      return;
    }

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const attrs = {
      name: this.state.updateFormValue.name,
      category_name: this.state.updateFormValue.category_id,
      product_feature: this.state.updateFormValue.product_feature,
      manufacture_date: this.state.updateFormValue.manufacture_date,
      expiry_date: this.state.updateFormValue.expiry_date,
      price: +this.state.updateFormValue.price,
      stock: +this.state.updateFormValue.numStock,
    };

    const httpBody = {
      products: attrs,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProductApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_inventory_management/products/${this.state.updateFormValue.id}`
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
    return true;
  };
  // Customizable Area End
}
