import React from "react";
import {
  AreaChart,
  Line,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer,
} from "recharts";

export default function LineChartComponent({state}:any) {
  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={state.mySchoolDetails.graph_data}
        style={{
          marginLeft: "-5px",
          marginTop: "6px",
        }}
        margin={{
          top: 2,
          right: 2,
        }}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#9CC8E2" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#9CC8E2" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="day"
          style={{
            fontFamily: "Gotham-Light",
            color: "#949494",
            fontSize: "12px",
          }}
          padding={{right: 10}}
          interval={0}
          allowDataOverflow
        />
        <YAxis
          style={{
            fontFamily: "Gotham-Light",
            color: "#949494",
            fontSize: "12px",
          }}
        />
        <Line type="monotone" dataKey="credits" stroke="#007DC6" strokeWidth={3} />
        <Area
          type="monotone"
          dataKey="credits"
          stroke="#007DC6"
          fillOpacity={1}
          fill="url(#colorPv)"
          strokeWidth={3}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
