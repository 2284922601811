Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "StoreCredits";
exports.labelBodyText = "StoreCredits Body";
exports.historyDetails = "bx_block_activity_feed/credit_activities/";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End