import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Avatar,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PopoverComponent from "./PopoverComponent";
import "../../blocks/TeamBuilder/assets/table-component.scss";
import { getCommaSeperator } from "./NativeWebRouteWrapper/Utils";

export interface Data {
  name: string;
  permission: string;
  earned: number;
  allocated: number;
  currentBalance: number;
  activated: boolean;
  image: string;
  teacherId: number;
  unused_earned_credits : number;
  currentUser: boolean;
  schoolName: string;
}


interface HeadCellProps {
  id: keyof Data;
  numeric:boolean;
  disablePadding: boolean;
  label: string;
}

export interface DrawerDataTypes {
  activated: boolean;
  allocated:  number;
  currentBalance: number
  earned: number;
  image: string | undefined;
  name: string;
  permission: string;
  teacherId: number;
  unused_earned_credits: number;
}

interface TableComponentTypes {
  rows : Data[];
  handleDrawer : ()=> void;
  setDrawerData : (value : DrawerDataTypes)=> boolean,
  handleOkAction?: (catchedId: number, catchedRole: string)=>boolean;
  handleRemoveActions:(catchedId: number)=>boolean;
  isPendingCheckoutShow:boolean;
  handleRemoveLead:(catchedId: number,currentUser:boolean)=>boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string  | boolean}
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "permission",
    numeric: false,
    disablePadding: true,
    label: "Permission",
  },
  {
    id: "earned",
    numeric: true,
    disablePadding: false,
    label: "Earned Credits",
  },
  {
    id: "allocated",
    numeric: true,
    disablePadding: false,
    label: "Allocated Credits",
  },
  {
    id: "currentBalance",
    numeric: true,
    disablePadding: false,
    label: "Current Balance",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell: any, index) => (
          <TableCell
            key={headCell.id}
            // className={index>0 ? "headCell" : ""}
            className={classes.headCell}
            // align="left" 
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span className={classes.headTitle}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      border: "1px solid #EAECF0",
      borderRadius: "8px",
    },
    table: {
      width: "100%",  
    },
    headCell: {
      '&:not(:first-child)': {
        
                paddingLeft: '4px',
      }
      },
    tableHeader: {
      background: "#F9FAFB",
      borderWidth: "0px 1px 1px 0px",
      borderStyle: "solid",
      borderColor: "#EAECF0",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    headTitle: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#344054",
    },
    fieldValue: {
      fontStyle: "normal",
      fontWeight: 325,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#344054",
    },
    fieldValueLead: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: 'var(--Primary-600, #00649E)',
      display: 'inline-block',
      alignItems: 'center',
      padding: '6px 10px',
      borderRadius: '1000px',
      fontFamily: "Gotham",
      background: 'var(--Primary-25, #E8F6FE)',
    },
    bluessh: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#007DC6",
    },
    fieldValueBlue: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#007DC6",
    },
  })
);

function TableComponent({ rows, handleDrawer, setDrawerData, handleOkAction, handleRemoveActions, handleRemoveLead,isPendingCheckoutShow}: TableComponentTypes) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: Data) => {
    setDrawerData(row);
    handleDrawer();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowData, setRowData] = React.useState<Data>({
    name: "",
    permission: "",
    earned:0,
    allocated: 0,
    currentBalance: 0,
    activated: false,
    image: "",
    teacherId: 0,
    unused_earned_credits : 0,
    currentUser : false,
    schoolName: "",
  });

  const handleClickAction = (event: React.MouseEvent<HTMLElement>, row : Data) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setRowData(row)
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


   const renderPopoverComponent = () => {
    if(rowData){
      if(rowData.permission === "Lead"){
        if(rowData.currentUser){
          return (
            <PopoverComponent
              handleClose={handlePopoverClose}
              anchorEl={anchorEl}
              rowData = {rowData}
              handleRemoveActions = {()=> handleRemoveLead(rowData.teacherId,true)}
              popupToShow="removeLeadSelf"
              rows={rows}
          />
          )
        } else {
          return (
            <PopoverComponent
              handleClose={handlePopoverClose}
              anchorEl={anchorEl}
              rowData = {rowData}
              handleRemoveActions = {()=> handleRemoveLead(rowData.teacherId,false)}
              popupToShow="removeLeadOther"
            />
          )
        }
      }
      else if (rowData.permission === "Teacher") {
        if(handleOkAction){
          return(
            <PopoverComponent
              handleClose={handlePopoverClose}
              anchorEl={anchorEl}
              rowData = {rowData}
              handleOkAction={() => handleOkAction(rowData.teacherId, rowData.permission)}
              handleRemoveActions = {()=> handleRemoveActions(rowData.teacherId)}
              popupToShow="leadAndRemove"
              isPendingCheckoutShow={isPendingCheckoutShow}
            />
          )
        }
      }
    }
   }

  return (
    <div className={classes.root}>
      <TableContainer className="table-component-container">
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {
            stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.teacherId}
                    className="pointer"
                  >
                    <TableCell
                    data-test-id={"rowValuesA" + index}
                      align="left"
                      className={classes.fieldValue}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                      onClick={(event) => handleClick(event, row)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar
                        alt=""
                        src={row.image}
                      />
                      &nbsp;&nbsp;<span>{row.name}</span>&nbsp;&nbsp;{!row.activated && <div className="tag-pending">Pending</div>}
                    </TableCell>
                    <TableCell
                    data-test-id={"rowValuesB" + index}
                      align="left"
                      className={classes.fieldValue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      <span className={row.permission === "Lead" ? classes.fieldValueLead : classes.fieldValue}>{row.permission}</span>
                    </TableCell>
                    <TableCell
                    data-test-id={"rowValuesC" + index}
                      align="left"
                      className={classes.fieldValue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      {getCommaSeperator(row.earned)}
                    </TableCell>
                    <TableCell
                    data-test-id={"rowValuesD" + index}
                      align="left"
                      className={classes.fieldValue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      {getCommaSeperator(row.allocated)}
                    </TableCell>
                    <TableCell
                    data-test-id={"rowValuesE" + index}
                      align="left"
                      className={classes.fieldValueBlue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      {getCommaSeperator(row.currentBalance)}
                    </TableCell>
                    <TableCell align="right" className={classes.fieldValue} >
                      <div  data-test-id={"rowValuesF" + index} onClick={(e)=>handleClickAction(e,row)} >
                        <MoreVertIcon color="disabled" />
                      </div>
                      {
                      rowData && rowData.teacherId === row.teacherId && (
                      renderPopoverComponent()                     
                      )
                    }  
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default TableComponent;
