// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getAuthToken, handleTokenError } from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showTrainingModal: boolean;
  trainingList: any[];
  loading: boolean;
  trainingType: string;
  trainingData: any;
  patnerTrainingOrder: string;
  patnerTrainingOrderError: string;
  page: number;
  confirmId: number;
  confirmTitle: string;
  confirmCredit: number;
  creator_tag: string
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class TrainingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTrainingApiCallId: any;
  getPartnerTrainingOrder: any;
  requestTraining: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showTrainingModal: false,
      trainingList: [],
      loading: false,
      trainingType: "",
      trainingData: {},
      patnerTrainingOrder: "",
      patnerTrainingOrderError: "",
      page: 1,
      confirmId: 0,
      confirmTitle: "",
      confirmCredit: 0,
      creator_tag: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start

    this.getTrainingList(this.state.trainingType, this.state.page);
    this.getConfirmationDetails()
    // Customizable Area End
  }

  async componentWillUnmount() {
    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  closeTrainingModal = () => {
    this.setState({ patnerTrainingOrderError: "" });
    this.setState({
      showTrainingModal: false,
    });
    return true;
  };

  openTrainingModal = (data: any) => {
    this.setState({
      showTrainingModal: true,
      trainingData: data,
    });
    return true;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });
      }

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getTrainingApiCallId) {
          this.setState({ trainingList: responseJson.data || [] });
        } else if (apiRequestCallId === this.getPartnerTrainingOrder) {
          if (responseJson?.status) {
            if (responseJson?.status === "ordered") {
              this.closeTrainingModal();
              this.props.navigation.navigate("ConfirmTraining", {
                id: this.state.trainingData.id,
                title: this.state.trainingData?.attributes?.title,
                credit: this.state?.trainingData?.attributes?.credit_amount,
                creator_tags: this.state?.trainingData?.attributes?.creater_name
              })
            }
            this.setState({ patnerTrainingOrder: responseJson?.status });
          }
          if (responseJson?.error) {
            this.setState({ patnerTrainingOrderError: responseJson?.error });
          }
        } else if (apiRequestCallId === this.requestTraining) {
          this.props.navigation.navigate("RequestTraining")
        }
      }

      // Customizable Area End
    }
  }
  // Customizable Area Start

  handleTrainingChange = (event: any, newValue: string) => {
    this.setState({ trainingType: newValue });
    this.getTrainingList(newValue, this.state.page);
    return true;
  };

  getTrainingList = (training_type: string, page: number) => {
    const token = getAuthToken();
    const header = {
      token: token,
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrainingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.trainingAPiEndPoint}?training_type=${training_type ? training_type : ""
      }&page=${page}&per=1000`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onPatnerTrainingOrder = (id: any) => {
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPartnerTrainingOrder = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.partnerTrainingOrder}${id}/order_training`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickRequestTraining = (id: any) => {
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    this.setState({
      loading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestTraining = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.requestTrainingApi}?training_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchMoreData = () => {
    this.getTrainingList(this.state.trainingType, this.state.page + 1);
    this.setState({ page: this.state.page + 1 });
  };

  getConfirmationDetails = () => {
    let confirmationId = this.props.navigation?.getParam("id");
    let confirmationTitle = this.props.navigation?.getParam("title");
    let confirmatioCredit = this.props.navigation?.getParam("credit");
    let confirmTag = this.props.navigation?.getParam("creator_tags");

    this.setState({ confirmId: confirmationId, confirmTitle: confirmationTitle, confirmCredit: confirmatioCredit, creator_tag: confirmTag })
  }
  // Customizable Area End
}
// Customizable Area End

// Customizable Area End
