Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.getListProject = "bx_block_profile/projects"
exports.btnExampleTitle = "CLICK ME";
exports.plusPlanEndPoint = "bx_block_dashboard/dashboards"
exports.getStandardsEndPoint = "bx_block_profile/standard_sets/data_for_standard_filter"
// Customizable Area End