Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleAPiDelete = "DELETE";
exports.exampleAPiUpdate = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";
exports.InventoryEndPoint = "bx_block_inventory_management/products";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
