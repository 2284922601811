// Customizable Area Start
import React from "react";
import { Grid, Button, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Typography, IconButton } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import MySchoolController, { Props } from "./MySchoolController";
import { inviteUser } from "./assets";
import "../assets/myschool.scss";
import TableComponent from "../../../components/src/TableComponent";
import InviteTeacherModal from "./components/InviteTeacherModal";
import TeacherDetailsDrawer from "./components/TeacherDetailsDrawer";
import ReclaimCreditModal from "./components/ReclaimCreditModal";
import AllocateCreditModal from "./components/AllocateCreditModal";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import {
  getCommaSeperator,
  getCredits,
  getSchoolCurrBaklance,
  getTeachers,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import ConfirmDialog from "../../../components/src/ComformDialog";
import CancelIcon from "@material-ui/icons/Clear";

export default class MySchool extends MySchoolController {
  constructor(props: Props) {
    super(props);
  }

  renderStaffLeadsErrorMsg = () => {
    return (

      <ConfirmDialog
        data-test-id="handleCloseErrDialogID"
        open={this.state.staffColeadsUpdateApiErrors} onOkText=""
        onCancleText="Cancel"  handleClose={this.handleCloseErrDialog}
        title="First, assign a new Lead"
        content={["Before removing yourself as a lead from ", <span className="bold-content-text2">{Array.isArray(this.state.teachersData) && (this.state.teachersData[0]?.schoolName)},</span>, " you will first need to assign another colleague as the Lead for Classbox."]}
        onCancle={this.handleCloseErrDialog} iconUrl=""
      />
    )

  }

  render() {
    return (
      <Grid container className="myschool-container">
        <Grid item container sm={12} spacing={3} className="card-container">
          <Grid item sm={4} md={4} xs={12}>
            <div className="card">

              <span className="title">
                {getCommaSeperator(
                  this.state.mySchoolDetails.teacher_count || 0
                )}
              </span>

              <span className="desc">
                {getTeachers(this.state.mySchoolDetails?.teacher_count)}
              </span>

            </div>
          </Grid>
          <Grid item sm={4} md={4} xs={12}>
            <div className="card">
              <span className="title">
              {getSchoolCurrBaklance(this.state)}
              </span>
              <span className="desc">

                Unallocated{" "}
                {getCredits(
                  this.state.mySchoolDetails?.school_credit_details?.data
                    ?.attributes?.current_balance
                )}

              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={4} xs={12}>
            <div className="card">
              <span className="title">

                {getCommaSeperator(
                  this.state.mySchoolDetails?.total_school_wide_credits || 0
                )}

              </span>
              <span className="desc">Total School-wide Credits</span>
            </div>
          </Grid>
        </Grid>
        <Grid item container sm={12} spacing={3} className="table-container">
          <Grid sm={12} item className="table-header">
            <span className="title">{getTeachers(this.state.mySchoolDetails.teacher_count)}</span>
            <Button className="btn" onClick={this.handleDialog}>
              Invite Teachers &nbsp;&nbsp;
              <img src={inviteUser} alt="icon" />
            </Button>
          </Grid>

          <Grid item sm={12}>
            
            <TableComponent
              rows={this.state.teachersData}
              handleDrawer={this.handleDrawer}
              setDrawerData={this.setDrawerData}
              data-test-id="tableData"
              handleOkAction={this.onMakeCoLeadAction}
              handleRemoveActions={this.onStaffDeleteAction}
              handleRemoveLead={this.onLeadDeleteAction}
              isPendingCheckoutShow={this.state.pendingCheckoutPopup}
            />
          </Grid>
        </Grid>

        <InviteTeacherModal
          handleDialog={this.handleDialog}
          onAddTeacher={this.onAddTeacher}
          state={this.state}
        />
        
        {this.state.isDrawerOpen && (
          <TeacherDetailsDrawer
            isDrawerOpen={this.state.isDrawerOpen}
            handleDrawer={this.handleDrawer}
            handleReclaimModal={this.handleReclaimModal}
            data-test-id="TeacherDetailID"
            handleAddCreditModal={this.handleAddCreditModal}
            state={this.state}
            fetchMoreData={this.fetchMoreData}
          />
        )}
        {this.state.openReclaimModal && (
          <ReclaimCreditModal
            state={this.state}
            handleReclaimModal={this.handleReclaimModal}
            onClickReclaimCredit={this.onClickReclaimCredit}
          />
        )}
        {this.state.openAddCreditModal && (
          <AllocateCreditModal
            state={this.state}
            handleAddCreditModal={this.handleAddCreditModal}
            onAddCredit={this.onAddCredit}
          />
        )}
        <LoaderComponent open={this.state.loading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", 
          horizontal: "center" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          data-test-id="Snackbar"
          onClose={this.handleCloseSnackbar} id="snackBarClose"
        >

          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >

            {this.state.message}

          </MuiAlert>
        </Snackbar>
    
        {
          this.renderStaffLeadsErrorMsg()
        }

        <div>
          <Dialog
            open={this.state.pendingCheckoutPopup}
            onClose={this.handlePendingCheckoutPopupClose}
            aria-labelledby="draggable-dialog-title"
            className="dialog-container-pending"
            data-test-id="handlePendingCheckoutPopupCloseID"
          >

            <Box className="title-cross-box2">
              <div>
                <DialogTitle className="title-text2 modal-title-bold2">Complete pending Checkout</DialogTitle>
              </div>
              <IconButton onClick={this.handlePendingCheckoutPopupClose}>
                <CancelIcon />
              </IconButton>
            </Box>
            <DialogContent>
              <DialogContentText className="content-text2">Before removing <span className="bold-content-text2">{this.state.pendingCheckMetaDataCatch.user_name}</span> from <span className="bold-content-text2">{this.state.pendingCheckMetaDataCatch.school},</span> the user will first need to complete outstanding activities related to a checkout. </DialogContentText>
              <Typography className="content-text-bold-title">Pending Checkouts:</Typography>
              {
                Array.isArray(this.state.pendingCheckoutData) &&
                this.state.pendingCheckoutData.map((elem)=> (
                  <Box className="pending-checkout-box" key={elem.recordId}>
                    <Typography className="content-text-bold">{elem.classboxName}</Typography>
                    <Typography className="content-text-date">Return Date: {elem.returnDate}</Typography>
                  </Box>
                ))
              }
              <DialogContentText className="content-text2">Please contact <span className="bold-content-text2">{this.state.pendingCheckMetaDataCatch.user_name}</span> to have them complete this checkout, or contact your School Success Manager to get assistance on completing these. </DialogContentText>
            </DialogContent>
            <DialogActions className="btn-container-confirm-dialog2">
              <Button onClick={this.handlePendingCheckoutPopupClose} className="cancle-btn">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>

      </Grid>
    );
  }
}
// Customizable Area End
