Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.getClassboxesDataApiEndPoint = "classbox/classboxes";
exports.btnExampleTitle = "CLICK ME";
exports.getPurchaseDetailsEndPoint = "classbox/classboxes";
exports.PurchaseClassEnndPoint = "classbox/order_details";
exports.PutContent = "PUT";
exports.pendingRequestApi = "bx_block_trainings/teacher_requests"
// Customizable Area End