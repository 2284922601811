import React, { useEffect } from "react";
import {
  vectorIcon,
  iIcon,
  truck,
  cart,
  coin,
  error,
  rightBtn,
  cal,
  errorone,
} from "../assets";
import { Button } from "@material-ui/core";
import { Divider } from "react-native-elements";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import { getCommaSeperator, getUserDetails, reclaimedCredits, getCredits, showClassbox, getUserInfo } from "../../../../components/src/NativeWebRouteWrapper/Utils";

function ReviewOrder({
  checkedStatus,
  state,
  classBoxDetails,
  alignment,
  toSave,
  handleSaveTo,
  sendRequest,
  subClassBoxUnit,
  checkbox,
}: any) {
  //@ts-ignore
  let schoolAddress = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    lead_type: "",
    teacher_type: "",
    role: {
      name: "",
    },
    image: { url: "" },
    account_credits: {
      data: [
        {
          attributes: {
            recieved: 0,
            award: 0,
            reward: 0,
            claimed: 0,
            reclaimed: 0,
            spent: 0,
            current_balance: 0,
            unused_earned_credits:0
          },
        },
      ],
    },
    school: {
      data: {
        id: "",
        type: "",
        attributes: {
          name: "",
          address: "",
          current_balance: 0,
          spent: 0,
          school_credits: {
            data: [
              {
                attributes: {
                  recieved: 0,
                  award: 0,
                  reward: 0,
                  claimed: 0,
                  reclaimed: 0,
                  spent: 0,
                  current_balance: 0,
                },
              },
            ],
          },
          manager_details: {
            email: "",
            first_name: "",
            last_name: "",
          },
        },
      },
    },
  });
  useEffect(() => {
    let amt = getUserDetails();
    setUser(amt);
  }, []);
  let total_cred = reclaimedCredits(user.account_credits?.data[0]?.attributes, state, alignment === "0" ? +state.checkOutCost : +state.purchaseCost)
  return (
    <div className="review-main">
      <div className="divone">
        <span className="review-header">Review Order</span>

      </div>
      <Divider />
      <div className="info2">
        <img src={vectorIcon} alt="info" />
        <span className="info-text2">
          {" "}
          Classbox {state.alignment == 0 ? "Checkout" : "Purchase"}
        </span>
      </div>

      <div className="divtwo">
        <span className="info-text3">
          &#8226;{" "}
          {state.alignment == 0
            ? state.quantityCheckOut
            : state.quantityPurchase}
          x {classBoxDetails?.attributes?.title}
        </span>
        <div className="info-div3">
          {showClassbox(state.alignment, classBoxDetails).map(
            (item: any) => {
              let quantity = subClassBoxUnit(item);
              return (
                <span className="info-text4" key={item?.attributes?.name}>
                  • {quantity}x {item?.attributes?.name}
                </span>
              );
            }
          )}
        </div>
      </div>
      <Divider />

      <div className="info2">
        <img src={truck} alt="info" />
        <span className="info-text2">Delivery Details</span>
      </div>
      <div className="divthree">
        <span className="info-text7">
          {schoolAddress?.school?.data?.attributes?.name}
        </span>
        <span className="info-text8">
          {schoolAddress?.school?.data?.attributes?.address}
        </span>
        <span className="info-text9 conatat-change-bottom">
          Contact your School Success Manager to modify
        </span>
        {state.alignment === "0" ? <Divider /> : ""}

        {state.alignment === "0" && (
          <>
            <div className="date-text">
              <img src={cal}></img>
              <span className="info-text2">Checkout Dates</span>
            </div>
            <div className="date-div">
              <span className="dates">
                {moment(state.selectedRange[0] || new Date())?.format(
                  "MM/DD/YYYY"
                )}{" "}
                -{" "}
                {moment(state.selectedRange[1] || new Date())?.format(
                  "MM/DD/YYYY"
                )}
              </span>
            </div>
            <span className="return-label">
              A return label will be provided to you to return your Classbox
              Checkout. You can choose to purchase your Classbox at any time
              during your Checkout Period for an additional Credits Cost.
            </span>
          </>
        )}
      </div>
      <div className="footer1">
        <Divider />
        <br />
        <div className="info7">
          <img src={cart} alt="info" />
          &nbsp;&nbsp;<span className="info-text2">Your Order</span>
        </div>
        <div className="info3">
          <span className="info-text7">Total Credits Cost:</span>
          {alignment === "0" ? (
            <span className="info-text8">{getCommaSeperator(+state.checkOutCost * state.months)}</span>
          ) : (
            <span className="info-text8">{getCommaSeperator(+state.purchaseCost)}</span>
          )}
        </div>
        <div className="info3 info-bg">
          <div>
            <span className="info-text9">
              Use earned credits for this order
            </span>
            <br />
            <span className="info-text10">
              You will use {getUserInfo(user)}{" "}
              earned credits
            </span>
          </div>
          <Checkbox
            onClick={checkedStatus}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            checked={state.checked}
          />
        </div>
        {state.insufficientCredit ? (
          <>
            <div
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                marginTop: "30px",
                padding: "10px",
                alignSelf: "center",
                display: "flex",
                backgroundColor: "#FEEDE6",
                justifyContent: "space-between",
              }}
            >
              <img
                src={errorone}
                style={{ width: "10%", marginRight: "3%", marginLeft: "3%" }}
              ></img>
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#DC6803",
                }}
              >
                You don't have enough credits, please submit for approval by
                your school's administrator
              </span>
            </div>
            <Button
              onClick={sendRequest}
              variant="contained"
              className="right-btn"
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                marginTop: "0px",
              }}
            >
              Submit For Approval &nbsp; &nbsp;
              <img src={rightBtn} alt="i" />
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            className={toSave ? "right-btn" : "right-btn1"}
            disabled={toSave ? false : true}
            onClick={handleSaveTo}
            id="complete-btn-review"
          >
            Complete Order &nbsp; &nbsp;
            <img src={rightBtn} alt="i" />
          </Button>
        )}

        <div className="info3">
          <div className="card1-content">
            <span className="credits-text">
              Remaining Balance After Ordering:
            </span>
            <span className="credits-amt">
              {getCommaSeperator(total_cred)} <span className="credits-amt1">{getCredits(total_cred)}</span>
            </span>
          </div>
          <img src={coin} alt="coin" />
        </div>
      </div>
    </div>
  );
}

export default ReviewOrder;
