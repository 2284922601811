export const dummyIcon = require("../assets/dummyicon.svg");
export const betaboxIcon = require("../assets/betabox.png");
export const training2 = require("../assets/training2.png");
export const training3 = require("../assets/training3.png");
export const training4 = require("../assets/training4.png");
export const classboxIcon = require("../assets/classboxIcon.png");
export const creditIcon = require("../assets/creditIcon.svg");
export const debitIcon = require("../assets/debitIcon.svg");
export const hoursIcon = require("../assets/hoursIcon.svg");
export const startupIcon = require("../assets/startupIcon.png");
export const rewardsIcon = require("../assets/rewards-icon.svg");
export const rightArrowIcon = require("../assets/rightArrow.svg");
export const closeIcon = require("../assets/closeicon.svg");
export const confirmation = require("../assets/confirmation.svg");
