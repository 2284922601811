import React from "react";
import { Grid, Button } from "@material-ui/core";
import { emptyDocIcon, exploreIcon } from "../assets";
import "../../assets/emptyDashboard.scss";

const EmptyTeacherDashboard = ({ props }: any) => {

  return (
    <Grid container className="EmptyDashboard-container">
      <Grid item sm={12} xs={12} xl={12} className="sub-container">
        <img className="emptyIcon-img" src={emptyDocIcon} />
        <span className="empty-title">
          You don't have any <br />
          Classboxes quite yet.
        </span>
        <span className="empty-desc">
          To start teaching hands-on projects in your class,
          <br /> browse what's available on Classbox.
        </span>
      </Grid>
      <div className="button-container">
        <Button
          className="btn"
          style={{
            padding: "12px 24px",
            backgroundColor: "#007DC6",
            borderRadius: "10px",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
          }}
          variant="contained"
          onClick={() => props.navigation.navigate("Classboxes")}
          data-test-id={""}
        >
          Order A Classbox
          <img src={exploreIcon} className="exploreIcon" />
        </Button>
      </div>
    </Grid>
  );
};

export default EmptyTeacherDashboard;
