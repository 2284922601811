import React from "react";
import "../../assets/stepper.scss";
import { rightIconWhite } from "../assets";
import { Button } from "rsuite";

const StepperComponent = ({navigation}:any) => {
  return (
    <div className="stepper-container">
      <h1 className="title">Next Steps</h1>
      <div className="content">
        <div className="step-container">
          <h1 className="title-txt">We’ve Received Your Return Request</h1>
          <div>
          <span className="desc">
            A return label will be sent to you via email soon.
          </span>
          <br />
          <br />
          <span className="desc">
            While you wait for it to arrive, go ahead and box up all the
            components that came with your Classbox into the box that it arrived
            in.
          </span>
          <br />
          <br />
          <span className="desc">
            Even if one of the parts is damaged, still place it in the box for
            return.
          </span>
          <br />
          <br />
          <span className="desc">
            Do not include any consumable items that were used up while doing
            the project.
          </span>
          </div>
          <div className="image-circle">1</div>
        </div>
        <div className="step-container">
          <h1 className="title-txt-1">Shipping Your Classbox Return</h1>
          <span className="desc">
            Once you receive your return label, print it out and place it inside
            of the clear shipping label pouch on the outside of the Classbox.
            When ready, leave the Classbox at your front desk for the Classbox
            to be picked up.
          </span>
          <div className="image-circle-1">2</div>
        </div>
        <div className="step-container">
          <h1 className="title-txt-1">Processing your Classbox Return</h1>
          <span className="desc">
            Once we receive your Classbox, we will process it. Don’t worry, we
            will never charge you for lost or damaged parts. Hands-on learning
            can be messy!
          </span>
          <div className="image-circle-1">3</div>
        </div>
        <div
          className="step-container"
          style={{
            marginBottom: "-1vw",
          }}
        >
          <h1 className="title-txt-1">You’re Done!</h1>
          <span className="desc">
            That is all! Thank you for completing these return steps.
          </span>
          <div className="image-circle-1">4</div>
        </div>
      </div>
      <div className="card-self">
        <div className="sub-card">
          <span className="string1">What will you teach next?</span>
          <span className="string2">
            We can’t wait to see which Classbox you’ll teach next.
          </span>
        </div>
        <br />
        <br />
        <Button className="btn-ow" onClick={() => navigation.navigate("Dashboard")}>
          Dashboard &nbsp;&nbsp;<img src={rightIconWhite} alt="aero"></img>
        </Button>
      </div>
    </div>
  );
};

export default StepperComponent;
