export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const cardImg = require("../assets/cardImage.svg");
export const grades = require("../assets/grades.svg");
export const gradesCheck = require("../assets/gradesCheck.svg");
export const duration = require("../assets/duration.svg");
export const durationCheck = require("../assets/durationCheck.svg");
export const subject = require("../assets/subject.svg");
export const subjectCheck = require("../assets/subjectCheck.svg");
export const classBox = require("../assets/classBox.svg");
export const classBoxCheck = require("../assets/classBoxCheck.svg");
export const standard = require("../assets/standard.svg");
export const standardCheck = require("../assets/standardCheck.svg");
export const creatorCard = require("../assets/creatorCard.svg");
export const creatorCard2 = require("../assets/createrCard2.svg");
export const creatorCard3 = require("../assets/createrCard3.svg");
export const checkIcon = require("../assets/checkIcon.svg");

