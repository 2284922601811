import React, { useRef } from "react";
import { Grid, TextField } from "@material-ui/core";
import {
  oneActiveIcon,
  oneIcon,
  threeActiveIcon,
  threeIcon,
  twotActiveIcon,
  twotIcon,
} from "../assets";
import CheckBoxComponent from "../components/CheckboxComponent";
import useOutsideClick from "../../../../components/src/NativeWebRouteWrapper/useOutsideClick";

const PopoverComponent = ({
  state,
  handleCloseModal,
  handletab0Checkbox,
  handletab1Checkbox,
  handleChangeTab,
  handleTabsButton,
  handleCompareChange,
  handleChangeV1,
  handleChangeV2,
}: any) => {
  const refComponent = useRef<HTMLDivElement>(null);
  useOutsideClick(refComponent, () => handleCloseModal());
  return (
    <div className="popover-filters" ref={refComponent || ""}>
      <div className="tabs">
        <div
          className={state.tab === "tab0" ? "active-tab" : "tab"}
          onClick={() => handleChangeTab("tab0")}
        >
          <img
            src={state.tab === "tab0" ? oneActiveIcon : oneIcon}
            alt="icon"
          />
          &nbsp;&nbsp;<span className="text">Request Types</span>
        </div>
        <div
          className={state.tab === "tab1" ? "active-tab" : "tab"}
          onClick={() => handleChangeTab("tab1")}
        >
          <img
            src={state.tab === "tab1" ? twotActiveIcon : twotIcon}
            alt="icon"
          />
          &nbsp;&nbsp;<span className="text">Credit Cost</span>
        </div>
        <div
          id="tab2btn"
          className={state.tab === "tab2" ? "active-tab" : "tab"}
          onClick={() => handleChangeTab("tab2")}
        >
          <img
            src={state.tab === "tab2" ? threeActiveIcon : threeIcon}
            alt="icon"
          />
          &nbsp;&nbsp;<span className="text">Classbox Type</span>
        </div>
      </div>
      <div className="content">
        {state.tab === "tab0" && (
          <Grid item container className="tab0-content" spacing={2}>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab0Checkbox}
                checked={state.tab0Checkboxes.Purchase}
                label="Purchase"
                name="Purchase"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab0Checkbox}
                checked={state.tab0Checkboxes.Checkout}
                label="Checkout"
                name="Checkout"
              />
            </Grid>
          </Grid>
        )}
        {state.tab === "tab1" && (
          <Grid className="tab1-content" container>
            <Grid item container sm={12} className="tabs" spacing={2}>
              <Grid item sm={6}>
                <div
                  id="checkout-btn1"
                  onClick={() => handleTabsButton("checkout")}
                  className={
                    state.requestType.type === "checkout"
                      ? "activebtn"
                      : "nonactivebtn"
                  }
                >
                  <span className="checkout-txt0">Checkout</span>
                </div>
              </Grid>
              <Grid item sm={6}>
                <div
                  id="checkout-btn2"
                  onClick={() => handleTabsButton("purchase")}
                  className={
                    state.requestType.type === "purchase"
                      ? "activebtn"
                      : "nonactivebtn"
                  }
                >
                  <span className="purchase-txt0">Purchase</span>
                </div>
              </Grid>
            </Grid>
            {state.requestType.type !== "" && (
              <Grid item container className="compare" sm={12} spacing={2}>
                <Grid item sm={2}>
                  <div
                    id="checkout-btn3"
                    onClick={() => handleCompareChange("is")}
                    className={
                      state.requestType.compare === "is"
                        ? "activebtn"
                        : "nonactivebtn"
                    }
                  >
                    <span>Is</span>
                  </div>
                </Grid>
                <Grid item sm={4}>
                  <div
                    id="compare0"
                    onClick={() => handleCompareChange("between")}
                    className={
                      state.requestType.compare === "between"
                        ? "activebtn"
                        : "nonactivebtn"
                    }
                  >
                    <span>Is between</span>
                  </div>
                </Grid>
                <Grid item sm={3}>
                  <div
                    id="compare1"
                    onClick={() => handleCompareChange("morethan")}
                    className={
                      state.requestType.compare === "morethan"
                        ? "activebtn"
                        : "nonactivebtn"
                    }
                  >
                    <span>More than</span>
                  </div>
                </Grid>
                <Grid item sm={3}>
                  <div
                    id="compare2"
                    onClick={() => handleCompareChange("lessthan")}
                    className={
                      state.requestType.compare === "lessthan"
                        ? "activebtn"
                        : "nonactivebtn"
                    }
                  >
                    <span>Less than</span>
                  </div>
                </Grid>
              </Grid>
            )}
            {state.requestType.compare !== "" && (
              <Grid item container sm={12} className="fields" spacing={2}>
                <Grid
                  item
                  sm={state.requestType.compare === "between" ? 6 : 12}
                >
                  <TextField
                    className="input-field"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="number"
                    placeholder="Value"
                    onChange={handleChangeV1}
                    value={state.requestType.value1}
                    fullWidth
                  />
                </Grid>
                {state.requestType.compare === "between" && (
                  <Grid item sm={6}>
                    <TextField
                      className="input-field"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="number"
                      placeholder="Value"
                      onChange={handleChangeV2}
                      value={state.requestType.value2}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
        {state.tab === "tab2" && (
          <Grid item container className="tab2-content" spacing={2}>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.makerspaceEquipment}
                label="Makerspace Equipment"
                name="makerspaceEquipment"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.STEMTechnology}
                label="STEM Technology"
                name="STEMTechnology"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.reusableMaterials}
                label="Reusable Materials"
                name="reusableMaterials"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.consumableMaterials}
                label="Consumable Materials"
                name="consumableMaterials"
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default PopoverComponent;
