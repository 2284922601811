// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { successIcon, decline } from "./assets";

import { runEngine } from "../../../framework/src/RunEngine";
import { getAuthToken, getUserDetails, handleTokenError, isTeacher, setUserDetails } from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setPendingRequest: (data:number)=>void;
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  showSnackbar: boolean;
  showAlert: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  tab: string;
  desclineModalOpen: boolean;
  noCreditModalOpen: boolean;
  storedData: any;
  declineData: any;
  credits: number;
  errorMsg: any;
  action: string;
  request_id: number;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class RequestsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPendingRequest: any;
  onApproveClick: any;
  getUserDetailsApiId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      showSnackbar: false,
      showAlert: false,
      severity: undefined,
      message: "",
      loading: false,
      tab: "pending",
      desclineModalOpen: false,
      noCreditModalOpen: false,
      storedData: {},
      declineData: {},
      credits: 0,
      errorMsg: {
        msg: "",
        requested: "",
        techerName: "",
        icon:""
      },
      action:"",
      request_id: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    if(isTeacher()){
      this.props.navigation.goBack()
    }
    this.getPendingRequestData();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (prevState.tab !== this.state.tab) {
      this.getPendingRequestData();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });
      }
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getPendingRequest) {
          this.setState({ storedData: responseJson });
          this.setPendingRequest(responseJson.pending_requests_count);
        } else if (apiRequestCallId === this.onApproveClick) {
          if (responseJson?.status) {
            if (responseJson?.status === "approved") {
              this.setState({
                errorMsg: {
                  ...this.state.errorMsg,
                  requested: "Request accepted",
                  msg: `You accepted ${this.state.errorMsg?.techerName}'s request`,
                  icon:successIcon
                },
              });
              this.handleAlert(true);
              this.getPendingRequestData();
              this.getUserData()
            } else if (responseJson?.status === "declined") {
              this.setState({
                errorMsg: {
                  ...this.state.errorMsg,
                  requested: "Request declined",
                  icon:decline
                },
              });
              this.handleAlert(true);
              this.setState({
                desclineModalOpen: !this.state.desclineModalOpen,
              });
              this.getPendingRequestData();
              return true;
            }
          } else if (responseJson?.error) {
            if (responseJson?.error === "Low credit balance") {
              this.handleNocreditModal();
            }
          }
        } else if(apiRequestCallId === this.getUserDetailsApiId){
          setUserDetails(responseJson)
        }
      }
      // Customizable Area End
    }
  }

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  changeTab = (value: string) => {
    this.setState({
      tab: value,
    });
    return true;
  };

  handleDeclineModal = (item: any, state: string, totalCredits: any,request_id: any ) => {
    this.setState({
      errorMsg: { ...this.state.errorMsg, techerName: item?.teacher_name },
    });
    this.setState({ declineData: item });
    this.setState({ credits: totalCredits });
    this.setState({
      desclineModalOpen: !this.state.desclineModalOpen,
    });
    this.setState({ action: state });
    this.setState({ request_id : request_id });

    return true;
  };

  handleDeclineReq = (values: any) => {
    let message =
      values?.reason.length > 20
        ? values?.reason.substring(0, 20) + "..."
        : values?.reason;

    this.setState({
      errorMsg: {
        ...this.state.errorMsg,
        msg: `Your reason: ${message}`,
      },
    });

    this.handleApprove(
      this.state.declineData,
      this.state.action,
      this.state.credits,
      values.reason,
      this.state.request_id
    );

    return true;
  };
  handleNocreditModal = () => {
    this.setState({
      noCreditModalOpen: !this.state.noCreditModalOpen,
    });
    return true;
  };

  handleAlert = (value: boolean) => {
    this.setState({
      showAlert: value,
    });
    return true;
  };

  handleApprove = (item: any, state: string, totalCredits: any, value: any, request_id: any) => {
    this.setState({
      loading: true,
    });

    const token = getAuthToken();
    const header = {
      token: token,
    };
    this.setState({
      errorMsg: {
        ...this.state.errorMsg,
        techerName: item?.teacher_name,
      },
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.onApproveClick = requestMessage.messageId;

    if (state === "approved_all") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.onApproveDecline}?teacher_id=${item?.teacher_id}&status=approved&credits=${totalCredits}`
      );
    } else if (state === "declined_all") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.onApproveDecline}?teacher_id=${item?.teacher_id}&status=declined&credits=${totalCredits}&message=${value}`
      );
    } else if (state === "declined") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.onApproveDecline}?teacher_id=${item?.teacher_id}&status=${state}&credits=${totalCredits}&message=${value}&request_id=${request_id}`
      );
    } else if (state === "approved") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.onApproveDecline}?teacher_id=${item?.teacher_id}&status=${state}&credits=${totalCredits}&request_id=${request_id}`
      );
    } 

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  setPendingRequest = (data: any) => {
    this.props.setPendingRequest(data);
  };

  getPendingRequestData = () => {
    this.setState({ loading: true });
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.getUserDetailsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPendingRequest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPending}?status=${this.state.tab}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSumData = (creditsValue: any) => {
    let sumValue = 0;
    for (let i = 0; i < creditsValue?.length; i++) {
      sumValue += creditsValue[i]?.credits;
    }
    return sumValue;
  };
  getUserData = () => {
    const header = {
      "Content-Type": configJSON.getUserDetailsApiContentType,
    };

    let user = getUserDetails();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsAPiEndPoint}${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}

// Customizable Area End

// Customizable Area End
