import React from "react";
import { Grid } from "@material-ui/core";

const ProjectCards = ({ state, handleLeftCard }: any) => {
    return (
        <Grid container item sm={12} className="claaaboxes-cards">
            {state.setCurrentprojectData.data?.map((elemet: any) => {
                return <Grid item sm={12} key={elemet.id} className={state.setSlectedCard?.id === elemet.id ?"card-one1 pointer": "card-one pointer"} onClick={() => handleLeftCard(elemet)}>
                    {elemet.attributes.related_classboxes?.title && <div className="card-main-text-div">
                        <img src={elemet.attributes.related_classboxes.emoji_url.url} className="classbox-url" alt="Icon"></img>
                        <span className="card-main-text"> {elemet.attributes.related_classboxes.title}</span>
                    </div>}

                    <div className="card-content-main">
                        <img src={elemet.attributes.hero_image?.url} alt="Icon" className="card-content-img"></img>
                        <div className="card-content-left-div">
                            <div className="card-content-div">
                                    <span className="card-content-link">{elemet.attributes.subject_tags.join(", ")}</span>
                            </div>
                            <span className="card-content-header">{elemet.attributes.title}</span>
                            <span className="card-content-sub-text">{elemet.attributes.short_description}</span>
                        </div>
                    </div>
                </Grid>
            })}
        </Grid>
    );
};

export default ProjectCards;
