Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpPostMethod = "PUT";
exports.postTeacherDetails = "account_block/accounts/teacher_details";
exports.httpPatchMethode = "PATCH";
exports.activateUser = "account_block/accounts/";
// Customizable Area End
