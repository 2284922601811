import React, { useEffect, useMemo, useRef } from "react";
import { DateRangePicker } from "rsuite";
import "../../assets/Calender.scss";
import moment from "moment";
import "rsuite/lib/styles/index.less";

const CalenderComponent = ({
  selectedRange,
  setSelectedRange,
  months,
  setMonth,
}: any) => {
  let getStartDate = () => {
    let dateAfterSeven: Date = new Date(
      new Date().setDate(new Date().getDate() + 7)
    );
    if (dateAfterSeven.getDay() === 0) {
      dateAfterSeven = new Date(
        dateAfterSeven.setDate(dateAfterSeven.getDate() + 1)
      );
    } else if (dateAfterSeven.getDay() === 6) {
      dateAfterSeven = new Date(
        dateAfterSeven.setDate(dateAfterSeven.getDate() + 2)
      );
    }

    return dateAfterSeven;
  };

  const [openCalender, setOpenCalender] = React.useState(false);

  useEffect(() => {
    setSelectedRange([
      getStartDate(),
      new Date(getStartDate().setMonth(getStartDate().getMonth() + 1)),
    ]);
  }, []);

  const predefinedBottomRanges = useMemo(() => ([
    {
      label: "1 Month",
      value: [
       selectedRange[0],
        new Date(new Date(selectedRange[0]).setMonth(new Date(selectedRange[0]).getMonth() + 1)),
      ],
    },
    {
      label: "2 Month",
      value: [
       selectedRange[0],
        new Date(new Date(selectedRange[0]).setMonth(new Date(selectedRange[0]).getMonth() + 2)),
      ],
    },
    {
      label: "3 Month",
      value: [
       selectedRange[0],
        new Date(new Date(selectedRange[0]).setMonth(new Date(selectedRange[0]).getMonth() + 3)),
      ],
    },
  ]) , [selectedRange])
  const refComponent = useRef<any>(null);
  return (
    <label className="date-picker-component">
      <div className="calender-div">
        <div>
          <span className="month">{months} Month Checkout</span>
          <br />
          <span className="date">
            {moment(selectedRange[0] || new Date())?.format("MM/DD/YYYY")} -{" "}
            {moment(selectedRange[1] || new Date())?.format("MM/DD/YYYY")}
          </span>
        </div>

        <DateRangePicker
          ref={refComponent}
          showOneCalendar
          oneTap={true}
          appearance="subtle"
          onSelect={(e) => {
            setSelectedRange([
              new Date(e),
              new Date(new Date(e).setMonth(new Date(e).getMonth() + months)),
            ])
          }
            
          }
          //@ts-ignore
          disabledDate={(date) => date < new Date()}
          //@ts-ignore
          value={selectedRange}
          //@ts-ignore
          ranges={predefinedBottomRanges}
          //@ts-ignore
          onChange={(range) => {
            setSelectedRange(range, () => setOpenCalender(false));
            // @ts-ignore
            setMonth(range);
          }}
          format="MM/DD/YYYY"
        />
      </div>
    </label>
  );
};

export default CalenderComponent;
