// Customizable Area Start
import React from "react";
import { Grid, Divider,Button} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import ProjectDetailsController, { Props } from "./ProjectDetailsController";
import ProjectsSidebar from "./components/ProjectsSidebar";
import "../assets/training.scss";
import ProjectDetailsMain from "./components/ProjectDetailsMain";
import ProjectDetailsHeader from "./components/ProjectDetailsHeader";
import ProjectOverview from "./components/ProjectOverview";
import { renderOverview } from "../../../components/src/NativeWebRouteWrapper/Utils";
import StepSTandards from "./components/StepSTandards";
import { tworingsIcon } from "./assets";

export default class ProjectDetails extends ProjectDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Grid container spacing={3} className="training-details">
        <Grid item sm={3} className="sidebar">
          <ProjectsSidebar
            state={this.state}
            navigation={this.props.navigation}
            getStepsDataWithTraining={this.getStepsDataWithTraining}
            toggleOverview={this.toggleOverview}
            setContent={this.setContent}
          />
        </Grid>
        {!this.state.overview && (
          <Grid container item sm={9} className="main">
            {this.state.previewMode !== "preview" && (
              <ProjectDetailsHeader
                updateStepStatus={this.updateStepStatus}
                state={this.state}
                getButtonTitle={this.getButtonTitle}
              />
            )}
            {this.state.previewMode !== "preview" && (
              <StepSTandards
                state={this.state}
                setStandardTab={this.setStandardTab}
              />
            )}
            {this.state.previewMode === "preview" && (
              <div className="header-preview-mode">
                <div className="left-container-txt">
                  <h1 className="title-txt0">
                    {this.state.projectDetails.overview.data.attributes.title}
                  </h1>
                  <span className="desc-txt0">
                    You’re viewing this project in Preview Mode.
                  </span>
                </div>
                <Button
                  className="order-btx-classbox"
                  onClick={() => {
                    this.props.navigation.navigate("PurchaseClassBox", {
                      ids: this.state.projectDetails.overview.data.attributes
                        .related_classboxes.id,
                    });
                  }}
                >
                  <img src={tworingsIcon} alt="image" /> &nbsp; Order Classbox
                </Button>
              </div>
            )}
            <Divider />
            <ProjectDetailsMain
              state={this.state}
              getButtonTitle={this.getButtonTitle}
              showPrevious={this.showPrevious}
              updateStepStatus={this.updateStepStatus}
              getPreviousStepId={this.getPreviousStepId}
            />
          </Grid>
        )}
        {renderOverview(this.state) && (
          <ProjectOverview
            navigation={this.props.navigation}
            state={this.state}
            setTab={this.setTab}
            addProjectPlan={this.addProjectPlan}
            toggleOverview={this.toggleOverview}
            handleStartTech={this.handleStartTech}
            addLmsCard={this.addLmsCard}
            copyToClipboard={this.copyToClipboard}
            resetCopiedState={this.resetCopiedState}
            data={
              this.state.previewMode === "preview"
                ? this.state.projectDetails.overview.data.attributes
                : this.state.projectDetails.project.overview.data.attributes
            }
            classboxsPartsData = {
               this.state.ClassboxParts
            }
            data-test-id="project-overview-test-id"
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} state={this.state} />
      </Grid>
    );
  }
}
// Customizable Area End
