// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { appLogo } from "./assets";
import ClassboxesController, { Props } from "./ClassboxesController";
import "../assets/statusBar.scss";
import RequestCard from "./component/RequestCard";
import KeepClassboxSuccess from "./components/KeepClassboxSuccess";
import StepperComponent from "./components/StepperComponent";

export default class StatusBar extends ClassboxesController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Grid container sm={12} item className="main-container" spacing={5}>
        <div className="header1">
          <img src={appLogo} alt="logo" />
        </div>
        {this.getId() == "3" && <StepperComponent navigation={this.props.navigation}/>}
        {this.getId() == "1" && (
          <Grid sm={12} className="min-h-100vh">
            <RequestCard navigation={this.props.navigation} />
          </Grid>
        )}
        {this.getId() == "2" && (
          <Grid sm={12} className="min-h-100vh">
            <KeepClassboxSuccess
              navigation={this.props.navigation}
              cost={this.props.navigation?.getParam("cost")}
              newDate={this.props.navigation?.getParam("date")}
              tab={this.props.navigation?.getParam("tab")}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}
// Customizable Area End
