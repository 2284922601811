import React from "react";
import { FrameImage, approved } from "../assets";
import { Grid, Button } from "@material-ui/core";
import { getCommaSeperator } from "../../../../components/src/NativeWebRouteWrapper/Utils";

function CardDetails({ classBoxDetails, state, goBack }: any) {
  return (
    <>
      <Grid sm={12} container className="card-details">
        <div className="card-details-main">
          <img src={FrameImage} alt="image" className="back-image" />
          <div className="item-float">
            <div>
              <div className="card-details-top top-div">
                <div className="card-details-top">
                  <img src={approved} alt="approved" />
                  <span className="card-details-name">
                    {classBoxDetails?.attributes?.title}
                  </span>
                </div>
              </div>
              <span className="card-qty">
                QTY:
                <span className="card-qty1">
                  {state?.savedOrderDetails?.attributes?.quantity}
                </span>
              </span>
            </div>
            <img
              src={classBoxDetails.attributes
                .hero_emoji?.url}
              alt="img"
              className="icon-side"
            />
          </div>
        </div>
        <div className="card-details-bottom">
          <Button className="card-details-btn" onClick={goBack}>
            Edit
          </Button>
          <div className="card-bottom-credit">
            <span className="card-credit"> Request Credits Cost</span>
            <span className="card-credit-amt">
              {getCommaSeperator(state.savedOrderDetails.attributes.total_cost)}
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default CardDetails;
