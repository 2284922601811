import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  InputLabel,
  TextField,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAllocatedData, getCommaSeperator, getCredits, getUserDetails } from "../../../../components/src/NativeWebRouteWrapper/Utils";
import { errorIcon } from "../../../EmailNotifications2/src/assets";


export const IconComponent1 = (errors: any, touched: any) => {
  return !!errors.reclaimCredit && !!touched.reclaimCredit ? (
    <img src={errorIcon} alt="icon" />
  ) : (
    <></>
  );
};
export const IconComponent2 = (errors: any, touched: any) => {
  return !!errors.reason && !!touched.reason ? (
    <img src={errorIcon} alt="icon" />
  ) : (
    <></>
  );
};
const ReclaimCreditModal = ({
  state,
  handleReclaimModal,
  onClickReclaimCredit,
}: any) => {
  const Schema = Yup.object().shape({
    reason: Yup.string().required("Field is Required."),
    reclaimCredit: Yup.number()
      .required("Field is required.")
      .lessThan(
        (+state.drawerDetails.currentBalance - +state.drawerDetails.unused_earned_credits) + 1,
        (+state.drawerDetails.currentBalance - +state.drawerDetails.unused_earned_credits) <= 0
          ? "Balance empty, no credits to reclaim."
          : `User has only ${(+state.drawerDetails.currentBalance - +state.drawerDetails.unused_earned_credits) } credit`
      ),
  });

  return (
    <Dialog
      className="dialog-container-1"
      onClose={handleReclaimModal}
      aria-labelledby="customized-dialog-title"
      open={state.openReclaimModal}
    >
      <DialogTitle id="customized-dialog-title">
        <div className="header-main">
          <Typography
            className="main-text title-medium modal-title-bold"
            gutterBottom
          >
            Reclaim Credits
          </Typography>
          <IconButton onClick={handleReclaimModal}>
            <CancelIcon />
          </IconButton>
        </div>
        <Typography className="desc">
          These credits will be unassigned from{" "}
          <span className="user-name">
            {state.drawerDetails.name}
          </span>{" "}
          and added back to your school's credit pool. This user will be
          notified about this change.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <form noValidate autoComplete="off" className="form-reclaim">
            <Formik
              initialValues={{
                reason: "",
                reclaimCredit: "",
              }}
              onSubmit={(values) => {
                onClickReclaimCredit(values);
              }}
              validationSchema={Schema}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form>
                    <InputLabel
                      htmlFor="my-input"
                      style={{ marginTop: "2%" }}
                      className="pop-up-input-text"
                    >
                      Reclaim Credits
                    </InputLabel>
                    <TextField
                      className="input-label"
                      style={{ marginTop: "1%" }}
                      onChange={handleChange("reclaimCredit")}
                      data-test-id={"txtInputProductId"}
                      value={values.reclaimCredit}
                      name="reclaimCredit"
                      placeholder=""
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="reclaimCredit" />}
                      error={!!errors.reclaimCredit && !!touched.reclaimCredit}
                      required
                      InputProps={{
                        endAdornment: IconComponent1(errors, touched),
                      }}
                    />
                    <InputLabel
                      htmlFor="my-input"
                      style={{ marginTop: "2%", paddingBottom: "8px" }}
                      className="pop-up-input-text"
                    >
                      Reason for Change
                    </InputLabel>
                    <span className="reason-string">
                      This reason will be provided to the user.
                    </span>
                    <TextField
                      className="input-label"
                      style={{ marginTop: "2%" }}
                      onChange={handleChange("reason")}
                      data-test-id={"txtInputProductId"}
                      value={values.reason}
                      name="reason"
                      placeholder="Provide reason"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      helperText={<ErrorMessage name="reason" />}
                      error={!!errors.reason && !!touched.reason}
                      required
                      InputProps={{
                        endAdornment: IconComponent2(errors, touched),
                      }}
                    />
                    <div className="footer1">
                      <span className="left">
                        {state.drawerDetails.name}’s updated balance:
                      </span>
                      <span className="right">
                        {getCommaSeperator(getAllocatedData(+state.drawerDetails.currentBalance - +values.reclaimCredit))}{" "}
                        {getCredits(+state.drawerDetails.currentBalance - +values.reclaimCredit)}
                      </span>
                    </div>
                    <DialogActions className="dialog-actions-container">
                      <Button
                        autoFocus
                        onClick={handleReclaimModal}
                        color="primary"
                        className="cancle-btn"
                      >
                        Nevermind
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => handleSubmit()}
                        className="add-btn"
                      >
                        Reclaim Credits
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReclaimCreditModal;
