import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { tableImg, downloadImg } from "../assets";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #EAECF0",
      borderRadius: "10px",
    },
  })
);

export default function TableComponent({ element, handleDownload }: any) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <img src={tableImg} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <span className="credit-text">{element?.operation}</span>{" "}
            </>
          }
          secondary={<span className="credit-date">{element?.date}</span>}
        />
        {element.document_appended && element.document_type ? (
          <Button
            startIcon={<img src={downloadImg} alt="plus" />}
            className="download-btn"
            onClick={() => handleDownload(element?.document_appended)}
          >
            {element?.document_type}
          </Button>
        ) : null}
      </ListItem>
    </List>
  );
}
