import React from "react";
import { Grid, Dialog,Popover } from "@material-ui/core";
import "./dialog.scss";

export default function SimpleDialogModal({
  selectedValue,
  onClose,
  open,
  handleItemClick,
  values,
  name,
  id,
  anchorEl
}: any) {
  return (
    <Popover
        id={id}
        className="dialog-container"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container className="list-items m-0" spacing={2}>
         {values.map((value: string) => (
           <Grid item key={value} sm={name === "type" ? 12 : name === "tutionTime" ? 3 : 2} className="m-0">
             <span
               className="font-size-28"
               onClick={() => {
                 handleItemClick({ name: name, value: value });
                 onClose();
               }}
             >
               {value}
             </span>
           </Grid>
         ))}
       </Grid>
      </Popover>
  );
}
