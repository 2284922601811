// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    open: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}
// Customizable Area Start
export default class InviteUserController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    submitApiCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            open: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start

        // Customizable Area End
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        // Customizable Area Start

        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start

        // Customizable Area End
    }

    onOk = (data: {
        firstName: string;
        lastName: string;
        email: string;
        role: string;
    }) => {
        this.setState({ open: false });
        return true;
    }
    onCancle = () => {
        this.setState({ open: false });
        return true;
    };
    handleModal = (value: boolean) => {
        this.setState({ open: value });
        return true;
    }
    openModal = () => {
        this.handleModal(true);
        return true;
    }
}
// Customizable Area End

// Customizable Area End
