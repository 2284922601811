// Customizable Area Start
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Switch, { SwitchProps } from "@material-ui/core/Switch";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 36,
    height: 20,
    padding:1,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "#F2F4F7",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#0099F3",
        borderColor: "#0099F3",
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
    background:"#FFF"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 12,
    opacity: 1,
    backgroundColor: "#E5E9EE",
  },
  checked: {},
}));

interface CustomSwitchProps extends SwitchProps {
  name: string;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange, name, ...rest }) => {
  const classes = useStyles();
  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={checked}
      onChange={onChange}
      name={name}
      {...rest}
    />
  );
};

export default CustomSwitch;
// Customizable Area End