import React from 'react'
import { Button } from "@material-ui/core";
import CalenderComponent from "../components/CalenderComponent";
import {
  calIcon,
  duration,
  plus,
  minus,
  errorMinus,
  errorplus
} from "../assets";
const AlignmetOne = ({ state, increaseQuantity, selectedRange, setSelectedRange,
  months,
  setMonth, alignment }: any) => {
  return <>
    {alignment === "0" ?
      <>
        <div className="center-div">
          <div className="center-left">
            <div className="heading-div">
              <img src={calIcon} alt="img" className="cal-img" />
              <span>Checkout QTY</span>
            </div>
            <div className="padl-26">
              <span className="order-text">
                Ordering{" "}
                <span className="order-text1">
                  {state?.quantityCheckOut}
                </span>{" "}
                {state.purchaseDetails?.attributes?.title} will allow{" "}
                <span className="order-text1"> {state.studentPerNumber.attributes.students_per_group}</span> students to work in
                groups of{" "}
                <span className="order-text1">
                  {state.checkOutGroup}
                </span>
                .
              </span>
            </div>
          </div>
          <div className={state.quantityCheckOut == "5" ? "increment-error" : "increment"}>
            <Button
              className="increment-btn"
              id="btn-25-vv-552"
              onClick={() => {
                if (state.quantityCheckOut > 0) {
                  increaseQuantity("decrement");
                }
              }}
            >
              {state.quantityCheckOut == "5" ? <img src={errorMinus} /> : <img src={minus} />}


            </Button>
            <span className="increment-text">
              {state?.quantityCheckOut}
            </span>

            <Button
              className="increment-btn"
              id="btn-25-vv-551"
              onClick={() => {
                if (state.quantityCheckOut <= 4) {
                  increaseQuantity("increment");
                }
              }}
            >
              {state.quantityCheckOut == "5" ? <img src={errorplus} /> : <img src={plus} />}
            </Button>

          </div>
        </div>
        <div className="purchase-div purchase-div-padding">
          <div
            className="img-span"
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "7px",
            }}
          >
            <img src={duration} alt="img" className="cal-img" />
            &nbsp; Checkout Duration
          </div>
          <div className="ordered">
            <span className="order-text2">
              Configure when you would like to receive your checkout, and
              for how long you would like to keep it.
            </span>

            <CalenderComponent setSelectedRange={setSelectedRange} selectedRange={selectedRange} setMonth={setMonth} months={months} />
          </div>
        </div>
      </> : <></>}
  </>

}

export default AlignmetOne