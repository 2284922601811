import React from "react";
import { Grid } from "@material-ui/core";
import LeadDasshBoard from "./LeadDasshBoard";
import LeadDrawerComponent from "./LeadDrawerComponent";
import VideoCard from "./VideoCard";

const LeadDashboardMain = ({
  state,
  handleClose,
  isFirstTime,
  navigation,
  fetchMoreData
}: any) => {
  return (
    <Grid container className="lead-dashboard">
      <Grid item sm={6} md={9} className="main">
        <LeadDasshBoard
          state={state}
          isFirstTime={isFirstTime}
          navigation={navigation}
        />
      </Grid>
      <Grid item sm={6}  md={3} className="sidebar">
        <LeadDrawerComponent state={state}fetchMoreData={fetchMoreData}/>
      </Grid>
      {isFirstTime && (
        <Grid className="video-card">
          <VideoCard open={state.showVideoModal} handleClose={handleClose} navigation={navigation}/>
        </Grid>
      )}
    </Grid>
  );
};

export default LeadDashboardMain;
