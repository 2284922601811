// Customizable Area Start
import React from "react";
import {
  Button,
} from "@material-ui/core";

import TrainingsController, { Props } from "./TrainingsController";
import "../assets/trainingsConfirmed.scss";

import {
  rightArrowIcon,
  confirmation,
} from "./assets";
import { getCommaSeperator, getCredits } from "../../../components/src/NativeWebRouteWrapper/Utils";

export default class ConfirmTraining extends TrainingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="container-comfirmed">
          <img src={confirmation} alt="img" className="img"></img>
          <div className="top-div">
            <span className="title">Training Ordered!</span>
            <br></br>
            <br></br>

            <span className="description">
              You have successfully ordered the <span className="des-bold">{this.state.confirmTitle}</span> training
              from <span className="des-bold">{this.state.creator_tag}</span>. You will receive an email from your School Success Manager soon with the details
              to access this training with our partner.

            </span>
          </div>
          <div className="bottom-div">
            <div className="sub-div">
              <span className="amt">{getCommaSeperator(this.state.confirmCredit)}{" "}</span>
              <span className="credits">{getCredits(this.state.confirmCredit)}</span>
              <br></br>
              <span className="total-credits">
                Total Credits cost from this training
              </span>
            </div>
            <Button className="right-btn" onClick={() => this.props.navigation.navigate("Dashboard")}>
              Dashboard &nbsp;
              <img src={rightArrowIcon} alt="i" />
            </Button>
          </div>
        </div>
      </>
    );
  }
}
// Customizable Area End
