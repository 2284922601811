import React from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Select, InputLabel, FormHelperText, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./inviteusermodal.scss";

interface Props {
    onOk: (data: {
        firstName: string;
        lastName: string;
        email: string;
        role: string;
    }) => void,
    onCancle: () => void,
    handleModal: (open: boolean) => void,
    open: boolean
}

const InviteUserModal = ({ handleModal, open, onCancle, onOk }: Props) => {

    const handleCloseModal = React.useCallback(() => handleModal(false), []);

    const inviteUserSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(10, 'Too Long!')
            .required('Field is Require'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(10, 'Too Long!')
            .required('Field is Required.'),
        email: Yup.string().email('Invalid email').required('Field is Required.'),
        role: Yup.string().required('Field is Required.'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            role: ''
        },
        onSubmit: (values: {
            firstName: string;
            lastName: string;
            email: string;
            role: string;
        }) => {
            onOk(values)
        },
        validationSchema: inviteUserSchema
    });

    const handleSubmit = React.useCallback(() => formik.handleSubmit(), []);

    return <Dialog open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title" className="invite-user-modal">
        <DialogTitle id="form-dialog-title" className="modal-title">Invite User</DialogTitle>
        <DialogContent>
            <DialogContentText className="modal-description ">
                Invite new users to your Classbox
            </DialogContentText>
            <form className="invite-user-form">
                <Grid container spacing={2}>
                    <Grid item sm={6} className="input-container">
                        <InputLabel htmlFor="firstName" className="input-label">First Name</InputLabel>
                        <TextField className="input-field" id="outlined-basic" variant="outlined" size="small" name="firstName"
                            type="text"
                            placeholder="FirstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName} />
                        {formik.errors.firstName && formik.touched.firstName && <FormHelperText error={true} >{formik.errors.firstName}</FormHelperText>}
                    </Grid>
                    <Grid item sm={6} className="input-container">
                        <InputLabel htmlFor="lastName" className="input-label">Last Name</InputLabel>
                        <TextField className="input-field" id="outlined-basic" variant="outlined" size="small" name="lastName"
                            type="text"
                            placeholder="LastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName} />
                        {formik.errors.firstName && formik.touched.lastName && <FormHelperText error={true} >{formik.errors.lastName}</FormHelperText>}
                    </Grid>
                    <Grid item sm={12} className="input-container">
                        <InputLabel htmlFor="email" className="input-label">Email</InputLabel>
                        <TextField className="input-field" id="outlined-basic" variant="outlined" size="small" name="email"
                            type="text"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values.email} />
                        {formik.errors.email && formik.touched.email && <FormHelperText error={true} >{formik.errors.email}</FormHelperText>}
                    </Grid>
                    <Grid item sm={12} className="input-container">
                        <InputLabel htmlFor="role" className="input-label">Pick Role</InputLabel>
                        <Select className="input-field" id="outlined-basic" variant="outlined" name="role"
                            type="select"
                            placeholder="Pick ROle"
                            onChange={formik.handleChange}
                            value={formik.values.role}
                            displayEmpty
                            //@ts-ignore
                            renderValue={formik.values.role !== "" ? undefined : () => <span style={{ color: "#A6A6A6" }}>Pick Role</span>}
                        >
                            <MenuItem value="Super Manager">Super Manager</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                        </Select>
                        {formik.errors.role && formik.touched.role && <FormHelperText error={true} >{formik.errors.role}</FormHelperText>}
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancle} color="primary" className="cancle-btn">
                Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained" size="medium" color="primary" className="add-user-btn">
                Add User
            </Button>
        </DialogActions>
    </Dialog >
}

export default InviteUserModal;
