import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
    root: {
        color: "#D0D5DD",
        "&$checked": {
            color: "#0099F3"
        }
    },
    checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels({ handleChange, checked, label, name }: any) {

    return (
        <div className={checked ? "checkbox-container-active" : "checkbox-container"}>
            <FormControlLabel
                control={
                    <GreenCheckbox
                        checked={checked}
                        onChange={handleChange}
                        name={name}
                    />
                }
                label={<span className="label-txt">{label}</span>}
                className="custom-checkbox"
            />
        </div>
    );
}
