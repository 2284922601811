import React, { useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import { Dialog } from "@material-ui/core";
import { successIcon, decline } from "../assets";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default function AlertComponent({
  title,
  description,
  success,
  handleClose,
  icon
}: any) {

  return (
    <Dialog
      open={true}
      className="alert-dialog"
    >
      <Alert
        className="alert-component"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            className="close-icon"
            onClick={() => handleClose(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <img
          src={icon}
          alt="icon"
        />
        <div className="content">
          <span className="title">{title}</span>
          <span className="desc">{description}</span>
        </div>
      </Alert>
    </Dialog>
  );
}
