import React from "react";
import { Grid, Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
const TrainingDeatilsHeader = ({
  state,
  getButtonTitle,
  updateStepStatus,
}: any) => {
  return (
    <Grid className="header">
      <span className="titles">
        {state.trainingDetails?.current_step_data?.data?.attributes?.title}
      </span>
      {state.trainingDetails?.training_data?.projects && (
        <>
          {state.trainingDetails?.current_step_data?.status !==
          "not_started" ? (
            <Button
              className="completed-btn"
              endIcon={<DoneIcon style={{ color: "#039855" }} />}
            >
              Completed
            </Button>
          ) : (
            <Button
              className="finish-btn"
              endIcon={<DoneIcon />}
              onClick={updateStepStatus}
            >
              {getButtonTitle()}
            </Button>
          )}
        </>
      )}
    </Grid>
  );
};

export default TrainingDeatilsHeader;
